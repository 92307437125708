import { commonErrText } from 'constants/commonConstants';
import { LoginPageError } from 'constants/loginPageConstants';
import { InputValidateObject } from 'models/Common/inputTypes';
import { EMAIL_REGEX, NUMBER_REGEX, PASSWORD_REGEX, SIGN_UP_NAME_REGEX, SPACE_REGEX } from 'utils/regex';

// 유효성 검사
export const validateCheck: InputValidateObject = {
    email: (email) => {
        if (EMAIL_REGEX.test(email)) {
            return '';
        } else if (SPACE_REGEX.test(email)) {
            return '공백은 사용할 수 없습니다.';
        } else if (email && !EMAIL_REGEX.test(email)) {
            return LoginPageError.changeEmailForm;
        } else {
            return commonErrText.required;
        }
    },
    password: (password, value, setErrMsg) => {
        if (value && value.confirmPassword && password !== value.confirmPassword) {
            setErrMsg && setErrMsg((prev) => ({ ...prev, confirmPassword: '비밀번호가 일치하지 않습니다.' }));
        }

        if (SPACE_REGEX.test(password)) {
            return '공백은 사용할 수 없습니다.';
        } else if (value && value.confirmPassword === password && PASSWORD_REGEX.test(password)) {
            setErrMsg && setErrMsg((prev) => ({ ...prev, confirmPassword: '' }));
            return '';
        } else if (PASSWORD_REGEX.test(password)) {
            return '';
        } else if (password && !PASSWORD_REGEX.test(password)) {
            return commonErrText.password;
        } else {
            return commonErrText.required;
        }
    },
    confirmPassword: (confirmPassword, value) => {
        if (value && confirmPassword && value.password === confirmPassword) {
            return '';
        } else if (value && confirmPassword && value.password !== confirmPassword) {
            return '비밀번호가 일치하지 않습니다.';
        } else {
            return '확인을 위해 비밀번호를 한 번 더 입력해주세요.';
        }
    },
    name: (name) => {
        if (SPACE_REGEX.test(name)) {
            return '공백은 사용할 수 없습니다.';
        } else if (!name) {
            return commonErrText.required;
        } else {
            return '';
        }
        // else if (SIGN_UP_NAME_REGEX.test(name)) {
        //     return '';
        // } else {
        //     return commonErrText.required;
        // }
    },
    phoneNumber: (phoneNumber) => {
        if (phoneNumber.length === 0) {
            return commonErrText.required;
        } else if (!NUMBER_REGEX.test(phoneNumber) || phoneNumber.length < 10) {
            return '연락처를 정확히 입력해주세요.';
        } else {
            return '';
        }
    },
    patientMobile: (patientMobile) => {
        if (patientMobile.length === 0) {
            return commonErrText.required;
        } else if (!NUMBER_REGEX.test(patientMobile) || patientMobile.length < 9) {
            return '연락처를 정확히 입력해주세요.';
        } else {
            return '';
        }
    },
    // schoolAge: (selected) => {
    //     console.log(selected.length, 'selected');
    //     if (!selected) {
    //         return commonErrText.requiredSchoolAgeAndSemester;
    //     } else {
    //         return '';
    //     }
    // },
    // semester: (selected) => {
    //     if (!selected) {
    //         return commonErrText.requiredSchoolAgeAndSemester;
    //     } else {
    //         return '';
    //     }
    // },
};

// 로그인 유효성 검사
export const loginValidate: InputValidateObject = {
    email: (email) => {
        if (SPACE_REGEX.test(email)) {
            return '공백은 사용할 수 없습니다.';
        } else if (EMAIL_REGEX.test(email)) {
            return '';
        } else if (email && !EMAIL_REGEX.test(email)) {
            return LoginPageError.changeEmailForm;
        } else {
            return '이메일 아이디를 입력해주세요.';
        }
    },
    password: (password, value) => {
        if (!password) {
            return '비밀번호를 입력해주세요.';
        } else if (SPACE_REGEX.test(password) || (value && value.confirmPassword && password !== value.confirmPassword)) {
            return '잘못된 비밀번호입니다. 다시 입력해주세요.';
        } else {
            return '';
        }
    },
    // password: (password, value) => {
    //     if (value && value.confirmPassword && password !== value.confirmPassword) {
    //         return '비밀번호가 일치하지 않습니다.';
    //     }
    //     if (PASSWORD_REGEX.test(password)) {
    //         return '';
    //     } else if (password && !PASSWORD_REGEX.test(password)) {
    //         return commonErrText.password;
    //     } else {
    //         return '비밀번호를 입력해주세요.';
    //     }
    // },
};

// 생년월일 유효성 검사(YYMMDD 형식 기준)
function isValidDate(yy: string, mm: string, dd: string) {
    const year = 2000 + parseInt(yy, 10);
    const month = parseInt(mm, 10) - 1; // JavaScript의 month는 0부터 시작
    const day = parseInt(dd, 10);

    const birthDate = new Date(year, month, day);

    // getFullYear, getMonth, getDate 메소드를 사용하여 입력한 날짜와 실제 생성된 날짜가 같은지 확인
    return birthDate.getFullYear() === year && birthDate.getMonth() === month && birthDate.getDate() === day;
}

export function isValidBirthdate(birthdate: string) {
    const regex = /^(\d{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/;
    const match = birthdate.match(regex);

    if (match) {
        return isValidDate(match[1], match[2], match[3]);
    }

    return false;
}
