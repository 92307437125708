import React from 'react';

const InfoCircle = () => {
    return (
        <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='State=Default' clip-path='url(#clip0_1878_42739)'>
                <path
                    id='Ellipse 34 (Stroke)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z'
                    fill='#989BA2'
                />
                <path
                    id='?'
                    d='M5.3584 7.30005C5.36719 6.18384 5.68359 5.84106 6.2373 5.49829C6.61523 5.26099 6.89648 4.96216 6.89648 4.52271C6.89648 4.03052 6.51855 3.71411 6.03516 3.71411C5.5957 3.71411 5.17383 3.99536 5.14746 4.58423H4.04883C4.0752 3.40649 4.96289 2.80005 6.04395 2.80005C7.23047 2.80005 8.04785 3.45923 8.04785 4.50513C8.04785 5.21704 7.6875 5.68286 7.11621 6.02563C6.60645 6.33325 6.39551 6.64087 6.38672 7.30005V7.37915H5.3584V7.30005ZM5.90332 9.32153C5.52539 9.32153 5.21777 9.01392 5.21777 8.63599C5.21777 8.26685 5.52539 7.95923 5.90332 7.95923C6.26367 7.95923 6.58008 8.26685 6.58008 8.63599C6.58008 9.01392 6.26367 9.32153 5.90332 9.32153Z'
                    fill='#989BA2'
                />
            </g>
            <defs>
                <clipPath id='clip0_1878_42739'>
                    <rect width='12' height='12' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default InfoCircle;
