import CloseEye from 'assets/Icons/Password/PasswordHide';
import OpenEye from 'assets/Icons/Password/PasswordShow';
import { InputContainerStyleProps, InputProps } from 'models/Common/inputTypes';
import HospitalCompactListDropdown from 'pages/SignUp/HospitalCompactListDropdown';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { BackgroundColor, PrimaryColor, WarningColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Radius } from 'styles/Global/objectStyles';
import { Caption1Regular } from 'styles/Global/typography';
import { BasicInput, InputLabel, InputSubLabel, InputWithLabelIconWrapper } from 'styles/Input/inputStyle';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import DeleteRound from 'assets/Icons/DeleteIcon/DeleteRound';
import Hospital from 'assets/Icons/HospitalIcon/Hospital';
import { inputWithLabelConstants } from 'constants/loginPageConstants';

export const InputWithLabel = forwardRef((props: InputProps, ref) => {
    const { desc, label, placeholder, onChange, id, width, focus, errorMsg, value, isPasswordVisible, setIsPasswordVisible, onFocus, enterKey, onBlur, type } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
    }));

    useEffect(() => {
        if (type === 'preventFocusOut') {
            const handleMouseDown = (e: MouseEvent) => {
                if (inputRef.current && e.target !== inputRef.current) {
                    e.preventDefault();
                    inputRef.current.focus();
                }
            };

            document.addEventListener('mousedown', handleMouseDown);

            return () => {
                document.removeEventListener('mousedown', handleMouseDown);
            };
        }
    }, []);
    return (
        <Container $width={width} id={id}>
            <InputLabel>{label}</InputLabel>
            {desc && <InputSubLabel>{desc}</InputSubLabel>}
            <InputWrapper>
                <BasicInput
                    ref={inputRef}
                    id={id}
                    onChange={(e) => onChange && onChange(e, id === 'phoneNumber' && isNaN(Number(e.target.value)) ? 'error' : id)}
                    placeholder={placeholder}
                    autoFocus={focus ?? false}
                    value={value}
                    $warning={errorMsg}
                    onFocus={onFocus}
                    onBlur={() => {
                        onBlur && onBlur(id);
                    }}
                    type={(id === 'password' || id === 'confirmPassword') && !isPasswordVisible ? 'password' : 'text'}
                    onKeyUp={enterKey}
                />
                {id === 'password' && <InputWithLabelIconWrapper onMouseDown={setIsPasswordVisible}>{isPasswordVisible ? <OpenEye /> : <CloseEye />}</InputWithLabelIconWrapper>}
                {id === 'confirmPassword' && value && !errorMsg && <ConfirmBadgeWrapper>{inputWithLabelConstants.same}</ConfirmBadgeWrapper>}
            </InputWrapper>

            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
        </Container>
    );
});

// 회원가입 병원명 input component
export const HospitalNameInputWithLabel = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
    const {
        desc,
        label,
        placeholder,
        onChange,
        id,
        width,
        errorMsg,
        value,
        onFocus,
        onDropdown,
        hospitalData,
        onClick,
        selectedHospital,
        changeToInitValue,
        onBlur,
        hospitalNameInputRef,
        hospitalNameRef,
    } = props;

    return (
        <Container ref={hospitalNameRef} $width={width} id={id}>
            <InputLabel>{label}</InputLabel>
            {desc && <InputSubLabel>{desc}</InputSubLabel>}
            <InputWrapper>
                {selectedHospital?.name === value ? (
                    <SelectedHospitalNameContainer>
                        <Hospital />
                        <HospitalInput id={id} onChange={(e) => onChange && onChange(e, id)} placeholder={placeholder} value={value} onFocus={onFocus} disabled={selectedHospital.name === value} />
                    </SelectedHospitalNameContainer>
                ) : (
                    <BasicInput
                        id={id}
                        ref={hospitalNameInputRef}
                        onChange={(e) => onChange && onChange(e, id)}
                        placeholder={placeholder}
                        value={value}
                        $warning={errorMsg}
                        onFocus={onFocus}
                        disabled={selectedHospital?.name === value}
                        onBlur={() => onBlur && onBlur(id)}
                    />
                )}
                {(!value || !selectedHospital?.name || errorMsg) && <HospitalNameErrorMsg>{errorMsg}</HospitalNameErrorMsg>}
                {onDropdown && <HospitalCompactListDropdown hospitalData={hospitalData} onClick={onClick} value={value} />}
                {selectedHospital?.name === value && (
                    <InputWithLabelIconWrapper onClick={changeToInitValue}>
                        <DeleteRound />
                    </InputWithLabelIconWrapper>
                )}
            </InputWrapper>
        </Container>
    );
});

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const HospitalInput = styled.input<InputContainerStyleProps>`
    width: 100%;
    height: 100%;
    font-size: 16px;
    border: none;
    outline: none;
    appearance: none;
    font-weight: ${font.weight.medium};
    -webkit-appearance: none;
    -moz-appearance: none;
    &:disabled {
        background-color: transparent;
        border: none;
    }
`;

const Container = styled.div<InputContainerStyleProps>`
    ${(props) => (props.id === 'password' || props.id === 'confirmPassword') && 'position: relative;'}
    width: ${(props) => props.$width ?? '100%'};
    display: flex;
    flex-direction: column;
`;

const ConfirmBadgeWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 49px;
    height: 28px;
    right: 20px;
    bottom: 16px;
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_primary_normal};
    font-size: ${font.size[14]};
    font-weight: ${font.weight.medium};
    color: ${PrimaryColor.color_primary_normal};
    line-height: 20px;
`;

const SelectedHospitalNameContainer = styled.div<InputContainerStyleProps>`
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    border: ${(props) => (props.$warning ? `1px solid ${palette.warning}` : `1px solid ${palette.testGray}`)};
    padding: 12px 50px 12px 20px;
    height: 60px;
    width: 100%;
    font-size: 16px;
    &:focus {
        border: ${(props) => (props.$warning ? `1px solid ${palette.warning}` : `1px solid ${palette.black}`)};
    }
`;

export const HospitalNameErrorMsg = styled(Caption1Regular)`
    color: ${WarningColor.color_warning_normal};
`;
