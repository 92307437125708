import { managingPatientsPageText } from 'constants/managingPatientsPageConstants';
import useToggle from 'hooks/CustomHooks/useToggle';
import React, { useEffect, useState } from 'react';
import { PageTitle } from 'styles/Global/commonStyles';
import { HospitalPageLabel, FilterWrapper, DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import { GetPatientsListApiResponse } from 'models/Hospital/hospitalApiTypes';
import styled from 'styled-components';
import { palette } from 'styles/Global/globalStyles';
import ManagingPatientsPagination from './ManagingPatientsPagination';
import { managingPatientsPaginationSorting } from 'functions/paginationSorting';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import TodayDiagnosisFilter from '../TodayDiagnosisPage/TodayDiagnosisFilter';
import managingPatientsPaginationSearchFilter from 'functions/managingPatientsPaginationSearchFilter';
import { managingPatientsApi } from 'api/Hospital/managingPatientsApi';
import { useLoginStore } from 'store/useLoginDataStore';
import { useLoadingStore } from 'store/useLoadingStore';

const ManagingPatientsPage = () => {
    const { loginData } = useLoginStore();

    const [initData, setInitData] = useState<GetPatientsListApiResponse['data']>();
    const [filterData, setFilterData] = useState<GetPatientsListApiResponse['data'] | undefined>(initData || undefined);
    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [sortDirections, setSortDirections] = useState<Record<string, boolean>>({ id: false, name: false, identityNumber: false, lastDiagnosisDate: false });
    const [sortField, setSortField] = useState<string>('');

    const [isSearchFilterDropdown, setIsSearchFilterDropdown] = useToggle();

    const { totalPages, setPage, currentPage, pageGroup, handlePageGroup, currentData } = usePaginationStateHooks(filterData);
    const { loading, setLoading } = useLoadingStore();

    // api call
    useEffect(() => {
        getPatientListApiCall();
    }, []);

    // search dropdown function
    const handleDropdown = (e: React.MouseEvent, idx: number) => {
        e.stopPropagation();
        setIsSearchFilterDropdown();
        setselectedSearchFilter(idx);
        setSearchValue('');
        setPage(1);
    };

    // SearchFilter function
    const handleSearchFilter = () => {
        managingPatientsPaginationSearchFilter(searchValue, setSortDirections, setSortField, selectedSearchFilter, initData, setPage, handlePageGroup, setFilterData);
    };

    // reset Button function
    const handleResetButton = () => {
        setSearchValue('');
        setFilterData(initData || []);
        setPage(1);
        handlePageGroup(0);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        }));
        setSortField('regNum');
    };

    // sorting function
    const handleSorting = (type: string) => {
        const direction = !sortDirections[type];

        setSortField(type);
        setSortDirections((prev) => ({
            ...Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            [type]: direction,
        }));
        setFilterData(managingPatientsPaginationSorting(type, filterData!, direction));
    };

    // api call function
    const getPatientListApiCall = async () => {
        try {
            setLoading(true);
            const getPatientListApiResponse = await managingPatientsApi.getPatientsList(loginData.hospitalId, loginData.token);
            if (getPatientListApiResponse.status === 'ok') {
                const initData = managingPatientsPaginationSorting('regNum', getPatientListApiResponse.data, false);
                setSortField('regNum');
                setInitData(initData);
                setFilterData(initData);
                setLoading(false);
            } else {
                setLoading(false);
                console.log('등록 환자 리스트 error');
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <DiagnosisLayout>
            {!currentData || loading ? null : (
                <DiagnosisInnerLayout>
                    <HospitalPageLabel onClick={() => window.location.reload()}>{managingPatientsPageText.pageLabelAndTitle}</HospitalPageLabel>
                    <PageTitle $marginbottom='24px'>{managingPatientsPageText.pageLabelAndTitle}</PageTitle>
                    <Divider />
                    <FilterWrapper>
                        <TodayDiagnosisFilter
                            type='managingPatients'
                            diagnosisNumber={filterData?.length}
                            selectedSearchFilter={selectedSearchFilter}
                            handleDropdown={handleDropdown}
                            isSearchFilterDropdown={isSearchFilterDropdown}
                            setIsSearchFilterDropdown={setIsSearchFilterDropdown}
                            onChange={setSearchValue}
                            searchValue={searchValue}
                            handleSearchFilter={handleSearchFilter}
                        />
                    </FilterWrapper>
                    <ManagingPatientsPagination
                        initNumber={initData?.length}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pageGroup={pageGroup}
                        handlePageGroup={handlePageGroup}
                        handlePageChange={setPage}
                        currentData={currentData}
                        handleSorting={handleSorting}
                        sortField={sortField}
                        sortDirections={sortDirections}
                        handleResetButton={handleResetButton}
                    />
                </DiagnosisInnerLayout>
            )}
        </DiagnosisLayout>
    );
};

export default ManagingPatientsPage;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${palette.testGray};
    margin-bottom: 16px;
`;
