import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const LnbDiagnosisTodayIcon = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4H19C20.1046 4 21 4.89543 21 6V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V6C3 4.89543 3.89543 4 5 4H7V3C7 2.44772 7.44772 2 8 2ZM15 6V7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7V6H19V19H5L5 6H7V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V6H15ZM10.5 11.5C10.5 11.0029 10.9029 10.6 11.4 10.6H15.8C16.2971 10.6 16.7 11.0029 16.7 11.5C16.7 11.9971 16.2971 12.4 15.8 12.4H11.4C10.9029 12.4 10.5 11.9971 10.5 11.5ZM8.3999 12.5C8.95219 12.5 9.3999 12.0523 9.3999 11.5C9.3999 10.9477 8.95219 10.5 8.3999 10.5C7.84762 10.5 7.3999 10.9477 7.3999 11.5C7.3999 12.0523 7.84762 12.5 8.3999 12.5ZM10.5 15.5C10.5 15.0029 10.9029 14.6 11.4 14.6H15.8C16.2971 14.6 16.7 15.0029 16.7 15.5C16.7 15.9971 16.2971 16.4 15.8 16.4H11.4C10.9029 16.4 10.5 15.9971 10.5 15.5ZM8.3999 16.5C8.95219 16.5 9.3999 16.0523 9.3999 15.5C9.3999 14.9477 8.95219 14.5 8.3999 14.5C7.84762 14.5 7.3999 14.9477 7.3999 15.5C7.3999 16.0523 7.84762 16.5 8.3999 16.5Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default LnbDiagnosisTodayIcon;
