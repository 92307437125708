import React from 'react';

import BasicButton from 'components/Buttons/BasicButton';
import styled from 'styled-components';
import { CloseButtonWrapper, TextInDetailPatientPageModal } from 'styles/Modal/modalStyle';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_Medium, Body5Regular } from 'styles/Global/typography';
import ModalDeleteIcon from 'assets/Icons/DeleteIcon/CloseDialog';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from 'components/Modal/ModalForms';
import { UserManageModalProps } from 'models/Admin/AdminManageUserJoinPageTypes';
import Hospital from 'assets/Icons/HospitalIcon/Hospital';
import { changeMobileForm } from 'functions/changeMobileForm';
import { userManageModalConstants } from 'constants/Admin/AdminManageUserJoinPageConstants';
import { commonText } from 'constants/commonConstants';

const UserManageModal = ({ toggle, selectedUserData, type, onClick }: UserManageModalProps) => {
    const title = type === 'accept' ? userManageModalConstants.acceptModalTitle : userManageModalConstants.rejectModalTitle;

    const handleSubTitle = (type: string) => {
        if (type === 'accept') {
            return <ModalContent>{userManageModalConstants.acceptDescription}</ModalContent>;
        } else {
            return (
                <SubTitleWrapper>
                    <ModalContent>{userManageModalConstants.rejectFirstDescription}</ModalContent>
                    <ModalContent>{userManageModalConstants.rejectSecondDescription}</ModalContent>
                </SubTitleWrapper>
            );
        }
    };

    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <ModalInnerLayout>
                    <ModalTitleAndContentContainer>
                        <ModalTitle>{title}</ModalTitle>
                        {handleSubTitle(type)}
                    </ModalTitleAndContentContainer>
                    <PatientInfoLayout>
                        <PatientInfoHeaderContainer>
                            <Hospital />
                            <InfoWrapper>
                                <TextInDetailPatientPageModal>{selectedUserData?.hospitalName}</TextInDetailPatientPageModal>
                            </InfoWrapper>
                        </PatientInfoHeaderContainer>
                        <PatientInfoBodyContainer>
                            <PatientInfoCellsWrapper>
                                <Label>{commonText.id}</Label>
                                <Value>{selectedUserData?.email}</Value>
                            </PatientInfoCellsWrapper>
                            <PatientInfoCellsWrapper>
                                <Label>{commonText.name}</Label>
                                <Value>{selectedUserData?.name}</Value>
                            </PatientInfoCellsWrapper>
                            <PatientInfoCellsWrapper>
                                <Label>{commonText.phoneNumber}</Label>
                                <Value>{changeMobileForm(selectedUserData!.mobile)}</Value>
                            </PatientInfoCellsWrapper>
                        </PatientInfoBodyContainer>
                    </PatientInfoLayout>
                </ModalInnerLayout>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={toggle} $type='anotherFill'>
                        {commonText.cancel}
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' $type={type === 'accept' ? 'fill' : 'warning'} onClick={() => onClick(selectedUserData!._id, type === 'accept' ? 1 : 2, type)}>
                        {type === 'accept' ? userManageModalConstants.acceptButtonText : userManageModalConstants.rejectButtonText}
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={toggle}>
                    <ModalDeleteIcon />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default UserManageModal;

const ModalInnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 16px;
`;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
`;

const SubTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

const PatientInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 4px;
    gap: 14px;
`;

const PatientInfoHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const PatientInfoBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    background-color: ${BackgroundColor.color_background_neutral};
    border-radius: ${Radius.radius_4};
    gap: 12px;
`;

const PatientInfoCellsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 22px;
    gap: 12px;
`;

const Label = styled(Body5Regular)`
    width: 100px;
    line-height: 22px;
    color: ${TextColor.color_text_light};
`;

const Value = styled(Body4_Medium)`
    width: calc(100% - 84px);
    color: ${TextColor.color_text_normal};
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;
