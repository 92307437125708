import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetHospitalApiType } from 'models/Admin/AdminManageHospitalPageTypes';

class AdminManageHospitalPageApi {
    async getHospitalList(page: number | null, searchValue: string | null, token: string): Promise<GetHospitalApiType> {
        const url = `${apiPaths.getHospitalList}?page=${page}&limit=${11}&searchType=${1}&searchKeyword=${searchValue}`;
        try {
            const resopnse = await axios.get(url, { headers: tokenHeader(token) });
            return resopnse.data;
        } catch (error) {
            throw error;
        }
    }
}

export const adminManageHospitalPageApi = new AdminManageHospitalPageApi();
