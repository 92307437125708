import DoctorIcon from 'assets/Icons/Doctor/DoctorIcon';
import DeleteRound from 'assets/Icons/DeleteIcon/DeleteRound';
import { AddDoctorIconTextButton, DeleteDoctorIconTextButton } from 'components/Buttons/IconTextButton';
import { HospitalSettingInputProps } from 'models/Common/inputTypes';
import { useState } from 'react';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Border } from 'styles/Global/objectStyles';
import { Body3_Medium, Body5Regular } from 'styles/Global/typography';
import { BasicInput, BasicInputLayout, InputDesc, InputLabel, InputSubLabel } from 'styles/Input/inputStyle';
import AddRound from 'assets/Icons/AddIcons/AddRound';

const HospitalSettingInput = ({ desc, label, placeholder, onChange, id, disabled, onRemoveButtonClick, value, subLabel, doctorList, onClick, level }: HospitalSettingInputProps) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    return (
        <BasicInputLayout>
            <LabelContainer>
                <InputLabel $marginBottom='0'>{label}</InputLabel>
                <InputSubLabel $marginBottom='0'>{`총 ${subLabel}명`}</InputSubLabel>
            </LabelContainer>
            {desc && <InputDesc>{desc}</InputDesc>}
            {level !== 0 && (
                <InputContainer>
                    <BasicInput id={id} onChange={(e) => onChange && onChange(e, id)} placeholder={placeholder} value={value} type='text' padding='12px 90px 12px 20px' />
                    <AddDoctorButtonWrapper>
                        <AddDoctorIconTextButton disabled={disabled} onClick={onClick}>
                            <AddRound disabled={disabled} />
                            추가
                        </AddDoctorIconTextButton>
                    </AddDoctorButtonWrapper>
                </InputContainer>
            )}
            <DoctorListsLayout>
                {doctorList?.map((doctor, idx) => {
                    return (
                        <DoctorContainer key={idx} onMouseEnter={() => setSelectedIndex(idx)} onMouseLeave={() => setSelectedIndex(null)}>
                            <DoctorCellWrapper>
                                <DoctorIcon />
                                <Text>{doctor.name}</Text>
                                <SemiText>원장님</SemiText>
                            </DoctorCellWrapper>
                            {level !== 0 && (
                                <ButtonWrapper>
                                    <DeleteDoctorIconTextButton onClick={() => onRemoveButtonClick(doctor.name)} disabled={selectedIndex !== idx || doctorList.length <= 1}>
                                        <DeleteRound />
                                        삭제
                                    </DeleteDoctorIconTextButton>
                                </ButtonWrapper>
                            )}
                        </DoctorContainer>
                    );
                })}
            </DoctorListsLayout>
        </BasicInputLayout>
    );
};

export default HospitalSettingInput;

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const InputContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const AddDoctorButtonWrapper = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 40px;
    right: 12px;
    top: 10px;
`;
const ButtonWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78px;
    height: 40px;
    right: 12px;
    top: 10px;
    &:disabled {
        display: none;
    }
`;

const DoctorListsLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 6px 0;
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    border-radius: 4px;
`;

const DoctorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    align-items: center;
    padding: 0 8px 0 20px;
    cursor: pointer;
    &:hover {
        background-color: ${BackgroundColor.color_background_neutral};
    }
`;

const Text = styled(Body3_Medium)`
    color: ${TextColor.color_text_normal};
    margin-left: 8px;
    margin-right: 4px;
`;

const SemiText = styled(Body5Regular)`
    color: ${TextColor.color_text_light};
`;

const DoctorCellWrapper = styled.div`
    display: flex;
    align-items: center;
`;
