import { DividerProps, InputContainerProps, PageTitleProps } from 'models/Common/commonTypes';
import styled from 'styled-components';
import { font, palette } from './globalStyles';
import { Body2_Medium, Caption1Regular, Display1_Bold } from './typography';
import { BackgroundColor, BorderColor, IconColor, PrimaryColor, TextColor } from './colorSemantic';
import { Radius } from './objectStyles';

export const PageLayout = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
`;

export const ExplainTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 52px;
`;

export const ExplainText = styled(Body2_Medium)`
    color: ${TextColor.color_text_normal};
`;

export const PageLabelAndTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PageLabel = styled(Caption1Regular)`
    margin-bottom: 8px;
`;

export const PageTitle = styled(Display1_Bold)<PageTitleProps>`
    margin-bottom: ${(props) => props.$marginbottom || ''};
`;
export const PageSubTitle = styled(Display1_Bold)<PageTitleProps>`
    color: ${TextColor.color_text_light};
`;

export const InputContainer = styled.div<InputContainerProps>`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    min-height: ${(props) => props.$minheight || ''};
    padding-bottom: ${(props) => props.$paddingbottom || ''};
    margin-bottom: ${(props) => props.$marginbottom || ''};
    margin-top: ${(props) => props.$margintop || ''};
    border-bottom: ${(props) => props.$borderbottom || ''};
`;

export const RowDivider = styled.div<DividerProps>`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.$backgroundcolor};
    margin-bottom: ${(props) => props.$marginbottom};
`;

export const ColumnDivider = styled.div<DividerProps>`
    width: 1px;
    height: ${(props) => props.$height};
    background-color: ${(props) => props.$backgroundcolor || BorderColor.color_border_normal};
    margin-bottom: ${(props) => props.$marginbottom};
`;

export const DefaultRadioButton = styled.div<{ disabled?: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: ${Radius.radius_circle};
    margin-right: 8px;
    border: 1px solid ${IconColor.color_icon_light};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        border: 1px solid ${IconColor.color_icon_neutral};
    }
    &:active {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    &:focus {
        border: 1px solid ${PrimaryColor.color_primary_normal};
    }
    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${BorderColor.color_border_neutral};
        background-color: ${BackgroundColor.color_background_disabled};
    `}
`;
