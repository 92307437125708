import { hospitalAPI } from 'api/Auth/Hospital/hospitalApi';
import ConfirmCheck from 'assets/Icons/Check/ConfirmCheck';
import BasicButton from 'components/Buttons/BasicButton';
import { AddDiagnosisInputWithLabel, DoctorNameInputWithLabel } from 'components/Input/AddDiagnosisInputWithLabel';
import { ValueType } from 'models/Common/commonTypes';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useLoginStore } from 'store/useLoginDataStore';
import styled from 'styled-components';
import { ButtonContainer } from 'styles/Global/buttonStyles';
import { ExplainText, ExplainTextWrapper, InputContainer, PageLabel, PageLabelAndTitleWrapper, PageTitle } from 'styles/Global/commonStyles';
import { CounselingPageLayout, CounslingInnerLayout } from 'styles/Global/hospitalStyles';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import { AddDiagnosisPageTypes, ConfirmWrapperType } from 'models/Hospital/addDiagnosisPageTypes';
import useToggle from 'hooks/CustomHooks/useToggle';
import moment from 'moment';
import 'moment/locale/ko';
import { highSchool, middleSchool, primarySchool, schoolAge } from 'constants/schoolAgeAndSemester';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import { commonErrText, commonText } from 'constants/commonConstants';
import { calculateAge } from 'functions/calculateAge';
import { addDiagnosisApiCall } from 'functions/apiFunctions';
import { BasicInput, InputLabel } from 'styles/Input/inputStyle';
import { isValidBirthdate, validateCheck } from 'functions/validationFunctions';
import IdentityNumber from 'assets/Icons/IdentityNumber/IdentityNumber';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import LeaveModal from 'components/Modal/LeaveModal';
import { Accent, BackgroundColor, BorderColor, PrimaryColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { BasicInputWithLabel } from 'components/Input/BasicInput';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_Medium, Body5Regular, Body5SemiBold } from 'styles/Global/typography';
import { RoundBasicBadge } from 'components/Common/Badge';
import UnConfirmCheck from 'assets/Icons/Check/UnConfirmCheck';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { useLoadingStore } from 'store/useLoadingStore';
import { changeInputFunc, handleProcessEntriesErrMsgFunc } from 'functions/inputFunction';

const AddDiagnosisPage = () => {
    const { loginData } = useLoginStore();
    const { setLoading } = useLoadingStore();

    const initValue = {
        patientCode: '',
        patientName: '',
        patientMobile: '',
        birthDate: '',
        gender: '',
        hospitalId: '',
        hospitalname: '',
        doctorName: loginData.doctors[0].name,
        diagnosisDateTime: '',
        confirmPatient: '',
        patientMemo: '',
        grade: '',
        schoolAge: '',
        semester: '',
    };
    const errorInitValue = {
        birthDate: '',
        confirmPatient: '',
        gender: '',
        patientMobile: '',
        patientName: '',
        schoolAge: '',
        semester: '',
    };
    const [value, setValue] = useState<ValueType>(initValue);
    const [errMsg, setErrMsg] = useState<ValueType>(errorInitValue);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [selectedDate, setSelectedDate] = useState<string | null>('');
    const [selectedTime, setSelectedTime] = useState<string>('');
    const [onConfirmBox, setOnConfirmBox] = useState(false);
    const [onSchoolAgeDropdown, setOnSchoolAgeDropdown] = useState(false);
    const [onSemesterDropdown, setOnSemesterDropdown] = useState(false);
    const [schoolAgeIdx, setSchoolAgeIdx] = useState<number | undefined>();
    const [semesterIdx, setSemesterIdx] = useState<number | undefined>();
    const [doctorsIdx, setDoctorsIdx] = useState<number>(0);

    // browser에 노출용, 데이터 전송 X! value로만 데이터 전송
    const [showPatientInfo, setShowPatientInfo] = useState({ regNum: '', name: '', gender: '', age: '', birthDate: '', registeredPatient: '' });

    const [onCalendar, setOnCalendar] = useToggle();

    const { movePageState, setMovePageState, movePath, clickType, setClickType, setInit, isLeaveModal, setIsLeaveModal } = useLeaveModalStateStore();

    const patientNameInputRef = useRef<HTMLInputElement>(null);
    const patientMobileInputRef = useRef<HTMLInputElement>(null);
    const birthDateInputRef = useRef<HTMLInputElement>(null);
    const genderInputRef = useRef<HTMLInputElement>(null);
    const schoolAgeInputRef = useRef<HTMLInputElement>(null);
    const semesterInputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    useEffect(() => {
        // 진료 일시 초기화
        initDate();

        // 학령 / 학기 외부 클릭 시 드롭다운 닫기
        function handleClickOutside(event: { target: any }) {
            if (schoolAgeInputRef.current && !schoolAgeInputRef.current.contains(event.target)) {
                setOnSchoolAgeDropdown(false);
            }
            if (semesterInputRef.current && !semesterInputRef.current.contains(event.target)) {
                setOnSemesterDropdown(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 페이지 이동 여부 모달 조건 설정
    useEffect(() => {
        const checkValueList = ['patientName', 'patientMobile', 'birthDate', 'gender', 'schoolAge', 'semester', 'patientCode', 'patientMemo'];

        const hasValue = checkValueList.some((key) => value[key as keyof AddDiagnosisPageTypes.InputValue]);

        return setMovePageState(hasValue);
    }, [value]);

    // 오늘로 이동 버튼 함수
    const initDate = () => {
        moment.locale('ko');
        const now = moment();
        const roundedNow = now.minute() < 30 ? now.startOf('hour').add(30, 'minutes') : now.startOf('hour').add(1, 'hours');
        setSelectedDate(now.format('YYYY/MM/DD'));
        setSelectedTime(roundedNow.format('A h:mm'));
    };

    // 진료 추가 input handler(에러 처리 포함)
    const handleInputChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, id: string) => {
        if (id === 'error') return;

        changeInputFunc(id, e.target.value, value, setValue);

        let validateResult = '';

        if (id === 'patientName') {
            if (onConfirmBox) {
                setErrMsg((prevErrMsg) => ({ ...prevErrMsg, confirmPatient: commonErrText.confirmRegistSystem }));
            }

            const patientName = e.target.value;
            validateResult = validateCheck.name(patientName);

            if (validateResult) {
                setButtonDisabled(true);
            } else if (!validateResult && !errMsg.birthDate && !errMsg.gender && value.birthDate.length === 6 && value.gender) {
                setButtonDisabled(false);
            }
        }

        if (id === 'patientMobile') {
            validateResult = validateCheck.phoneNumber(e.target.value);
        }

        return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: validateResult }));
    };

    // 각 input blur error 처리
    const handleBlurError = (id: string) => {
        const excludeIdList = ['schoolAge', 'semester', 'birthDate', 'gender'];

        if (id === 'schoolAge' && schoolAgeIdx === undefined) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
        }

        if (id === 'semester' && semesterIdx === undefined) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
        }

        if (!excludeIdList.includes(id) && !value[id]) {
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.required }));
        }
    };

    // TODO: 수정 필요
    // add diagnosis button click handler
    const clickButton = async (type: string) => {
        setLoading(true);
        let err: ValueType = { ...errorInitValue };
        if (type === 'cancel') {
            console.log('@!!-->selectedDate', selectedDate);
            setLoading(false);
            if (movePageState) {
                return setIsLeaveModal();
            } else {
                navigate(-1);
            }
            // navigate(pagePaths.login);
        } else if (type === 'submit') {
            let checkError = false;
            const excludeValue = ['patientCode', 'hospitalId', 'hospitalname', 'diagnosisDateTime', 'patientMemo', 'grade', 'confirmPatient', 'schoolAge', 'semester'];

            const processEntries = () => {
                for (let [key, keyValue] of Object.entries(value)) {
                    if (!onConfirmBox) {
                        err = handleProcessEntriesErrMsgFunc('confirmPatient', commonErrText.confirmRegistSystem, err, setErrMsg);
                    }

                    if (!value.grade && schoolAgeIdx === undefined) {
                        err = handleProcessEntriesErrMsgFunc('schoolAge', commonErrText.required, err, setErrMsg);
                    }

                    if ((key === 'schoolAge' || (key === 'semester' && semesterIdx !== undefined)) && !keyValue) {
                        err = handleProcessEntriesErrMsgFunc(key, commonErrText.requiredSchoolAgeAndSemester, err, setErrMsg);
                    } else if (key === 'schoolAge' && schoolAgeIdx === undefined) {
                        err = handleProcessEntriesErrMsgFunc(key, commonErrText.requiredSchoolAgeAndSemester, err, setErrMsg);
                    } else if (key === 'semester' && semesterIdx === undefined && schoolAgeIdx !== 0) {
                        err = handleProcessEntriesErrMsgFunc(key, commonErrText.requiredSchoolAgeAndSemester, err, setErrMsg);
                    } else if (!keyValue && !excludeValue.includes(key)) {
                        err = handleProcessEntriesErrMsgFunc(key, commonErrText.required, err, setErrMsg);
                    }
                }
            };

            const processCheckError = () => {
                for (let value of Object.values(err)) {
                    if (value) {
                        checkError = false;
                        window.scrollTo(0, 0);
                        setLoading(false);
                        break;
                    } else {
                        checkError = true;
                    }
                }
            };

            // focus 이동 함수
            const focusInput = () => {
                switch (true) {
                    case err.patientName.length !== 0:
                        return patientNameInputRef.current?.focus();
                    case err.birthDate.length !== 0:
                        return birthDateInputRef.current?.focus();
                    case err.gender.length !== 0:
                        return genderInputRef.current?.focus();
                    case err.confirmPatient.length !== 0:
                        return window.scrollTo(0, 0);
                    case err.patientMobile.length !== 0:
                        window.scrollTo(0, 0);
                        return patientMobileInputRef.current?.focus();
                    case err.schoolAge.length !== 0 || err.semester.length !== 0:
                        return window.scrollTo(0, 0);
                }
            };

            const processApiCall = async () => {
                try {
                    if (checkError && schoolAgeIdx !== undefined && onConfirmBox) {
                        await addDiagnosisApiCall(value, loginData, selectedDate, selectedTime, navigate, setInit);
                    }
                } catch (error) {
                    console.log(error);
                }
            };

            processEntries();
            processCheckError();
            focusInput();
            processApiCall();
        }
    };

    // 환자 등록 여부 확인
    const patientFindFunc = async () => {
        setErrMsg((prevErrMsg) => ({ ...prevErrMsg, confirmPatient: '' }));

        const rqData = {
            name: value.patientName,
            identityNumber: value.birthDate + value.gender,
            hospitalId: loginData.hospitalId,
        };

        const response = await hospitalAPI.patientFind(rqData, loginData.token);

        if (response.data.length !== 0) {
            const { birthDate, ageString, gender } = calculateAge(response.data[0].identityNumber);

            setValue((prevValue) => ({ ...prevValue, patientCode: response.data[0].patientCode, patientMobile: response.data[0].mobile, patientMemo: response.data[0].patientMemo }));
            setShowPatientInfo({
                regNum: response.data[0].regNum,
                name: response.data[0].name,
                gender: gender,
                age: ageString,
                birthDate: birthDate,
                registeredPatient: commonErrText.registeredPatient,
            });
        } else {
            setValue((prevValue) => ({ ...prevValue, patientCode: '', patientMobile: '', patientMemo: '' }));
            setShowPatientInfo((prevInfo) => ({ ...prevInfo, registeredPatient: commonErrText.notRegisteredPatient }));
        }

        setButtonDisabled(true);
        setOnConfirmBox(true);
    };

    // TODO: 추가 수정 고민해보기 (중복되더라도 함수 묶어서 할지 말지)
    // 학령 / 학기 dropdown on/off handler
    const handleSchoolAgeAndSemesterDropDown = (id: string, idx: number | undefined, name: string, value: string) => {
        if (id === 'schoolAge') {
            setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
            setValue((prevValue) => ({ ...prevValue, grade: '', schoolAge: name, semester: '' }));
            setSemesterIdx(undefined);
            setSchoolAgeIdx(idx);

            switch (idx) {
                case 0:
                    idx !== undefined && setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
                    break;
                // case undefined:
                //     setOnSchoolAgeDropdown(!onSchoolAgeDropdown);
                //     setOnSemesterDropdown(false);
                //     break;
                default:
                    idx !== undefined && setErrMsg((prevErrMsg) => ({ ...prevErrMsg, [id]: commonErrText.requiredSchoolAgeAndSemester }));
            }
        } else if (id === 'semester' && idx !== undefined) {
            setValue((prevValue) => ({ ...prevValue, grade: value, semester: name }));
            setOnSemesterDropdown(!onSemesterDropdown);
            setSemesterIdx(idx);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, schoolAge: '', semester: '' }));
        } else {
            setOnSemesterDropdown(!onSemesterDropdown);
            setOnSchoolAgeDropdown(false);
        }
    };

    const handleDoctorData = (idx: number) => {
        setDoctorsIdx(idx);
        setValue((prevValue) => ({ ...prevValue, doctorName: loginData.doctors[idx].name }));
    };

    // 주민등록번호 앞자리 입력 및 유효성 검사
    const handleIdentityNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        setValue({ ...value, birthDate: newValue });
        const { genderCodeError } = calculateAge(e.target.value + value.gender);
        const valid = isValidBirthdate(e.target.value);

        if (onConfirmBox) {
            setOnConfirmBox(false);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, confirmPatient: commonErrText.confirmRegistSystem }));
        }

        if (!e.target.value && !value.gender) {
            setButtonDisabled(true);
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '필수 정보입니다.' }));
        } else if (!valid || genderCodeError) {
            setButtonDisabled(true);
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '주민등록번호를 정확히 입력해주세요.' }));
        }

        setButtonDisabled(false);
        return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '' }));
    };

    // 주민번호 뒷자리(성별) 입력 및 유효성 검사
    const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/[^0-9]/g, '');
        setValue({ ...value, gender: newValue });
        const { genderCodeError } = calculateAge(value.birthDate + e.target.value);
        const valid = isValidBirthdate(value.birthDate);

        if (onConfirmBox) {
            setOnConfirmBox(false);
            setErrMsg((prevErrMsg) => ({ ...prevErrMsg, confirmPatient: commonErrText.confirmRegistSystem }));
        }

        if (!value.birthDate && !e.target.value) {
            setButtonDisabled(true);
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '필수 정보입니다.' }));
        } else if (!valid || genderCodeError) {
            setButtonDisabled(true);
            return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '주민등록번호를 정확히 입력해주세요.' }));
        }

        setButtonDisabled(false);
        return setErrMsg((prevErrMsg) => ({ ...prevErrMsg, birthDate: '' }));
    };

    // modal 나가기 버튼
    const clickLeaveButtonInLeaveModal = () => {
        if (clickType === 'sideBar' || clickType === 'header') {
            setInit();
            return navigate(movePath);
        } else {
            navigate(-1);
            return setIsLeaveModal();
        }
    };

    const clickCancelButton = () => {
        if (movePageState) {
            return setIsLeaveModal();
        } else {
            navigate(-1);
        }
    };

    // 등록된 환자 체크 박스 handler
    const handleConfirmBox = () => {
        if (showPatientInfo.registeredPatient === '시스템에 등록된 환자입니다.') {
            return (
                <>
                    <ConfirmTextWrapper>
                        <ConfirmCheck />
                        <ConfirmText>{showPatientInfo.registeredPatient}</ConfirmText>
                    </ConfirmTextWrapper>
                    <PatientInfoWrapper>
                        <RoundBasicBadge>{showPatientInfo.regNum}</RoundBasicBadge>
                        <ConfirmBoxText>{showPatientInfo.name}</ConfirmBoxText>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {showPatientInfo.gender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}
                        </span>
                        <ConfirmBoxAge>{showPatientInfo.birthDate}</ConfirmBoxAge>
                        <ConfirmBoxAge>({showPatientInfo.age})</ConfirmBoxAge>
                    </PatientInfoWrapper>
                </>
            );
        } else {
            return (
                <ConfirmTextWrapper>
                    <UnConfirmCheck />
                    <NewPatientText>{showPatientInfo.registeredPatient}</NewPatientText>
                </ConfirmTextWrapper>
            );
        }
    };

    return (
        <>
            {isLeaveModal && (
                <LeaveModal
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButtonInLeaveModal}
                    title='진료를 추가하지 않고 나갈까요?'
                    content='지금 나가시면, 작성중인 내용이 저장되지 않고 삭제됩니다.'
                    firstButton='머무르기'
                    secondButton='나가기'
                />
            )}
            <CounselingPageLayout>
                <CounslingInnerLayout>
                    <PageLabelAndTitleWrapper>
                        <PageLabel>진료 추가</PageLabel>
                        <PageTitle $marginbottom='16px'>새로운 진료 추가하기</PageTitle>
                        <ExplainTextWrapper>
                            <ExplainText>시스템에 등록된 환자인지 여부를 확인해주세요.</ExplainText>
                            <ExplainText>나머지 정보를 입력하고, 진료를 추가해보세요.</ExplainText>
                        </ExplainTextWrapper>
                    </PageLabelAndTitleWrapper>
                    <InputContainer $paddingbottom='40px' $borderbottom={`1px solid ${BorderColor.color_border_neutral}`}>
                        <BasicInputWithLabel
                            ref={patientNameInputRef}
                            label='환자 이름'
                            placeholder='이름'
                            onChange={handleInputChange}
                            id='patientName'
                            focus={true}
                            value={value.patientName}
                            errorMsg={errMsg.patientName}
                        />
                        <InputAndErrMsgWrapper>
                            <InputLabel $marginBottom='8px'>환자 주민등록번호</InputLabel>
                            <IdentityNumberInputWrapper>
                                <BasicInput
                                    ref={birthDateInputRef}
                                    id='identityNumber'
                                    placeholder='000000'
                                    onChange={(e) => {
                                        handleIdentityNumberChange(e);
                                        if (e.target.value.length === 6) genderInputRef.current?.focus();
                                    }}
                                    value={value.birthDate}
                                    onBlur={() => handleBlurError('birthDate')}
                                    width='205px'
                                    $warning={errMsg.birthDate ? 'true' : ''}
                                />
                                <span>-</span>
                                <BasicInput
                                    id='identityNumber'
                                    placeholder='0'
                                    onChange={(e) => handleGenderChange(e)}
                                    value={value.gender}
                                    onBlur={() => handleBlurError('gender')}
                                    width='205px'
                                    ref={genderInputRef}
                                    maxLength={1}
                                    $warning={errMsg.birthDate ? 'true' : ''}
                                />
                                <IdentityNumberWrapper>
                                    <IdentityNumber />
                                </IdentityNumberWrapper>
                            </IdentityNumberInputWrapper>
                            {errMsg.birthDate && <ErrorMsg>{errMsg.birthDate}</ErrorMsg>}
                        </InputAndErrMsgWrapper>
                        <BasicButton disabled={buttonDisabled} $type='outLine' onClick={patientFindFunc}>
                            등록 여부 확인하기
                        </BasicButton>
                        {errMsg.confirmPatient && (
                            <ConfirmWarningWrapper>
                                <ConfirmWarningText>{errMsg.confirmPatient}</ConfirmWarningText>
                            </ConfirmWarningWrapper>
                        )}
                        {onConfirmBox && <ConfirmWrapper $type={showPatientInfo.registeredPatient}>{handleConfirmBox()}</ConfirmWrapper>}
                    </InputContainer>
                    <InputContainer $minheight='605px'>
                        <AddDiagnosisInputWithLabel
                            ref={patientMobileInputRef}
                            label='환자 연락처'
                            desc='환자와 관련된 연락처를 입력해주세요.'
                            subDesc='ex) 부모님의 휴대폰 번호'
                            placeholder='연락처(숫자만 입력)'
                            onChange={handleInputChange}
                            id='patientMobile'
                            value={value.patientMobile}
                            errorMsg={errMsg.patientMobile}
                            onBlur={handleBlurError}
                        />
                        <IdentityNumberInputWrapper>
                            <DoctorNameInputWithLabel
                                ref={schoolAgeInputRef}
                                label='학령 / 학기'
                                placeholder='학령 선택'
                                id='schoolAge'
                                value={schoolAgeIdx !== undefined ? schoolAge[schoolAgeIdx] : ''}
                                errorMsg={errMsg.schoolAge || errMsg.semester}
                                onDropdown={onSchoolAgeDropdown}
                                setDropdown={handleSchoolAgeAndSemesterDropDown}
                                schoolAgeIdx={schoolAgeIdx}
                                onBlur={handleBlurError}
                            />
                            <DoctorNameInputWithLabel
                                ref={semesterInputRef}
                                label=''
                                placeholder='학기 선택'
                                id='semester'
                                value={
                                    semesterIdx !== undefined && schoolAgeIdx === 1
                                        ? primarySchool[semesterIdx].semester
                                        : semesterIdx !== undefined && schoolAgeIdx === 2
                                        ? middleSchool[semesterIdx].semester
                                        : semesterIdx !== undefined && schoolAgeIdx === 3
                                        ? highSchool[semesterIdx].semester
                                        : ''
                                }
                                errorMsg={errMsg.schoolAge || errMsg.semester}
                                setDropdown={handleSchoolAgeAndSemesterDropDown}
                                onDropdown={onSemesterDropdown}
                                schoolAgeIdx={schoolAgeIdx}
                                semesterIdx={semesterIdx}
                                onBlur={handleBlurError}
                            />
                        </IdentityNumberInputWrapper>
                        <AddDiagnosisInputWithLabel
                            label='진료 일시'
                            desc='선택하지 않으면, 현재에서 가까운 시각으로 등록됩니다.'
                            placeholder=''
                            id='diagnosisDateTime'
                            value={selectedDate + ' ' + selectedTime}
                            errorMsg=''
                            setOnCalendar={setOnCalendar}
                            onCalendar={onCalendar}
                            setSelectedDate={setSelectedDate}
                            setSelectedTime={setSelectedTime}
                            selectedTime={selectedTime}
                            selectedDate={selectedDate}
                            initDate={initDate}
                        />
                        <DoctorNameInputWithLabel
                            label='담당의'
                            desc={loginData.doctors.length > 1 ? `총 ${loginData.doctors.length}명` : ''}
                            placeholder=''
                            id='doctorName'
                            value=''
                            setDoctors={handleDoctorData}
                            doctorsIdx={doctorsIdx}
                        />
                        <AddDiagnosisInputWithLabel label='환자 ID' placeholder='환자 ID' onChange={handleInputChange} id='patientCode' value={value.patientCode} errorMsg={''} />
                        <AddDiagnosisInputWithLabel
                            label='메모'
                            placeholder='환자에 대한 메모가 있다면 작성해주세요.'
                            onMemoChange={handleInputChange}
                            id='patientMemo'
                            value={value.patientMemo}
                            errorMsg={''}
                        />
                    </InputContainer>
                    <ButtonContainer $margintop='12px'>
                        <BasicButton
                            $width='120px'
                            $type='anotherFill'
                            onClick={() => {
                                clickButton('cancel');
                            }}
                        >
                            {commonText.cancel}
                        </BasicButton>
                        <BasicButton
                            $width='310px'
                            $type='fill'
                            onClick={() => {
                                clickButton('submit');
                            }}
                        >
                            진료 추가하기
                        </BasicButton>
                    </ButtonContainer>
                </CounslingInnerLayout>
            </CounselingPageLayout>
        </>
    );
};

export default AddDiagnosisPage;

const IdentityNumberInputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ConfirmWrapper = styled.div<ConfirmWrapperType>`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 16px 20px;
    border-radius: ${Radius.radius_4};
    background-color: ${(props) => (props.$type === '시스템에 등록된 환자입니다.' ? BackgroundColor.color_background_primary_neutral : Accent['color-accent-lime-opacity-8'])};
    margin-top: -16px;
`;

const ConfirmTextWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const ConfirmText = styled(Body4_Medium)`
    color: ${PrimaryColor.color_primary_normal};
`;

const NewPatientText = styled(Body4_Medium)`
    color: ${TextColor.color_text_normal};
`;

const PatientInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px 20px;
    background-color: ${BackgroundColor.color_background_primary_strong};
    border-radius: ${Radius.radius_4};
    gap: 2px;
`;

const ConfirmWarningWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    background-color: ${BackgroundColor.color_background_warning};
    margin-top: -16px;
`;

const ConfirmWarningText = styled(Body4_Medium)`
    color: ${WarningColor.color_warning_normal};
`;

const InputAndErrMsgWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const IdentityNumberWrapper = styled.div`
    position: absolute;
    right: 100px;
    top: 17px;
`;

const ConfirmBoxText = styled(Body5SemiBold)`
    color: ${TextColor.color_text_normal};
    margin-left: 4px;
`;
const ConfirmBoxAge = styled(Body5Regular)`
    color: ${TextColor.color_text_normal};
`;
