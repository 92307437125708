import { useEffect, useState } from 'react';
import Calendar, { OnArgs } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import '../../styles/Global/customCalender.css';
import { CustomCalendarProps, Value } from 'models/Common/calendarTypes';
import styled from 'styled-components';

export const CustomCalendar = ({ setSelectedDate, selectedDate, maxDate, minDate, selectedPeriod, type, setSelectSequence, setCalendarInputWarning }: CustomCalendarProps) => {
    const [value, onChange] = useState<Value>(selectedDate ? new Date(selectedDate) : null);
    const [activeStartDate, setActiveStartDate] = useState<Date>(selectedDate ? new Date(selectedDate) : new Date());

    useEffect(() => {
        if (selectedPeriod === 4 && setSelectedDate && !value) {
            setSelectedDate(null);
        } else {
            if (setSelectSequence && type) setSelectSequence(type);
            setCalendarInputWarning && setCalendarInputWarning(false);
            setSelectedDate && setSelectedDate(moment(value as Date).format('YYYY/MM/DD'));
        }
    }, [value]);

    const handleActiveStartDateChange = ({ activeStartDate }: OnArgs) => {
        activeStartDate && setActiveStartDate(activeStartDate);
    };
    return (
        <div>
            <Calendar
                onChange={onChange}
                value={selectedDate}
                minDate={minDate || maxDate || selectedPeriod ? undefined : new Date()}
                formatDay={(locale, date) => moment(date).format('D')}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={activeStartDate}
                onActiveStartDateChange={handleActiveStartDateChange}
                showFixedNumberOfWeeks={true}
                // showNeighboringMonth={false}
            />
        </div>
    );
};

export const AddDiagnosisPageCalendar = ({ setSelectedDate, selectedDate }: CustomCalendarProps) => {
    console.log('@!!--> selectedDate', selectedDate);
    const [value, onChange] = useState<Value>(selectedDate ? new Date(selectedDate) : new Date());

    useEffect(() => {
        setSelectedDate && setSelectedDate(moment(value as Date).format('YYYY/MM/DD'));
    }, [value]);

    const handleActiveStartDateChange = ({ activeStartDate, view }: OnArgs) => {
        if (view === 'month' && activeStartDate) {
            const today = new Date();
            if (activeStartDate.getMonth() === today.getMonth() && activeStartDate.getFullYear() === today.getFullYear()) {
                onChange(today);
            } else {
                onChange(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
            }
        } else if (view === 'year' && activeStartDate) {
            onChange(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1));
        } else if ((activeStartDate && view === 'decade') || (view === 'century' && activeStartDate)) {
            onChange(new Date(activeStartDate.getFullYear(), 0, 1));
        }
    };

    return (
        <div>
            <Calendar
                onChange={onChange}
                value={selectedDate ? new Date(selectedDate) : new Date()}
                minDate={new Date()}
                formatDay={(locale, date) => moment(date).format('D')}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={selectedDate ? new Date(selectedDate) : new Date()}
                onActiveStartDateChange={handleActiveStartDateChange}
                // showNeighboringMonth={false}
            />
        </div>
    );
};

export const HomeCalendar = () => {
    return (
        <>
            <Calendar
                value={new Date()}
                formatDay={(locale, date) => moment(date).format('D')}
                nextLabel={null}
                prevLabel={null}
                next2Label={null}
                prev2Label={null}
                calendarType='gregory'
                activeStartDate={new Date()}
                tileDisabled={() => true}
                minDetail='month'
            />
        </>
    );
};
