import { BasicButtonType } from 'models/Common/buttonTypes';
import styled from 'styled-components';
import { BackgroundColor, InverseColor, PrimaryColor, OpacityColor, WarningColor } from 'styles/Global/colorSemantic';
import { Border } from 'styles/Global/objectStyles';
import { Body5Medium } from 'styles/Global/typography';

export const PositiveButton = ({ children, $width, onClick, disabled, $height, $gap, $type }: BasicButtonType) => {
    return (
        <PositiveButtonCss $width={$width} onClick={onClick} disabled={disabled} $height={$height} $gap={$gap} $type={$type}>
            {children}
        </PositiveButtonCss>
    );
};
export const NegativeButton = ({ children, $width, onClick, disabled, $height, $gap, $type }: BasicButtonType) => {
    return (
        <NegativeButtonCss $width={$width} onClick={onClick} disabled={disabled} $height={$height} $gap={$gap} $type={$type}>
            {children}
        </NegativeButtonCss>
    );
};

const VariantsBasicButtonCss = styled(Body5Medium)<BasicButtonType>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width ?? '100%'};
    height: ${(props) => props.$height ?? '100%'};
    border-radius: 4px;
    line-height: normal;
`;

const PositiveButtonCss = styled(VariantsBasicButtonCss)<BasicButtonType>`
    color: ${PrimaryColor.color_primary_strong};
    border: ${Border.border_1} solid ${PrimaryColor.color_primary_strong};
    &:hover {
        border: none;
        background-color: ${PrimaryColor.color_primary_normal};
        color: ${InverseColor.color_inverse_white};
    }
    &:active {
        border: none;
        background-color: ${PrimaryColor.color_primary_heavy};
        color: ${InverseColor.color_inverse_white};
    }
    &:focus {
        border: none;
        background-color: ${PrimaryColor.color_primary_heavy};
        color: ${InverseColor.color_inverse_white};
    }
    // &:disabled {
    //     background-color: ${BackgroundColor.color_background_primary_disabled}; // disabled 상태일 때의 배경색
    //     color: ${OpacityColor.color_opacity_white_80};
    //     border: none;
    //     cursor: not-allowed;
    // };
`;
const NegativeButtonCss = styled(VariantsBasicButtonCss)<BasicButtonType>`
    color: ${WarningColor.color_warning_normal};
    border: ${Border.border_1} solid ${WarningColor.color_warning_normal};
    &:hover {
        border: none;
        background-color: ${WarningColor.color_warning_normal};
        color: ${InverseColor.color_inverse_white};
    }
    &:active {
        border: none;
        background-color: ${WarningColor.color_warning_normal_press};
        color: ${InverseColor.color_inverse_white};
    }
    &:focus {
        border: none;
        background-color: ${WarningColor.color_warning_normal_press};
        color: ${InverseColor.color_inverse_white};
    }
    // &:disabled {
    //     background-color: ${BackgroundColor.color_background_primary_disabled}; // disabled 상태일 때의 배경색
    //     color: ${OpacityColor.color_opacity_white_80};
    //     border: none;
    //     cursor: not-allowed;
    // };
`;
