import { useEffect } from 'react';
import { GlobalStyles } from './styles/Global/globalStyles';
import Header from './components/Common/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideBar from 'components/Common/SideBar';
import styled from 'styled-components';
import axios from 'axios';
import Loading from 'components/Common/Loading';
import { useLoadingStore } from 'store/useLoadingStore';
import { pagePaths } from 'constants/path';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import AlertDialog from 'components/Modal/AlertDialog';
import { alertDialogConstants } from 'constants/appConstants';
import { ConfigProvider } from 'antd';
import customTheme from 'styles/theme';
import ko_KR from 'antd/locale/ko_KR';
import LeaveModal from 'components/Modal/LeaveModal';
import { logoutModalContants } from 'constants/headerConstants';
import { useLoginStore } from 'store/useLoginDataStore';
import { commonText } from 'constants/commonConstants';

function App() {
    const { clearLoginData } = useLoginStore();

    const location = useLocation();
    const navigate = useNavigate();

    const { loading, setLoading } = useLoadingStore();

    const { isAlartDialog, setIsAlartDialog, isLogoutModal, setIsLogoutModal } = useLeaveModalStateStore();

    // TODO: jwt-decode 설치 후 테스트 필요(header에 token 자동 추가 및 토큰 만료 체킹, 로그아웃)
    // interface DecodedToken {
    //     exp: number;
    //     [key: string]: any;
    // }

    // const isTokenExpired = (token: string): boolean => {
    //     const decoded: DecodedToken = jwtDecode(token);
    //     const currentTime = Date.now() / 1000;
    //     return decoded.exp < currentTime;
    // };

    useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                // TODO: 토큰 체킹 테스트
                // const token = axios.defaults.headers.common['Authorization']?.split(' ')[1];
                // if (token && isTokenExpired(token)) {
                //     // 토큰이 만료된 경우 로그아웃 처리
                //     localStorage.clear();
                //     navigate('/login'); // 로그인 페이지로 리다이렉트
                //     return Promise.reject(new Error('Token expired'));
                // }

                return config;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                setLoading(false);
                if (error.response.status === 534) {
                    localStorage.clear();
                    setIsAlartDialog(true);
                    return navigate(pagePaths.login, { replace: true });
                }
                console.log(error, 'axiosInterceptResponseError');
                return Promise.reject(error);
            }
        );
    }, []);

    // logout func
    const logout = () => {
        localStorage.clear();
        clearLoginData();
        setIsLogoutModal(false);
        navigate(pagePaths.login, { replace: true });
    };

    const renderHeader = () => {
        const pathsWithoutHeader = ['/', '/sign-up'];
        return !pathsWithoutHeader.includes(location.pathname);
    };

    return (
        <>
            <GlobalStyles />
            <ConfigProvider locale={ko_KR} theme={customTheme} wave={{ disabled: true }}>
                <AppLayout>
                    {isLogoutModal && (
                        <LeaveModal
                            title={logoutModalContants.title}
                            content={logoutModalContants.content}
                            setIsLeaveModal={() => setIsLogoutModal(false)}
                            clickLeaveButtonInLeaveModal={logout}
                            firstButton={commonText.cancel}
                            secondButton={commonText.logout}
                        />
                    )}
                    {isAlartDialog && (
                        <AlertDialog
                            $title={alertDialogConstants.title}
                            $content={alertDialogConstants.content}
                            $subContent={alertDialogConstants.subContent}
                            $onClick={() => setIsAlartDialog(false)}
                        />
                    )}
                    <Loading loading={loading} />
                    <SideBar location={renderHeader()} />
                    <InnerLayout isFirstPage={location.pathname === '/'}>
                        {renderHeader() && <Header />}
                        <Outlet />
                    </InnerLayout>
                </AppLayout>
            </ConfigProvider>
        </>
    );
}

export default App;

const AppLayout = styled.div`
    position: relative;
    display: flex;
    width: 100vw;
    min-height: 100vh;
`;

const InnerLayout = styled.div<{ isFirstPage?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-left: ${(props) => (props.isFirstPage ? '88px' : '320px')};
    min-width: 960px;
    // min-height: 100vh;
`;
