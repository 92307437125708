import { loginValidate } from 'functions/validationFunctions';
import _ from 'lodash';
import packageJson from '../../../package.json';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { PageLayout, PageTitle } from 'styles/Global/commonStyles';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useLoginStore } from 'store/useLoginDataStore';
import { signUpApi } from 'api/SignUp/signUpApi';
import { useNavigate } from 'react-router-dom';
import { ExistUserApiType, SignUpApiResponseType } from 'models/SignUp/signUpApiTypes';
import { addDiagnosisApi } from 'api/Hospital/addDiagnosisApi';
import styled from 'styled-components';
import OpenEye from 'assets/Icons/Password/PasswordShow';
import CloseEye from 'assets/Icons/Password/PasswordHide';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import { palceholderText } from 'constants/commonConstants';
import { Body1_Medium_input, Body1_Regular_Button, Body3_Medium, Body3_Regular, Body5Regular } from 'styles/Global/typography';
import { BackgroundColor, BorderColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { tabletPaths } from 'constants/path';
import { error } from 'console';

interface InputType {
    padding: string;
}

const ManagerLogin = () => {
    const { setLoginData } = useLoginStore();
    const navigate = useNavigate();
    const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [passwordType, setPassWordType] = useState('password');
    const [errorMsg, setErrorMsg] = useState({ email: '', password: '' });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const { resetPatient } = usePatientStore();
    const { resetAssessment } = useAssessmentStore();
    const clearLocalStorage = () => {
        localStorage.clear();
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setLoginInfo((prev) => ({ ...prev, [id]: value }));

        const error = loginValidate[id](value);
        setErrorMsg((prev) => ({ ...prev, [id]: error }));
    };

    const saveHoispitalInfo = async (res: SignUpApiResponseType) => {
        try {
            const getHospitalDataApiResponse = await addDiagnosisApi.getHospitalData(res.data.user.hospitalId, res.data.bearerToken);
            if (getHospitalDataApiResponse.status === 'ok') {
                setLoginData({
                    id: res.data.user._id,
                    email: res.data.user.email,
                    hospitalName: res.data.user.hospitalName,
                    hospitalId: res.data.user.hospitalId,
                    level: res.data.user.level,
                    mobile: res.data.user.mobile,
                    name: res.data.user.name,
                    status: res.data.user.status,
                    token: res.data.bearerToken,
                    doctors: getHospitalDataApiResponse.data.doctors,
                });
                if (res.data.user.status === 1) {
                    navigate(tabletPaths.login);
                } else {
                    console.log('panding 상태, home으로 이동');
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const checkAuthEmail = async () => {
        try {
            const response = await signUpApi.existUser(loginInfo.email);

            if (response.status === 'ok') {
                console.log(response, 'response');
                loginApi(response);
            } else {
                setLoading(false);
                setErrorMsg((prev) => ({ ...prev, email: '가입되지 않은 이메일 아이디입니다.' }));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loginApi = async (existUserResponse: ExistUserApiType) => {
        try {
            const auth = getAuth();
            const { email, password } = loginInfo;
            signInWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    const version = require('../../../package.json').version;
                    const idToken = await userCredential.user.getIdToken();
                    const rqData = {
                        idToken: idToken,
                        deviceType: 3,
                        os: 'web',
                        model: 'chrome',
                        version: version,
                        token: existUserResponse.data.fcmToken,
                    };

                    const response = await signUpApi.oauth(rqData);

                    if (response.status === 'ok') {
                        setLoading(false);
                        saveHoispitalInfo(response);
                    } else {
                        console.log('oauth 로그인 실패');
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setErrorMsg((prev) => ({ ...prev, password: '잘못된 비밀번호입니다. 다시 입력해주세요.' }));
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    const handleClickShowPassword = () => {
        if (passwordType === 'password') {
            setPassWordType('text');
            setIsPasswordVisible(true);
        } else {
            setPassWordType('password');
            setIsPasswordVisible(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'Enter' && e.currentTarget.id === 'email') {
            inputRef.current?.focus();
        }
        if (e.code === 'Enter' && e.currentTarget.id === 'password') {
            buttonRef.current?.focus();
        }
    };

    const handleClickLogin = () => {
        setLoading(true);
        const { email, password } = loginInfo;
        const emailError = loginValidate.email(email);
        const passwordError = loginValidate.password(password);
        if (_.isEmpty(emailError) && _.isEmpty(passwordError)) {
            checkAuthEmail();
        } else {
            setLoading(false);
            setErrorMsg((prevErrorMsg) => ({ ...prevErrorMsg, email: emailError, password: passwordError }));
        }
    };

    useEffect(() => {
        clearLocalStorage();
        resetPatient();
        resetAssessment();
    }, []);

    return (
        <PageLayout>
            <PageInnerLayOut>
                <PageTextLayout>
                    <PageTitle>로그인</PageTitle>
                    <PageDescription>병원관리자 웹에서 가입하신 아이디로만 로그인이 가능합니다.</PageDescription>
                </PageTextLayout>
                <InputLayout>
                    <Label>이메일 아이디</Label>
                    <Input
                        autoComplete='off'
                        spellCheck={false}
                        placeholder={palceholderText.emailInput}
                        onChange={handleChange}
                        id='email'
                        value={loginInfo.email}
                        onKeyDown={handleKeyDown}
                        padding='12px 50px 12px 24px'
                    />
                    {errorMsg.email && <ErrorMsg>{errorMsg.email}</ErrorMsg>}
                </InputLayout>
                <InputLayout>
                    <Label>비밀번호</Label>
                    <InputBtnLayout>
                        <Input
                            autoComplete='off'
                            spellCheck={false}
                            type={passwordType}
                            placeholder='비밀번호'
                            onChange={handleChange}
                            id='password'
                            ref={inputRef}
                            value={loginInfo.password}
                            onKeyDown={handleKeyDown}
                            padding='12px 72px 12px 24px'
                        />
                        <InputWithLabelIconWrapper onClick={handleClickShowPassword}>{isPasswordVisible ? <OpenEye /> : <CloseEye />}</InputWithLabelIconWrapper>
                    </InputBtnLayout>
                    {errorMsg.password && <ErrorMsg>{errorMsg.password}</ErrorMsg>}
                </InputLayout>
                <BasicButton onClick={handleClickLogin} ref={buttonRef}>
                    로그인
                </BasicButton>
            </PageInnerLayOut>
            <VersionTag>v{packageJson.version}</VersionTag>
        </PageLayout>
    );
};

export default ManagerLogin;

const PageInnerLayOut = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    justify-content: center;
`;

const InputBtnLayout = styled.div`
    position: relative;
    display: flex;
`;

const InputWithLabelIconWrapper = styled.button`
    position: absolute;
    width: 72px;
    height: 100%;
    right: 0px;
`;

const PageTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`;

const PageDescription = styled(Body3_Regular)`
    color: ${TextColor.color_text_light};
`;

const InputLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 8px;
`;

const Label = styled(Body3_Medium)``;

const Input = styled(Body1_Medium_input)<InputType>`
    width: 520px;
    height: 72px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    background: ${BackgroundColor.color_background_white};
    padding: ${(props) => props.padding};
    color: ${TextColor.color_text_normal};
`;

const BasicButton = styled(Body1_Regular_Button)`
    width: 100%;
    height: 72px;
    border: none;
    border-radius: ${Radius.radius_4};
    background-color: ${BackgroundColor.color_background_primary};
    color: ${Static.color_static_white};

    &:active {
        background-color: ${PrimaryColor.color_primary_heavy};
    }
`;

const VersionTag = styled(Body5Regular)`
    position: absolute;
    bottom: 3%;
    right: 5%;
    color: ${TextColor.color_text_weak};
    opacity: 0.5;
`;
