import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const LnbAdminHospitalIcon = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M6.53805 7.00005H4C3.44772 7.00005 3 7.44777 3 8.00005V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V8.00005C21 7.44776 20.5523 7.00005 20 7.00005H17.462C17.2752 6.03156 16.423 5.30005 15.4 5.30005H15.0005V4.90005C15.0005 3.74025 14.0603 2.80005 12.9005 2.80005H11.1005C9.94069 2.80005 9.00049 3.74025 9.00049 4.90005V5.30005H8.6C7.57698 5.30005 6.72482 6.03156 6.53805 7.00005ZM15.0005 11.7V11.3H15.4C16.5598 11.3 17.5 10.3598 17.5 9.20005V9.00005H19V19H16V16C16 15.4478 15.5523 15 15 15H9C8.44772 15 8 15.4478 8 16V19H5V9.00005H6.5V9.20005C6.5 10.3598 7.4402 11.3 8.6 11.3H9.00049V11.7C9.00049 12.8598 9.94069 13.8 11.1005 13.8H12.9005C14.0603 13.8 15.0005 12.8598 15.0005 11.7ZM11.0005 7.20005C11.0005 7.25528 10.9557 7.30005 10.9005 7.30005H8.6C8.54477 7.30005 8.5 7.34482 8.5 7.40005V9.20005C8.5 9.25528 8.54477 9.30005 8.6 9.30005H10.9005C10.9557 9.30005 11.0005 9.34482 11.0005 9.40005V11.7C11.0005 11.7553 11.0453 11.8 11.1005 11.8H12.9005C12.9557 11.8 13.0005 11.7553 13.0005 11.7V9.40005C13.0005 9.34482 13.0453 9.30005 13.1005 9.30005H15.4C15.4552 9.30005 15.5 9.25528 15.5 9.20005V7.40005C15.5 7.34482 15.4552 7.30005 15.4 7.30005H13.1005C13.0453 7.30005 13.0005 7.25528 13.0005 7.20005V4.90005C13.0005 4.84482 12.9557 4.80005 12.9005 4.80005H11.1005C11.0453 4.80005 11.0005 4.84482 11.0005 4.90005V7.20005ZM14 17V19H10V17H14Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default LnbAdminHospitalIcon;
