import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import _ from 'lodash';
import { GetMonthlySettlementResponse, GetPaymentListApiResponse, GetQuarterSettlementResponse } from 'models/Admin/calculatePageApiTypes';

type IndividualProps = {
    token: string;
    dateList?: string[];
    searchType?: number;
    searchKeyword?: string;
    page?: number;
    limit?: number;
};

type QuarterProps = {
    token: string;
    selectedYear?: number;
    selectedSearchType?: number;
    searchKeyword?: string;
    page?: number;
    limit?: number;
};

const defaultPageIdx = 1;
const defaultPostCount = 12;
const now = new Date();

class SettlementPageApi {
    async getPaymentList({ token, dateList, searchType, searchKeyword, page }: IndividualProps): Promise<GetPaymentListApiResponse> {
        const url = `${apiPaths.paymentList}?startDate=${_.first(dateList)}&endDate=${_.last(dateList)}&searchType=${searchType}&searchKeyword=${searchKeyword}&page=${page}&limit=${defaultPostCount}`;

        try {
            const resopnse = await axios.get(url, { headers: tokenHeader(token) });
            return resopnse.data;
        } catch (error) {
            throw error;
        }
    }

    async getQuarterPaymentList({ token, selectedYear, selectedSearchType, searchKeyword, page, limit }: QuarterProps): Promise<GetQuarterSettlementResponse> {
        const year = selectedYear ?? now.getFullYear();
        const searchType = selectedSearchType ?? 0;
        const keyword = searchKeyword ?? '';
        try {
            const url = `${apiPaths.getQuarterPayment}?year=${year}&searchType=${searchType}&searchKeyword=${keyword}`;
            let response = await axios.get(url, { headers: tokenHeader(token) });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getMonthlyPaymentList({ token, selectedYear, selectedSearchType, searchKeyword, page, limit }: QuarterProps): Promise<GetMonthlySettlementResponse> {
        const year = selectedYear ?? now.getFullYear();
        const searchType = selectedSearchType ?? 0;
        const keyword = searchKeyword ?? '';

        try {
            const url = `${apiPaths.getMonthlyPayment}?year=${year}&searchType=${searchType}&searchKeyword=${keyword}`;
            let response = await axios.get(url, { headers: tokenHeader(token) });

            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const calculatePageApi = new SettlementPageApi();
