import ArrowLineRight from 'assets/Icons/Arrow/ArrowLineRight';
import IconArrowLineRight from 'assets/Icons/Arrow/Icon_arrowLine_right';
import styled from 'styled-components';
import { Accent, BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body5Regular, Body5SemiBold } from 'styles/Global/typography';

interface MobileLabelNumberProps {
    $title: string;
    $subTitle: string;
    $desc: string;
    $label: string;
    $type: string;
    $number: number;
    $bedgeType: string;
    $onClick: () => void;
}

export const MobileAssessmentButton = ({ $title, $subTitle, $desc, $label, $type, $number, $bedgeType, $onClick }: MobileLabelNumberProps) => {
    return (
        <ButtonContainer onClick={$onClick}>
            <PreciousAssessmentInfoContainer>
                <InfoContainer>
                    {$type === 'cyan' ? (
                        <PreciousTestTypeBadge isChildTest={true}>{$label}</PreciousTestTypeBadge>
                    ) : (
                        <PreciousTestTypeBadge isChildTest={false} textColor={Accent.color_accent_lime_heavy}>
                            {$label}
                        </PreciousTestTypeBadge>
                    )}
                    <InfoTextContainer>
                        <Body5SemiboldText>{$title}</Body5SemiboldText>
                        <Body5RegualarText>{$desc}</Body5RegualarText>
                    </InfoTextContainer>
                </InfoContainer>
            </PreciousAssessmentInfoContainer>
            <ArrowContainer>
                <IconArrowLineRight color={PrimaryColor.color_primary_normal} />
            </ArrowContainer>
        </ButtonContainer>
    );
};

const RelationContainer = styled.div<{ isChild: boolean }>`
    color: ${(props) => (props.isChild ? Accent.color_accent_cyan_weak : Accent.color_accent_lime_light)};
    padding: 4px 8px;
    background-color: ${(props) => (props.isChild ? Accent.color_accent_lime_light : Accent.color_accent_lime_heavy)};
    border-radius: 999px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 16px;
    border: 1px solid ${BorderColor.color_border_light};
    border-radius: 8px;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const InfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 4px;
`;

const PreciousAssessmentInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PreciousTestTypeBadge = styled.div<{ isChildTest: boolean; backgroundColor?: string; textColor?: string }>`
    width: fit-content;
    padding: 4px 8px;
    border-radius: 999px;
    background-color: ${(props) => props.backgroundColor ?? Accent.color_accent_lime_weak};
    color: ${(props) => props.textColor ?? Accent.color_accent_lime_heavy};
    font-family: ${Body5SemiBold};
    flex-grow: 0;
    flex-shrink: 0;
`;

const Body5SemiboldText = styled(Body5SemiBold)<{ textColor?: string }>`
    color: ${(props) => props.textColor ?? TextColor.color_text_normal};
`;

const Body5RegualarText = styled(Body5Regular)<{ textColor?: string }>`
    color: ${(props) => props.textColor ?? TextColor.color_text_neutral};
`;

const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 11px 13px;
    border-radius: 12px;
    background-color: ${BackgroundColor.color_background_primary_normal};
`;
