import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { UpdateAccountApiResponse, UpdateAccountRqData, UserChangeStatusRqData } from 'models/Hospital/hospitalApiTypes';

class UserApi {
    async updateUser(rqData: UpdateAccountRqData, token: string): Promise<UpdateAccountApiResponse> {
        try {
            const response = await axios.put(apiPaths.updateUserInfo, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async userChangeStatus(rqData: UserChangeStatusRqData, token: string): Promise<UpdateAccountApiResponse> {
        try {
            const response = await axios.put(apiPaths.userChangeStatus, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const userApi = new UserApi();
