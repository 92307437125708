import IconArrowDown from 'assets/Icons/Arrow/Icon_arrow_down';
import { AddDiagnosisPageCalendar } from 'components/Common/Calendar';
import TimeInCalendar from 'components/Hospital/TimeInCalendar';
import { InputContainerStyleProps, InputProps } from 'models/Common/inputTypes';
import AddDiagnosisDoctorsDropdown from 'pages/Hospital/AddDiagnosisPage/AddDiagnosisDoctorsDropdown';
import { forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { font, palette } from 'styles/Global/globalStyles';
import { Body3_Medium, Body3_Medium_TextArea, Body5Regular } from 'styles/Global/typography';
import { BasicInput, InputLabel, InputSubLabel, InputSubText, InputWithLabelIconWrapper } from 'styles/Input/inputStyle';
import IconArrowUp from 'assets/Icons/Arrow/Icon_arrow_up';
import Calender from 'assets/Icons/Calender/Calender';
import SchoolAgeSemesterDropdown from 'components/Dropdown/SchoolAgeSemesterDropdown';

export const AddDiagnosisInputWithLabel = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        desc,
        label,
        placeholder,
        onChange,
        id,
        width,
        focus,
        errorMsg,
        value,
        onFocus,
        onBlur,
        disabled,
        subDesc,
        setOnCalendar,
        onCalendar,
        setSelectedDate,
        setSelectedTime,
        selectedTime,
        selectedDate,
        onMemoChange,
        initDate,
        patientCodedesc,
    } = props;
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const calendar = document.querySelector('.react-calendar');
        const parentDiv = calendar?.parentElement;
        parentDiv?.classList.add('react-calendar-parent-class');
    }, [onCalendar]);

    // textArea auto height
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [props.value]);

    useEffect(() => {
        function handleClickOutside(event: { target: any }) {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                if (
                    !event.target.closest('.react-calendar__navigation') &&
                    !event.target.closest('.react-calendar__month-view__days__day') &&
                    !event.target.closest('.react-calendar__year-view__months__month') &&
                    !event.target.closest('.react-calendar__tile.react-calendar__decade-view__years__year') &&
                    !event.target.closest('.react-calendar__tile.react-calendar__century-view__decades__decade')
                ) {
                    if (!event.target.closest('#diagnosisDateTime') && !event.target.closest('#calendarIcon')) {
                        setOnCalendar && setOnCalendar(false);
                    }
                }
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Container $width={width} id={id}>
            <LabelAndSubTextWrapper>
                <InputLabel $marginBottom={desc ? '-4px' : '0'}>{label}</InputLabel>
                {(id === 'patientMemo' || id === 'patientCode') && <PatientIdSubLabel>(선택)</PatientIdSubLabel>}
            </LabelAndSubTextWrapper>
            {desc && id !== 'patientCode' && (
                <InputDescContainer id={id}>
                    <InputSubLabel $marginBottom='0'>{desc}</InputSubLabel>
                    {subDesc && <InputSubText>{subDesc}</InputSubText>}
                </InputDescContainer>
            )}
            <InputWrapper>
                {id === 'patientMemo' ? (
                    <Textarea
                        ref={textareaRef}
                        id={id}
                        onChange={(e) => onMemoChange?.(e, id)}
                        placeholder={placeholder}
                        value={value}
                        onBlur={() => {
                            onBlur && onBlur(id);
                        }}
                        disabled={disabled}
                    />
                ) : (
                    <BasicInput
                        ref={ref}
                        id={id}
                        onChange={(e) => onChange && onChange(e, id === 'patientMobile' && isNaN(Number(e.target.value)) ? 'error' : id)}
                        placeholder={placeholder}
                        autoFocus={focus ?? false}
                        value={value}
                        $warning={errorMsg}
                        onFocus={onFocus}
                        onBlur={() => {
                            onBlur && onBlur(id);
                        }}
                        type='text'
                        disabled={disabled}
                        readOnly={id === 'diagnosisDateTime'}
                        onClick={() => setOnCalendar && setOnCalendar(!onCalendar)}
                        maxLength={id === 'patientMobile' ? 11 : undefined}
                    />
                )}
                {id === 'diagnosisDateTime' && (
                    <InputWithLabelIconWrapper id='calendarIcon' onClick={() => setOnCalendar && setOnCalendar(!onCalendar)}>
                        <Calender />
                    </InputWithLabelIconWrapper>
                )}

                {onCalendar && (
                    <CalenderContainer ref={calendarRef}>
                        <CalenderWrapper>
                            <AddDiagnosisPageCalendar setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                        </CalenderWrapper>
                        <TimeWrapper>
                            <ResetTimeButton onClick={initDate}>오늘로 이동</ResetTimeButton>
                            <TimeInCalendar setSelectedTime={setSelectedTime} selectedTime={selectedTime} selectedDate={selectedDate} />
                        </TimeWrapper>
                    </CalenderContainer>
                )}
            </InputWrapper>
            {errorMsg && <ErrorMsg id={id}>{errorMsg}</ErrorMsg>}
        </Container>
    );
});

// 회원가입 병원명 input component
export const DoctorNameInputWithLabel = forwardRef<HTMLDivElement, InputProps>((props, ref) => {
    const { desc, label, placeholder, id, width, errorMsg, value, onFocus, onDropdown, schoolAgeIdx, onBlur, setDropdown, semesterIdx, setDoctors, doctorsIdx } = props;

    return (
        <Container ref={ref} $width={width} id={id}>
            {id === 'doctorName' ? (
                <InputDescContainer id={id}>
                    <InputLabel $marginBottom='0'>{label}</InputLabel>
                    {desc && <InputDesc id={id}>{desc}</InputDesc>}
                </InputDescContainer>
            ) : (
                <>
                    <DoctorNameInputLabel>{label}</DoctorNameInputLabel>
                    {desc && <InputDesc>{desc}</InputDesc>}
                </>
            )}
            <InputWrapper>
                {id === 'doctorName' ? (
                    <AddDiagnosisDoctorsDropdown setDoctors={setDoctors} doctorsIdx={doctorsIdx} />
                ) : (
                    <BasicInput
                        id={id}
                        placeholder={placeholder}
                        value={value}
                        $warning={errorMsg}
                        onFocus={onFocus}
                        disabled={id === 'semester' && (schoolAgeIdx === 0 || !schoolAgeIdx)}
                        onBlur={() => onBlur && onBlur(id)}
                        onClick={() => setDropdown && setDropdown(id, undefined, '', '')}
                        readOnly
                    />
                )}
                {(id === 'schoolAge' || id === 'semester') && (
                    <InputWithLabelIconWrapper onClick={() => setDropdown && setDropdown(id, undefined, '', '')} disabled={id === 'semester' && (schoolAgeIdx === 0 || !schoolAgeIdx)}>
                        {!onDropdown ? <IconArrowDown /> : <IconArrowUp />}
                    </InputWithLabelIconWrapper>
                )}
                {(id === 'schoolAge' || id === 'semester') && onDropdown && (
                    <SchoolAgeSemesterDropdown id={id} setDropdown={setDropdown} schoolAgeIdx={schoolAgeIdx} semesterIdx={semesterIdx} $height='176px' />
                )}
            </InputWrapper>
            {errorMsg && <ErrorMsg id={id}>{errorMsg}</ErrorMsg>}
        </Container>
    );
});

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const DoctorNameInputLabel = styled(Body3_Medium)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_normal};
    &::before {
        content: '${(props) => (props.children ? '' : '\u00A0')}';
    }
`;

const InputDescContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.id === 'patientMobile' || props.id === 'doctorName' ? 'row' : 'column')};
    gap: ${(props) => (props.id === 'patientMobile' || props.id === 'doctorName' ? '8px' : '')};
`;

const InputDesc = styled(Body5Regular)`
    display: flex;
    align-items: center;
    color: ${TextColor.color_text_weak};
    font-weight: ${font.weight.regular};
`;

const Input = styled.input<InputContainerStyleProps>`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal}`)};
    padding: ${(props) => (props.padding ? props.padding : '0 20px')};
    height: 60px;
    width: ${(props) => props.width || '100%'};
    font-size: 16px;
    letter-spacing: ${(props) => (props.id === 'birthDate' || props.id === 'birthDate' ? '3px' : 0)};
    cursor: ${(props) => props.id === 'diagnosisDateTime' && 'pointer'};
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }
    &:focus {
        border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal_press}`)};
    }
    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};
    }
`;
const Textarea = styled(Body3_Medium_TextArea)<InputContainerStyleProps>`
    border-radius: 4px;
    border: ${(props) => (props.$warning ? `1px solid ${WarningColor.color_warning_normal}` : `1px solid ${BorderColor.color_border_normal}`)};
    padding: 18px 20px;
    min-height: 180px;
    height: auto;
    width: 100%;
    resize: none;
    outline: none;
    overflow: auto;
    &:focus :hover {
        background-color: ${palette.white};
    }

    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }

    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};

        &:hover {
            background-color: inherit;
        }
    }

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
`;

const Container = styled.div<InputContainerStyleProps>`
    ${(props) => (props.id === 'password' || props.id === 'confirmPassword') && 'position: relative;'};
    width: ${(props) => (props.$width ? props.$width : props.id === 'semester' || props.id === 'schoolAge' ? 'calc(50% - 5px)' : '100%')};
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: visible;
    ${(props) => (props.id === 'semester' || props.id === 'schoolAge' ? 'flex-shrink: 0;' : '')};
`;

const CalenderWrapper = styled.div`
    width: 280px;
    height: 100%;
`;
const CalenderContainer = styled.div`
    position: relative;
    display: flex;
    padding: 24px 24px 28px;
    width: 100%;
    height: 385px;
    border-radius: 4px;
    border: 1px solid ${palette.black};
    margin-top: 8px;
`;

const TimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 280px);
    height: 270px;
    margin-top: 51px;
    overflow-y: auto;
    gap: 6px;
`;

const ResetTimeButton = styled.button`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 88px;
    height: 40px;
    font-size: ${font.size[14]};
    border-radius: 4px;
    right: 41px;
    top: 24px;
    &:hover {
        background-color: ${palette.gray};
    }
`;

const ErrorMsg = styled.span<InputContainerStyleProps>`
    color: red;
    font-size: ${font.size[13]};
    font-weight: ${font.weight.regular};
    visibility: ${(props) => (props.id === 'gender' || props.id === 'semester') && 'hidden'};
    white-space: nowrap;
`;

const PatientIdSubLabel = styled(Body5Regular)`
    color: ${TextColor.color_text_weak};
`;

const LabelAndSubTextWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    gap: 4px;
`;
