import { diagnosisApi } from 'api/Tablet/diagnosisApi';
import _ from 'lodash';
import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoginStore } from 'store/useLoginDataStore';
import Question from './Question';
import Answer from './Answer';
import styled from 'styled-components';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import { AssessmentQuestionType, AssessmentResponse } from 'models/Tablet/AssessmentApiTypes';
import { assessmentApi } from 'api/Tablet/assessmentApi';
import AnswerStatusBadge from './AnswerStatusBadge';
import { font, palette } from 'styles/Global/globalStyles';
import ModalStartExamination from './ModalStartExamination';
import ArrowLineRight from 'assets/Icons/Arrow/ArrowLineRight';
import IconArrowLineRight from 'assets/Icons/Arrow/Icon_arrowLine_right';

interface SubmitBtnStyle {
    opacity: string;
}

const StartExamination = () => {
    const [clientRect, setClientRect] = useState<HTMLDivElement[] | null[]>([]);
    const { state } = useLocation();
    const [minusScoreList, setMinusScoreList] = useState<number[]>([]);
    const { assessmentData, setAssessmentData } = useAssessmentStore();
    const [assessmentIdList, setAssessmentIdList] = useState<string[]>(state.assessmentIdList);
    const [assessmentStatus, setAssessmentStatus] = useState(0);
    const [assessmentResData, setAssessmentResData] = useState<AssessmentResponse>();
    const [assessmentQuestions, setAssessmentQuestions] = useState<AssessmentQuestionType[]>([]);
    const [choiceList, setChoiceList] = useState<ChoiceListType[]>([]);
    const { patientData } = usePatientStore();
    const { loginData } = useLoginStore();
    const [questionInfo, setQuestionInfo] = useState({ prev: 0, now: 0, next: 0, end: 0 });
    const ref = useRef<null[] | HTMLDivElement[]>([]);
    const [missed, setMissed] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const getAssessmentData = async () => {
        try {
            const searchAssessmentId = assessmentIdList[assessmentStatus];
            const assessmentListRes = await diagnosisApi.getAssessmentData(searchAssessmentId, loginData.token);
            if (_.isNull(assessmentListRes) || _.isUndefined(assessmentListRes)) {
                console.log('null');
            } else {
                setAssessmentResData(assessmentListRes!.data);
                setAssessmentQuestions(assessmentListRes!.data.questions.map((question) => question));
                setQuestionInfo((prev) => ({
                    ...prev,
                    now: 1,
                    next: 0,
                    end: assessmentListRes!.data.questions.map((question) => question).length,
                }));
                setAssessmentData({
                    assessmentNameList: assessmentData.assessmentNameList,
                    assessmentGroupList: assessmentData.assessmentGroupList,
                    assessmentIdList: assessmentData.assessmentIdList,
                    assessmentCodeList: assessmentData.assessmentCodeList,
                    assessmentCnt: assessmentStatus,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const changeAssessmentStatus = async (status: number) => {
        try {
            const rqData = {
                diagnosisId: state.diagnosisId,
                assessmentCode: assessmentData.assessmentCodeList[assessmentStatus],
                status,
            };
            const res = await assessmentApi.changeAssessmentStatus(rqData, loginData.token);
        } catch (error) {}
    };

    const changeCompletedAssessmentStatus = async (status: number) => {
        try {
            const requestApi = [];
            for (let i = 0; i < assessmentData.assessmentCodeList.length; i++) {
                const rqData = {
                    diagnosisId: patientData.diagnosisId!,
                    assessmentCode: assessmentData.assessmentCodeList[i],
                    status,
                };
                requestApi.push(setTimeout(() => assessmentApi.changeAssessmentStatus(rqData, loginData.token), i * 1000));
            }
            await Promise.allSettled(requestApi).then((result) => {
                if (result.filter((item) => item.status === 'rejected').length > 0) {
                    changeCompletedAssessmentStatus(status);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const findMissingNumber = (totalNum: number, enteredNumbers: number[]) => {
        const missedQuestion: number[] = [];

        for (let i = 1; i <= totalNum; i++) {
            if (!enteredNumbers.includes(i)) {
                missedQuestion.push(i);
            }
        }
        return missedQuestion;
    };

    const moveQuestion = (questionNumber: number) => {
        if (!missed) {
            setQuestionInfo((prev) => ({
                ...prev,
                prev: questionNumber!,
                next: questionNumber! + 2,
                now: questionNumber! + 1,
            }));
            setMissed(false);
        }
    };

    const moveMissingQuestion = () => {
        setMissed(true);
    };

    const checkMissingQuestions = () => {
        if (choiceList.length === assessmentQuestions.length) {
            return true;
        }
    };

    const handleSubmit = () => {
        setShowModal(true);
    };

    const scrollIntoViewEvent = useMemo(() => {
        const headerOffset = 100;

        return () => {
            clientRect.forEach((el, index) => {
                if (!_.isNull(el) && index === questionInfo.now - 1) {
                    const elementPosition = clientRect[index]!.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth',
                    });
                }
            });
        };
    }, [questionInfo]);

    useEffect(() => {
        const clientRect = ref.current;
        setClientRect(clientRect);
    }, []);

    useEffect(() => {
        if (missed) {
            const totalCnt = assessmentQuestions.length;
            const enteredQuestionNumberList = choiceList.map((item) => item.questionNo);
            const missedQuestionList = findMissingNumber(totalCnt, enteredQuestionNumberList);
            setQuestionInfo((prev) => ({
                ...prev,
                next: missedQuestionList[1],
                now: missedQuestionList[0],
            }));
        }
    }, [choiceList, missed]);

    useEffect(() => {
        if (choiceList.length === questionInfo.end) {
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            scrollIntoViewEvent();
        }
    }, [questionInfo]);

    useEffect(() => {
        setChoiceList([]);
        setMinusScoreList([]);
        getAssessmentData();
        window.scrollTo(0, 0);
        changeAssessmentStatus(1);
    }, [assessmentStatus]);
    return (
        <Wrapper>
            {showModal && <ModalStartExamination setShowModal={setShowModal} setAssessmentStatus={setAssessmentStatus} assessmentStatus={assessmentStatus} choiceList={choiceList} />}
            {assessmentQuestions ? (
                <>
                    {assessmentQuestions.map((question, index) => (
                        <QuestionContainer key={index} className={`${index.toString()}`} ref={(containerRef) => (ref.current[index] = containerRef)}>
                            <QuestionLayout>
                                <Question question={question.question} index={index} />
                                <Answer
                                    questionInfo={questionInfo}
                                    minusScoreList={minusScoreList}
                                    setMinusScoreList={setMinusScoreList}
                                    answers={question.answers}
                                    questionNumber={question.number}
                                    assessmentCode={assessmentResData?.assessmentCode}
                                    maxNoResCount={assessmentResData?.maxNoResCount}
                                    choiceList={choiceList}
                                    setChoiceList={setChoiceList}
                                    setQuestionInfo={setQuestionInfo}
                                    moveQuestion={moveQuestion}
                                />
                            </QuestionLayout>
                        </QuestionContainer>
                    ))}
                </>
            ) : (
                <div>데이터 없음</div>
            )}
            <SubmitBtn opacity={checkMissingQuestions() ? '' : '.2'} onClick={handleSubmit}>
                {assessmentData.assessmentCodeList && assessmentData.assessmentCodeList.length - 1 !== assessmentData.assessmentCnt ? (
                    <>
                        <span>제출하고 다음 검사로</span>
                        <IconArrowLineRight />
                    </>
                ) : (
                    <span>제출하고 검사 끝내기</span>
                )}
            </SubmitBtn>
            <AnswerStatusBadge choiceListLength={choiceList.length} assessmentQuestionsLength={assessmentQuestions.length} moveMissingQuestion={moveMissingQuestion} />
        </Wrapper>
    );
};

export default StartExamination;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 68px 0px 52px;
`;

const QuestionContainer = styled.div`
    width: 660px;
    display: flex;
    column-gap: 16px;
    margin: 32px 0px;
`;

const QuestionLayout = styled.div`
    width: 640px;
    display: flex;
    flex-direction: column;
    align-items: start-item;
    row-gap: 20px;
    padding-bottom: 36px;
    border-bottom: 1px solid #dbdbdb;
`;

const IconsContainer = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;
const SubmitBtn = styled.button<SubmitBtnStyle>`
    width: 380px;
    height: 72px;
    border-radius: 4px;
    background-color: #264c97;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    color: ${palette.white};
    opacity: ${(props) => props.opacity};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
