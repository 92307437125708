import DropdownButton from 'components/Buttons/DropdownButton';
import SearchInput from 'components/Input/SearchInput';
import { diagnosisListPageText } from 'constants/diagnosisListPageConstants';
import styled from 'styled-components';

import { ResetIconWrapper } from 'styles/Input/inputStyle';
import RefreshIcon from 'assets/Icons/Common/RefreshIcon';
import { commonText } from 'constants/commonConstants';
import { AdminManageHospitalPageFilterProps } from 'models/Admin/AdminManageHospitalPageTypes';

const AdminManageHospitalPageFilter = ({ diagnosisNumber, onChange, searchValue, handleSearchFilter }: AdminManageHospitalPageFilterProps) => {
    return (
        <>
            {(diagnosisNumber || 0) + diagnosisListPageText.countDiagnosis}
            <FilterButtonWrapper>
                <DropdownButton>{commonText.hospitalName}</DropdownButton>
                <SearchInputWrapper>
                    <SearchInput onChange={onChange} searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
                </SearchInputWrapper>
                <ResetIconWrapper onClick={() => window.location.reload()}>
                    <RefreshIcon />
                </ResetIconWrapper>
            </FilterButtonWrapper>
        </>
    );
};

export default AdminManageHospitalPageFilter;

const FilterButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
`;

const SearchInputWrapper = styled.div`
    position: relative;
    width: 220px;
    height: 100%;
    border-radius: 4px;
`;
