import IconArrowDown from 'assets/Icons/Arrow/Icon_arrow_down';
import DropdownButton from 'components/Buttons/DropdownButton';
import SearchFilterDropdownNotPeriod from 'components/Dropdown/SearchFilterDropdownNotPeriod';
import SearchInput from 'components/Input/SearchInput';
import { diagnosisListPageText, manageFilterList, searchFilterList } from 'constants/diagnosisListPageConstants';
import { TodayDiagnosisFilterProps } from 'models/Hospital/diagnosisListPageTypes';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { DropdownIconWrapper, ResetIconWrapper } from 'styles/Input/inputStyle';
import RefreshIcon from 'assets/Icons/Common/RefreshIcon';
import IconArrowUp from 'assets/Icons/Arrow/Icon_arrow_up';
import { adminManageFilterList } from 'constants/Admin/AdminManageUserJoinPageConstants';
import { adminManageHospitalFilterList } from 'constants/Admin/AdminManageHospitalPageConstants';

const TodayDiagnosisFilter = ({
    diagnosisNumber,
    selectedSearchFilter,
    handleDropdown,
    isSearchFilterDropdown,
    setIsSearchFilterDropdown,
    onChange,
    searchValue,
    handleSearchFilter,
    type,
}: TodayDiagnosisFilterProps) => {
    const dropdownRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isSearchFilterDropdown) {
                setIsSearchFilterDropdown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSearchFilterDropdown]);

    const divideDropdown = (type?: string) => {
        switch (type) {
            case 'adminManageHospitalPage':
                return adminManageHospitalFilterList[selectedSearchFilter];
            case 'adminManageUserJoinPage':
                return adminManageFilterList[selectedSearchFilter];
            case 'managingPatients':
                return manageFilterList[selectedSearchFilter];
            default:
                return searchFilterList[selectedSearchFilter];
        }
    };

    return (
        <>
            {(diagnosisNumber || 0) + diagnosisListPageText.countDiagnosis}
            <FilterButtonWrapper>
                <DropdownButton onClick={setIsSearchFilterDropdown} ref={dropdownRef}>
                    {/* {type ? managingPatientsFilterList[selectedSearchFilter] : searchFilterList[selectedSearchFilter]} */}
                    {divideDropdown(type)}
                    <DropdownIconWrapper>
                        {isSearchFilterDropdown ? (
                            <>
                                <IconArrowUp />
                                <SearchFilterDropdownNotPeriod type={type} selectedSearchFilter={selectedSearchFilter} handleDropdown={handleDropdown} />
                            </>
                        ) : (
                            <IconArrowDown />
                        )}
                    </DropdownIconWrapper>
                </DropdownButton>
                <SearchInputWrapper>
                    <SearchInput onChange={onChange} searchValue={searchValue} handleSearchFilter={handleSearchFilter} />
                </SearchInputWrapper>
                <ResetIconWrapper onClick={() => window.location.reload()}>
                    <RefreshIcon />
                </ResetIconWrapper>
            </FilterButtonWrapper>
        </>
    );
};

export default TodayDiagnosisFilter;

const FilterButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
`;

const SearchInputWrapper = styled.div`
    position: relative;
    width: 220px;
    height: 100%;
    border-radius: 4px;
`;
