import { PrimaryColor } from 'styles/Global/colorSemantic';

const LnbAdminCalculateIcon = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {' '}
            <path
                id='Union'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.9194 12.564C20.9718 12.3851 21 12.1959 21 12V5C21 3.89543 20.1046 3 19 3H8C6.89543 3 6 3.89543 6 5V7H4C2.89543 7 2 7.89543 2 9V16C2 17.1046 2.89543 18 4 18H10.3414C11.1651 20.3304 13.3876 22 16 22C19.3137 22 22 19.3137 22 16C22 14.722 21.6004 13.5373 20.9194 12.564ZM19 5H8V7H15C16.1046 7 17 7.89543 17 9V10.083C17.7179 10.2034 18.3926 10.4513 19 10.8027V5ZM10 16C10 13.027 12.1623 10.559 15 10.083V9H4L4 16H10ZM20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default LnbAdminCalculateIcon;
