import { ErrMsgProps, SignUpExplainTextProps } from 'models/SignUp/signUpTypes';
import styled from 'styled-components';
import { TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { font } from 'styles/Global/globalStyles';
import { Caption1Regular } from 'styles/Global/typography';

// error message font style

export const ErrorMsg = styled(Caption1Regular)<ErrMsgProps>`
    margin-top: ${(props) => props.$marginTop || '8px'};
    color: ${WarningColor.color_warning_normal};
`;

export const SignUpExplainText = styled.span<SignUpExplainTextProps>`
    color: ${(props) => props.color || TextColor.color_text_normal};
    font-weight: ${(props) => props.fontWeight || font.weight.medium};
    font-size: ${font.size[18]};
`;
