import React from 'react';

const IconWarningFill = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z' fill='#E7000E' />
            <path
                d='M11.3678 13.2673L11.0606 8.19818C11.0278 7.65696 11.4579 7.20001 12.0001 7.20001C12.5423 7.20001 12.9724 7.65696 12.9396 8.19818L12.6324 13.2673C12.6142 13.5666 12.3663 13.8 12.0665 13.8H11.9337C11.6339 13.8 11.386 13.5666 11.3678 13.2673Z'
                fill='white'
            />
            <path d='M12 16.8C12.5523 16.8 13 16.3523 13 15.8C13 15.2477 12.5523 14.8 12 14.8C11.4477 14.8 11 15.2477 11 15.8C11 16.3523 11.4477 16.8 12 16.8Z' fill='white' />
        </svg>
    );
};

export default IconWarningFill;
