import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { PageTitle } from 'styles/Global/commonStyles';
import { font } from 'styles/Global/globalStyles';
import { useLoginStore } from 'store/useLoginDataStore';
import { HospitalPageLabel, DiagnosisInnerLayout, DiagnosisLayout } from 'styles/Global/hospitalStyles';
import { calculatePageText } from 'constants/calculatePageConstants';
import { calculatePageApi } from 'api/Admin/settlementPageApi';
import { GetMonthlySettlementResponse, GetPaymentListApiResponse, GetQuarterSettlementResponse } from 'models/Admin/calculatePageApiTypes';
import SizedBox from 'components/Common/SizedBox';
import { ManagerBodyLayout } from 'styles/Global/SystemManager/calculationStyles';
import { Tabs } from 'antd';
import QuarterTabPage from './QuarterTabPage';
import IndividualTabPage from './IndividualTabPage';
import MonthlyTabPage from './MonthlyTabPage';
import { AdminInnerLayout } from 'styles/Global/Admin/adminCommonStyles';
import { usePageIndexDataStore } from 'store/usePaginationStore';
const TabPane = Tabs.TabPane;

const AdminCalculatePage = () => {
    const { loginData } = useLoginStore();
    const now = new Date();
    const [selectedTabIndex, setSelectedTabIndex] = useState('0');
    const [selectedPeriod, setSelectedPeriod] = useState('1개월');
    const [selectedMonth, setSelectedMonth] = useState('1월');
    const [selectedKeyword, setSelectedKeyword] = useState('병원명');
    const [searchDateList, setSearchDateList] = useState<string[]>([]);
    const [searchWord, setSearchWord] = useState('');
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [individualPayment, setIndividualPayment] = useState<GetPaymentListApiResponse['data']>();
    const [quarterPaymentData, setQuarterPaymentData] = useState<GetQuarterSettlementResponse>();
    const [monthlyPaymentData, setMonthlyPaymentData] = useState<GetMonthlySettlementResponse>();
    const { currentPage, handlePageIndex } = usePageIndexDataStore();

    const onTabClick = useCallback((index: string) => {
        setSelectedTabIndex(index);
        handlePageIndex(1);
    }, []);

    const setPageSection = usePageIndexDataStore((state) => state.handlePageSection);

    useEffect(() => {
        const thisYear = now.getFullYear();
        const thisMonth = now.getMonth();
        const today = now.getDate();

        const parseToString = (date: number) => String(date).padStart(2, '0');
        setSearchDateList([`20240101`, `${thisYear}${parseToString(thisMonth + 1)}${parseToString(today)}`]);

        // switch (selectedPeriod) {
        //     case '1년':
        //         console.log('@!!-->> 1년');
        //         setSearchDateList([`${thisYear - 1}${parseToString(thisMonth)}${parseToString(today)}`, `${thisYear}${parseToString(thisMonth)}${parseToString(today)}`]);
        //         break;
        //     case '1주':
        //         console.log('@!!-->> 1주');
        //         setSearchDateList([`${thisYear}${parseToString(thisMonth)}${today - 1}`, `${thisYear}${parseToString(thisMonth)}${parseToString(today)}`]);
        //         break;
        //     case '오늘':
        //         console.log('@!!-->> 오늘');
        //         setSearchDateList([`${thisYear}${parseToString(thisMonth)}${parseToString(today)}`, `${thisYear}${parseToString(thisMonth)}${parseToString(today)}`]);
        //         break;
        //     case '1개월':
        //         console.log('@!!-->> 1개월');
        //         setSearchDateList([`${thisYear}${parseToString(9)}${parseToString(today)}`, `${thisYear}${parseToString(thisMonth)}${parseToString(today)}`]);
        //         break;
        //     case '직접입력':
        //         console.log('@!!-->> 직접입력');
        //         break;
        //     default:
        //         setSearchDateList([`20240101`, `${thisYear}${parseToString(thisMonth)}${parseToString(today)}`]);
        //         break;
        // }
    }, [selectedPeriod]);

    const getSettlementData = useCallback(async () => {
        switch (selectedTabIndex) {
            case '1':
                await calculatePageApi
                    .getMonthlyPaymentList({ token: loginData.token, searchKeyword: searchWord })
                    .then((res) => {
                        setMonthlyPaymentData(res);
                    })
                    .catch((err) => console.log(err));
                break;
            case '2':
                await calculatePageApi
                    .getPaymentList({ token: loginData.token, dateList: ['20240101', _.last(searchDateList) ?? ''], page: currentPage })
                    .then((res) => {
                        setIndividualPayment(res.data);
                    })
                    .catch((err) => console.log(err));
                break;
            default:
                await calculatePageApi
                    .getQuarterPaymentList({ token: loginData.token, searchKeyword: searchWord })
                    .then((res) => {
                        console.log(res.data.quarterSummary);

                        setQuarterPaymentData(res);
                    })
                    .catch((err) => console.log(err));
                break;
        }
        setIsSearchClicked(false);
    }, [selectedTabIndex, isSearchClicked, currentPage]);

    useEffect(() => {
        getSettlementData();
    }, [getSettlementData]);

    return (
        <DiagnosisLayout>
            <AdminInnerLayout>
                <PageTitleContainer>
                    <HospitalPageLabel onClick={() => window.location.reload()}>{calculatePageText.pageLabel}</HospitalPageLabel>
                    <PageTitle>{calculatePageText.pageTitle}</PageTitle>
                </PageTitleContainer>
                <SizedBox height={32} />
                <ManagerBodyLayout>
                    <Tabs
                        defaultActiveKey={'0'}
                        tabBarStyle={{ fontWeight: font.weight.semiBold }}
                        onTabClick={async (key, e) => {
                            onTabClick(key);
                            setPageSection(1);
                        }}
                    >
                        <TabPane
                            tab={'분기별 정산'}
                            key={0}
                            style={{ paddingBottom: 14 }}
                            children={<QuarterTabPage isShowCarosel={true} settlementData={quarterPaymentData} onChangeSearchWord={setSearchWord} onClickedSearch={setIsSearchClicked} />}
                        />
                        <TabPane
                            tab={'월별 정산'}
                            key={1}
                            style={{ paddingBottom: 14 }}
                            children={<MonthlyTabPage isShowCarosel={true} settlementData={monthlyPaymentData} onChangeSearchWord={setSearchWord} onClickedSearch={setIsSearchClicked} />}
                        />
                        <TabPane
                            tab={'개별 정산'}
                            key={2}
                            style={{ paddingBottom: 14 }}
                            children={
                                <IndividualTabPage
                                    isShowCarosel={false}
                                    calData={individualPayment}
                                    onChangePeriod={setSelectedPeriod}
                                    onChangeMonth={setSelectedMonth}
                                    onChangeSearchDateList={setSearchDateList}
                                    onChangeSearchWord={setSearchWord}
                                    onClickedSearch={setIsSearchClicked}
                                />
                            }
                        />
                    </Tabs>
                    <TableTopContainer>
                        <SearchContainer></SearchContainer>
                    </TableTopContainer>
                </ManagerBodyLayout>
            </AdminInnerLayout>
        </DiagnosisLayout>
    );
};

export default AdminCalculatePage;

const PageTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`;

const MetricCardContainer = styled.div<{ isShow?: boolean }>`
    display: ${(props) => (props.isShow ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0px;
    width: 100%;
`;

const TableTopContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
