type Props = {
    width?: number;
    height?: number;
};

const SizedBox = (props: Props) => {
    return <div style={{ width: props.width, height: props.height }} />;
};

export default SizedBox;
