import { preciousAuthApi } from 'api/PreciousChild/preciousAuthApi';
import { governmentCodeConfirmPageConstants } from 'constants/Government/governmentCodeConfirmPageConstants';
import { preciousChildPaths, governmentPaths } from 'constants/path';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function VerificationPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [codeErr, setCodeErr] = useState('');

    useEffect(() => {
        const getPurchaseCode = () => {
            const pathName = _.split(location.pathname, '/');

            return _.last(pathName);
        };

        const setNavigation = async () => {
            const response = await preciousAuthApi.verifyPurchaseCode({ code: getPurchaseCode() ?? '' });

            if (response.status === 'ok') {
                if (response.data.voucher!.status !== 0) {
                    setCodeErr(governmentCodeConfirmPageConstants.apiErrText);
                } else {
                    navigate(preciousChildPaths.testerInfo, {
                        replace: true,
                        state: response.data,
                    });
                }
            } else {
                //@!!
                // if (response.error) {
                //     if (response.error!.code === 1002) {
                //         setCodeErr(governmentCodeConfirmPageConstants.apiUsedErrText);
                //         return;
                //     }
                //     setCodeErr(governmentCodeConfirmPageConstants.apiErrText);
                // }
            }
        };

        setNavigation();
    }, []);

    return <>{location.pathname}</>;
}
