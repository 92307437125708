import { commonText } from 'constants/commonConstants';

export const adminManageFilterList = [commonText.hospitalName, commonText.email, commonText.phoneNumber];

export namespace AdminManageUserConstants {
    export const pageCommonConstants = {
        title: '가입 요청 관리',
        accept: '승인',
        reject: '반려',
        completeAccept: '승인 완료',
        completeReject: '반려 처리',
    };

    export const paginationHeaderConstants = {
        numbering: 'No.',
        hospitalName: commonText.hospitalName,
        email: '가입 아이디',
        userName: commonText.name,
        phoneNumber: commonText.phoneNumber,
        registrationTimestamp: '가입 요청일',
        status: '승인 상태',
    };
}

export const userManageModalConstants = {
    acceptButtonText: '승인하기',
    rejectButtonText: '반려하기',
    acceptModalTitle: '가입 요청을 승인할까요?',
    rejectModalTitle: '가입 요청을 반려할까요?',
    acceptDescription: '승인하면, 해당 아이디로 서비스 이용이 가능해집니다.',
    rejectFirstDescription: '반려하면, 서비스 회원가입 처리가 이루어지지 않습니다.',
    rejectSecondDescription: '사용자는 재가입하고 승인되면 서비스 이용이 가능해집니다.',
};
