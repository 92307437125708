import { adminManageUserJoinPageApi } from 'api/Admin/adminManageUserJoinPageApi';
import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import useToggle from 'hooks/CustomHooks/useToggle';
import AdminManageUserJoinPageFilter from 'pages/Hospital/TodayDiagnosisPage/TodayDiagnosisFilter';
import React, { useEffect, useState } from 'react';
import { useLoginStore } from 'store/useLoginDataStore';
import { BorderColor } from 'styles/Global/colorSemantic';
import { PageTitle, RowDivider } from 'styles/Global/commonStyles';
import { DiagnosisLayout, FilterWrapper, HospitalPageLabel } from 'styles/Global/hospitalStyles';
import AdminManageUserJoinPagination from './AdminManageUserJoinPagination';
import { useLoadingStore } from 'store/useLoadingStore';
import { userApi } from 'api/Hospital/UserApi';
import UserManageModal from 'components/Admin/Modal/UserManageModal';
import { AdminManageUserConstants } from 'constants/Admin/AdminManageUserJoinPageConstants';
import { GetUserListApiListType } from 'models/Admin/AdminManageUserJoinPageTypes';
import { AdminInnerLayout } from 'styles/Global/Admin/adminCommonStyles';

const AdminManageUserJoinPage = () => {
    const { loginData } = useLoginStore();
    const { setLoading } = useLoadingStore();

    const [selectedSearchFilter, setselectedSearchFilter] = useState<number>(0);
    const [userData, setUserData] = useState<GetUserListApiListType[]>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedUserData, setSelectedUserData] = useState<GetUserListApiListType | null>(null);

    const [isSearchFilterDropdown, setIsSearchFilterDropdown] = useToggle();
    const [isRejectModal, setIsRejectModal] = useToggle();
    const [isAcceptModal, setIsAcceptModal] = useToggle();

    const { setPage, currentPage, pageGroup, handlePageGroup } = usePaginationStateHooks(userData);

    useEffect(() => {
        adminManageUserJoinPageApiCall();
    }, [currentPage]);

    // search dropdown function
    const handleDropdown = (e: React.MouseEvent, idx: number) => {
        e.stopPropagation();
        setIsSearchFilterDropdown();
        setselectedSearchFilter(idx);
        setSearchValue('');
        setPage(1);
    };

    // user state update func
    const handleUpdateUserState = async (userId: string, status: number, type: string) => {
        setLoading(true);
        try {
            const rqData = { _id: userId, status: status };
            const response = await userApi.userChangeStatus(rqData, loginData.token);

            if (response.status === 'ok') {
                await adminManageUserJoinPageApiCall();
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }

        handleModalFunc(type);
        setLoading(false);
    };

    // user list api call
    const adminManageUserJoinPageApiCall = async () => {
        setLoading(true);

        try {
            const response = await adminManageUserJoinPageApi.getUserList(currentPage, selectedSearchFilter + 1, searchValue, loginData.token);

            if (response.data) {
                setUserData(response.data.list);
                setTotalPages(response.data.pagination.totalPages);
                setTotalCount(response.data.pagination.totalCount);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    // reset Button function
    const handleResetButton = async () => {
        const response = await adminManageUserJoinPageApi.getUserList(currentPage, null, null, loginData.token);

        setSearchValue('');
        setUserData(response.data.list);
        setPage(1);
        handlePageGroup(0);
    };

    // 반려 / 승인 버튼 클릭 func
    const clickUserManageButton = (userData: GetUserListApiListType, type: string) => {
        setSelectedUserData(userData);
        handleModalFunc(type);
    };

    // Modal toggle func
    const handleModalFunc = (type: string) => {
        if (type === 'accept') {
            setIsAcceptModal();
        } else {
            setIsRejectModal();
        }
    };

    return (
        <DiagnosisLayout>
            {isAcceptModal && <UserManageModal toggle={setIsAcceptModal} selectedUserData={selectedUserData} type='accept' onClick={handleUpdateUserState} />}
            {isRejectModal && <UserManageModal toggle={setIsRejectModal} selectedUserData={selectedUserData} type='reject' onClick={handleUpdateUserState} />}
            <AdminInnerLayout>
                <HospitalPageLabel onClick={() => window.location.reload()}>{AdminManageUserConstants.pageCommonConstants.title}</HospitalPageLabel>
                <PageTitle $marginbottom='24px'>{AdminManageUserConstants.pageCommonConstants.title}</PageTitle>
                <RowDivider $backgroundcolor={BorderColor.color_border_neutral} $marginbottom='16px' />
                <FilterWrapper>
                    <AdminManageUserJoinPageFilter
                        type='adminManageUserJoinPage'
                        diagnosisNumber={totalCount}
                        selectedSearchFilter={selectedSearchFilter}
                        handleDropdown={handleDropdown}
                        isSearchFilterDropdown={isSearchFilterDropdown}
                        setIsSearchFilterDropdown={setIsSearchFilterDropdown}
                        onChange={setSearchValue}
                        searchValue={searchValue}
                        handleSearchFilter={adminManageUserJoinPageApiCall}
                    />
                </FilterWrapper>
                <AdminManageUserJoinPagination
                    initNumber={userData?.length}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageGroup={pageGroup}
                    handlePageGroup={handlePageGroup}
                    handlePageChange={setPage}
                    currentData={userData}
                    totalCount={totalCount}
                    clickUserManageButton={clickUserManageButton}
                    handleResetButton={handleResetButton}
                />
            </AdminInnerLayout>
        </DiagnosisLayout>
    );
};

export default AdminManageUserJoinPage;
