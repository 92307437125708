import _, { replace } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { ChoiceListType } from 'models/Tablet/DiagnosisApiTypes';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import InspectionComponent from './Components/InspectionComponent';
import ArrowLineRight from 'assets/Icons/Arrow/ArrowLineRight';
import InspectionHeaderTitle from './Components/InspectionHeaderTitle';
import { AssessmentListType, VoucherStatusParam } from 'models/Tablet/AssessmentApiTypes';
import { governmentPaths } from 'constants/path';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import useToggle from 'hooks/CustomHooks/useToggle';
import { diagnosisApi } from 'api/Tablet/diagnosisApi';
import { getInpsytResultType } from 'models/Tablet/DiagnosisApiTypes';
import { assessmentApi } from 'api/Tablet/assessmentApi';
import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import { codeConfirmPageApi } from 'api/Government/codeConfirmPageApi';

const PreciousAssessmentScript = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    console.log('@!!--> state::', state);
    const questionList = state.data;
    const assessmentInfo = state.info;
    const diagnosisInfo = state.diagnosisInfo;

    const { setHeaderText, setIsVisibleProgressbar, setProgressPercent, setInit } = useGovernmentHeaderStore();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [currentTestIndex, setCurrentTestIndex] = useState(0);
    const [selectedOverNoRes, setSelectedOverNoRes] = useToggle();
    const [isLeaveModal, setIsLeaveModal] = useToggle();
    const [isCompleteModal, setIsCompleteModal] = useToggle();
    const [isErrorModal, setIsErrorModal] = useToggle();
    const [noResponseList, setNoResponseList] = useState<ChoiceListType[]>([]);
    const [choiceList, setChoiceList] = useState<ChoiceListType[]>([]);
    const [choicedAssessmentList, setChoicedAssessmentList] = useState<AssessmentListType[]>([]);

    const [questionInfo, setQuestionInfo] = useState({
        prev: 0,
        now: 0,
        next: 0,
        end: 0,
    });

    useEffect(() => {
        setInit();
        window.scrollTo(0, 0);
        setChoiceList([]);
        setHeaderText(<InspectionHeaderTitle index={currentTestIndex + 1} currentQuestion={questionList[currentTestIndex]} />);
        setIsVisibleProgressbar(true);
    }, [currentTestIndex]);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        if (currentLocation.pathname === governmentPaths.userInspection && nextLocation.pathname !== governmentPaths.complete && !isLeaveModal) {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    useEffect(() => {
        const isComplete = choiceList.length === questionList[currentTestIndex].questionCount;
        const percent = _.isEmpty(choiceList) ? 0 : (choiceList.length / questionList[currentTestIndex].questionCount) * 100;
        setButtonDisabled(!isComplete);
        setProgressPercent(percent);
    }, [choiceList, currentTestIndex]);

    const onEvent = async () => {
        let test = [];
        const addQuestionNumList = _.map(choiceList, (item) => ({
            ...item,
            questionNo: item.questionNo + 1,
        }));

        const assessment: AssessmentListType = {
            assessmentCode: questionList[currentTestIndex].assessmentCode,
            choiceList: addQuestionNumList,
        };

        setChoicedAssessmentList([...choicedAssessmentList, assessment]);
        test = [...choicedAssessmentList, assessment];

        if (currentTestIndex < questionList.length - 1) {
            setIsCompleteModal();
            setCurrentTestIndex(currentTestIndex + 1);
        } else if (currentTestIndex === questionList.length - 1) {
            await sendChoiceAssessment(test);
            navigate(governmentPaths.complete);
        }
    };

    const checkIsAvailable = async () => {
        const voucherCode = {
            code: assessmentInfo.voucher.code,

            status: assessmentInfo.voucher.status,
        };

        const response = await codeConfirmPageApi.verifyVoucherCode(voucherCode);

        if (response.status === 'fail' && response.error) {
            setIsErrorModal();
            return;
        }

        setIsCompleteModal();
    };

    const completeDiagnosisApiCall = async () => {
        const rqData = {
            _id: diagnosisInfo._id,
            status: 3,
        };

        const response = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, assessmentInfo.token);

        if (response.status === 'ok') {
            const voucherParam: VoucherStatusParam = {
                code: assessmentInfo.voucher.code,
                status: 1,
            };

            await assessmentApi.chageVoucherStatus(voucherParam, assessmentInfo.token);
        } else {
            console.log('stats 변경 실패');
        }
    };

    const clickLeaveButton = () => {
        setInit();
        setIsLeaveModal();
        navigate(governmentPaths.agree, { replace: true });
    };

    const sendChoiceAssessment = async (test: AssessmentListType[]) => {
        console.log(diagnosisInfo._id!);
        const choiceAccessmentParam: getInpsytResultType = {
            patientId: diagnosisInfo.patientId,
            diagnosisId: diagnosisInfo._id!,
            assessmentList: test,
        };

        const assessmentRes = await diagnosisApi.getInpsytResult(choiceAccessmentParam, assessmentInfo.token);

        if (assessmentRes.status === 'ok') {
            await completeDiagnosisApiCall();
        } else {
            console.log('error', assessmentRes.status);
        }
    };

    return (
        <>
            {isLeaveModal && (
                <MobileLeaveModal
                    title='모든 데이터를 삭제하고'
                    subTitle='지금 나갈까요?'
                    content='지금 나가면, 첫 단계인 개인정보 동의부터 다시 시작해야합니다. 그래도 나갈까요?'
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton='아니요'
                    secondButton='나가기'
                    firstButtonType='anotherFill'
                />
            )}
            {selectedOverNoRes && (
                <MobileLeaveModal
                    title='무응답 선택은'
                    subTitle={`최대 ${questionList[currentTestIndex].maxNoResCount}개만 가능합니다.`}
                    content={'무응답을 선택한 다른 문항을 수정하거나, 현재 문항에서 다른 답변을 선택해주세요.'}
                    setIsLeaveModal={() => setSelectedOverNoRes()}
                    firstButton='확인'
                    firstButtonType='confirm'
                />
            )}
            {isCompleteModal && (
                <MobileLeaveModal
                    title={`${currentTestIndex < questionList.length - 1 ? '검사를 완료하고' : '검사를 완료하고 끝낼까요?'}`}
                    subTitle={`${currentTestIndex < questionList.length - 1 ? '다음 검사를 진행할까요?' : ''}`}
                    content={`${
                        currentTestIndex < questionList.length - 1
                            ? '다음 검사를 시작하면, 현재의 검사 답변을 수정하거나 되돌아올 수 없습니다.'
                            : '검사를 끝내면, 현재의 검사 답변을 수정하거나 되돌아올 수 없습니다.'
                    }`}
                    setIsLeaveModal={setIsCompleteModal}
                    clickLeaveButtonInLeaveModal={onEvent}
                    firstButton='아니요'
                    secondButton={`${currentTestIndex < questionList.length - 1 ? '다음 검사로' : '끝내기'}`}
                    firstButtonType='anotherFill'
                    secondButtonType='confirm'
                />
            )}
            {isErrorModal && (
                <MobileLeaveModal
                    title={'이미 완료된 검사로'}
                    subTitle={'끝낼 수 없습니다.'}
                    content={'입력한 검사코드로 이미 검사를 완료하여,\n검사를 중복 제출할 수 없습니다. 첫 단계인 약관동의로 돌아갑니다.'}
                    setIsLeaveModal={setIsErrorModal}
                    firstButton='나가기'
                    firstButtonType='warning'
                />
            )}
            <InspectionComponent
                noResponseList={noResponseList}
                questionInfo={questionInfo}
                currentQuestion={questionList[currentTestIndex]}
                choiceList={choiceList}
                setNoResponseList={setNoResponseList}
                setChoiceList={setChoiceList}
                setQuestionInfo={setQuestionInfo}
                setSelectedOverNoRes={setSelectedOverNoRes}
            />
            <MobileBasicButton
                $type='fill'
                $gap='4px'
                onClick={
                    // () => {}
                    currentTestIndex < questionList.length - 1 ? setIsCompleteModal : async () => await checkIsAvailable()
                }
                disabled={buttonDisabled}
            >
                {currentTestIndex < questionList.length - 1 ? (
                    <>
                        완료하고 다음 검사로
                        <ArrowLineRight />
                    </>
                ) : (
                    '검사 끝내기'
                )}
            </MobileBasicButton>
        </>
    );
};

export default PreciousAssessmentScript;
