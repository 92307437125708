import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { RoundBadgeXS } from 'components/Common/Badge';
import { HomeCellButton, HomeCellTitle, HomeCellTitleContainer } from 'components/Hospital/HomeForms';
import { pagePaths } from 'constants/path';
import { calculateAge } from 'functions/calculateAge';
import { changeMobileForm } from 'functions/changeMobileForm';
import { ManagingPatientsPaginationTableProps as CurrentPatientListProps } from 'models/Hospital/managingPatientsPageTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { HomeNothingDataContainer, TestCircle24 } from 'styles/Global/hospitalStyles';
import { Body4_SemiBold, Body5Regular } from 'styles/Global/typography';

type Props = {
    type?: string;
};

const CurrentPatientList = ({ data }: CurrentPatientListProps) => {
    const navigate = useNavigate();

    return (
        <>
            <HomeCellTitleContainer>
                <HomeCellTitle>최근 등록 환자</HomeCellTitle>
                <HomeCellButton onClick={() => navigate(pagePaths.managingPatients)}>
                    환자관리 <ArrowRightSlim />
                </HomeCellButton>
            </HomeCellTitleContainer>
            <PatientListContainer>
                {data.map((item, idx) => {
                    const { birthDate } = calculateAge(item.identityNumber);
                    return (
                        <PatientListWrapper key={idx}>
                            <PatientInfoWrapper>
                                <RoundBadgeXS>{item.regNum}</RoundBadgeXS>
                            </PatientInfoWrapper>
                            <PatientInfoWrapper>
                                <NameText>{item.name}</NameText>
                                <Text>{item.gender === 'male' ? <GenderMan $width='16px' $height='16px' /> : <GenderWoman $width='16px' $height='16px' />}</Text>
                                <Text>({birthDate})</Text>
                            </PatientInfoWrapper>
                            <PatientInfoWrapper>
                                <MobileText>{changeMobileForm(item.mobile)}</MobileText>
                            </PatientInfoWrapper>
                        </PatientListWrapper>
                    );
                })}
                {!data && (
                    <HomeNothingDataContainer>
                        <TestCircle24 />
                        등록된 환자가 없습니다.
                    </HomeNothingDataContainer>
                )}
            </PatientListContainer>
        </>
    );
};

export default CurrentPatientList;

const PatientListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 81px;
    gap: 4px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${BorderColor.color_border_weak};
`;

const PatientInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;

const Text = styled(Body5Regular)`
    display: flex;
    align-items: center;
    color: ${TextColor.color_text_normal};
`;

const NameText = styled(Body4_SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const MobileText = styled(Body5Regular)`
    color: ${TextColor.color_text_light};
`;

const PatientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 310px;
    gap: 12px;
    overflow-y: auto;
`;
