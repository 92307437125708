import React from 'react';

const ToolTipDisabledIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='2' y='2' width='20' height='20' rx='10' fill='#FEECEC' />
            <path
                d='M9.06564 7.93433C8.75322 7.62191 8.24669 7.62191 7.93427 7.93433C7.62185 8.24675 7.62185 8.75328 7.93427 9.0657L10.8686 12.0001L7.93436 14.9343C7.62194 15.2467 7.62194 15.7533 7.93436 16.0657C8.24678 16.3781 8.75331 16.3781 9.06573 16.0657L12 13.1314L14.9343 16.0657C15.2467 16.3781 15.7532 16.3781 16.0656 16.0657C16.3781 15.7533 16.3781 15.2467 16.0656 14.9343L13.1314 12.0001L16.0657 9.0657C16.3782 8.75328 16.3782 8.24675 16.0657 7.93433C15.7533 7.62191 15.2468 7.62191 14.9344 7.93433L12 10.8687L9.06564 7.93433Z'
                fill='#E7000E'
            />
        </svg>
    );
};

export default ToolTipDisabledIcon;
