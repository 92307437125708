import React from 'react';

const IconArrowRight = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.8637 18.6363C9.21517 18.9878 9.78502 18.9878 10.1365 18.6363L16.1365 12.6363C16.488 12.2848 16.488 11.715 16.1365 11.3635L10.1365 5.36351C9.78502 5.01203 9.21517 5.01203 8.8637 5.36351C8.51223 5.71498 8.51223 6.28483 8.8637 6.6363L14.2273 11.9999L8.8637 17.3635C8.51223 17.715 8.51223 18.2848 8.8637 18.6363Z'
                fill='#C2C4C8'
            />
        </svg>
    );
};

export default IconArrowRight;
