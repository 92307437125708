import IconArrowDown from 'assets/Icons/Arrow/Icon_arrow_down';
import IconArrowUp from 'assets/Icons/Arrow/Icon_arrow_up';
import BasicButton from 'components/Buttons/BasicButton';
import { UnSelectInputDropdown } from 'components/Dropdown/MobileDropdown';
import SchoolAgeSemesterDropdown from 'components/Dropdown/SchoolAgeSemesterDropdown';
import { BasicInputProps, InputContainerStyleProps, InputProps, LabelWithButtonProps } from 'models/Common/inputTypes';
import { forwardRef } from 'react';
import styled from 'styled-components';
import { TextColor, WarningColor } from 'styles/Global/colorSemantic';
import { Body5Medium, Caption1Regular } from 'styles/Global/typography';
import {
    BasicInput,
    BasicInputLayout,
    InputDesc,
    InputLabel,
    InputSubLabel,
    InputWithLabelIconWrapper,
    InputWrapper,
    MobileBasicInput,
    MobileInputLabel,
    MobileInputSubLabel,
    WithOutBasicInputLayout,
} from 'styles/Input/inputStyle';
import { ErrorMsg } from 'styles/SignUp/signUpStyles';

export const BasicInputWithLabel = forwardRef<HTMLInputElement, BasicInputProps>((props, ref) => {
    const { desc, label, placeholder, onChange, id, disabled, focus, errorMsg, value, onFocus, enterKey, onBlur, onBlurValue } = props;

    return (
        <BasicInputLayout>
            <InputWrapper>
                <InputLabel $marginBottom='0'>{label}</InputLabel>
                {desc && <InputSubLabel $marginBottom='0'>{desc}</InputSubLabel>}
            </InputWrapper>
            <BasicInput
                ref={ref}
                id={id}
                disabled={disabled}
                onChange={(e) => onChange && onChange(e, id === 'mobile' && isNaN(Number(e.target.value)) ? 'error' : id)}
                placeholder={placeholder}
                autoFocus={focus ?? false}
                value={value}
                $warning={errorMsg}
                onFocus={onFocus}
                onBlur={() => {
                    (onBlur && onBlur()) || (onBlurValue && onBlurValue(id));
                }}
                onKeyUp={enterKey}
                maxLength={id === 'mobile' || id === 'patientMobile' ? 11 : undefined}
            />
            {errorMsg && <ErrorMsg $marginTop='0'>{errorMsg}</ErrorMsg>}
        </BasicInputLayout>
    );
});

export const WithOutSubLabelInputWithLabel = forwardRef<HTMLInputElement, BasicInputProps>((props, ref) => {
    const { desc, label, placeholder, onChange, id, disabled, focus, errorMsg, value, onFocus, enterKey, onBlur } = props;

    return (
        <WithOutBasicInputLayout>
            <InputLabel $marginBottom='0'>{label}</InputLabel>
            <BasicInput
                ref={ref}
                id={id}
                disabled={disabled}
                onChange={(e) => onChange && onChange(e, id === 'mobile' && isNaN(Number(e.target.value)) ? 'error' : id)}
                placeholder={placeholder}
                autoFocus={focus ?? false}
                value={value}
                $warning={errorMsg}
                onFocus={onFocus}
                onBlur={() => {
                    onBlur && onBlur();
                }}
                onKeyUp={enterKey}
            />
            {errorMsg && <ErrorMsg $marginTop='0'>{errorMsg}</ErrorMsg>}
        </WithOutBasicInputLayout>
    );
});

export const LabelWithButton = ({ desc, label, id, onClick, buttonText, $type, buttonType }: LabelWithButtonProps) => {
    return (
        <BasicInputLayout>
            <InputLabel>{label}</InputLabel>
            {desc && <InputSubLabel>{desc}</InputSubLabel>}
            <BasicButton onClick={onClick} $type={$type} buttonType={buttonType}>
                {buttonText}
            </BasicButton>
        </BasicInputLayout>
    );
};

export const MobileBasicInputWithLabel = forwardRef<HTMLInputElement, BasicInputProps>((props, ref) => {
    const { desc, label, placeholder, onChange, id, disabled, focus, errorMsg, value, onFocus, enterKey, onBlur, onBlurValue, onKeyDown } = props;

    return (
        <BasicInputLayout>
            <InputWrapper>
                <MobileInputLabel $marginBottom='0'>{label}</MobileInputLabel>
                {desc && <MobileInputSubLabel $marginBottom='0'>{desc}</MobileInputSubLabel>}
            </InputWrapper>
            <MobileBasicInput
                ref={ref}
                id={id}
                disabled={disabled}
                onChange={(e) => onChange && onChange(e, id === 'patientMobile' && isNaN(Number(e.target.value)) ? 'error' : id)}
                placeholder={placeholder}
                autoFocus={focus ?? false}
                value={value}
                $warning={errorMsg}
                onFocus={onFocus}
                onBlur={() => {
                    (onBlur && onBlur()) || (onBlurValue && onBlurValue(id));
                }}
                onKeyUp={enterKey}
                onKeyDown={(e) => onKeyDown && onKeyDown(e, id)}
                maxLength={id === 'mobile' || id === 'patientMobile' ? 11 : undefined}
            />
            {errorMsg && <ErrorMsg $marginTop='0'>{errorMsg}</ErrorMsg>}
        </BasicInputLayout>
    );
});

// 모바일 학령 / 학기 input
export const MobileSemesterInputWithLabel = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { desc, label, placeholder, id, width, errorMsg, value, onFocus, onDropdown, schoolAgeIdx, onBlur, setDropdown, semesterIdx } = props;

    return (
        <Container $width={width} id={id}>
            <SemesterInputLabel>{label}</SemesterInputLabel>
            {desc && <InputDesc>{desc}</InputDesc>}

            <MobileInputWrapper>
                <MobileBasicInput
                    ref={ref}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    $warning={id === 'schoolAge' && !schoolAgeIdx ? errorMsg : id === 'semester' && semesterIdx !== 0 && !semesterIdx ? errorMsg : ''}
                    onFocus={onFocus}
                    disabled={id === 'semester' && (schoolAgeIdx === 0 || !schoolAgeIdx)}
                    onBlur={() => onBlur && onBlur(id)}
                    onClick={() => setDropdown && setDropdown(id, undefined, '', '')}
                    readOnly
                />
                {(id === 'schoolAge' || id === 'semester') && (
                    <InputWithLabelIconWrapper onClick={() => setDropdown && setDropdown(id, undefined, '', '')} $top='16px' disabled={id === 'semester' && (schoolAgeIdx === 0 || !schoolAgeIdx)}>
                        {!onDropdown ? <IconArrowDown /> : <IconArrowUp />}
                    </InputWithLabelIconWrapper>
                )}
                {(id === 'schoolAge' || id === 'semester') && onDropdown && (
                    <SchoolAgeSemesterDropdown id={id} setDropdown={setDropdown} schoolAgeIdx={schoolAgeIdx} semesterIdx={semesterIdx} $height='176px' $type='mobile' $top='56px' />
                )}
            </MobileInputWrapper>
            {errorMsg && <SemesterErrorMsg id={id}>{errorMsg}</SemesterErrorMsg>}
        </Container>
    );
});

const Container = styled.div<InputContainerStyleProps>`
    width: ${(props) => (props.$width ? props.$width : props.id === 'semester' || props.id === 'schoolAge' ? 'calc(50% - 5px)' : '100%')};
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: visible;
    ${(props) => (props.id === 'semester' || props.id === 'schoolAge' ? 'flex-shrink: 0;' : '')};
`;

const MobileInputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const SemesterInputLabel = styled(Body5Medium)<{ $marginBottom?: string }>`
    color: ${TextColor.color_text_neutral};
    &::before {
        content: '${(props) => (props.children ? '' : '\u00A0')}';
    }
`;

const SemesterErrorMsg = styled(Caption1Regular)<InputContainerStyleProps>`
    color: ${WarningColor.color_warning_normal};
    visibility: ${(props) => (props.id === 'gender' || props.id === 'semester') && 'hidden'};
    white-space: nowrap;
`;
