import React from 'react';

import { Table } from 'antd';
import _ from 'lodash';
import { styled } from 'styled-components';
import { useState } from 'react';
import { BackgroundColor } from 'styles/Global/colorSemantic';
import { Body5Regular } from 'styles/Global/typography';
import { SettlementTableProps } from 'models/Admin/calculatePageApiTypes';
import TPagination from 'components/Pagination/TPagination';

const AntdTable = <T,>({ initNumber, currentData, mergedColumnData, pagination }: SettlementTableProps<T>) => {
    const [bordered, setBordered] = React.useState(true);

    const [count, setCount] = React.useState(0);

    useState(() => {
        setCount(initNumber ?? 0);
    });

    const tblRef: Parameters<typeof Table>[0]['ref'] = React.useRef(null);

    return (
        <div>
            <CustomedTable
                bordered={bordered}
                virtual
                rowKey='numbering'
                dataSource={currentData}
                ref={tblRef}
                columns={mergedColumnData}
                pagination={false}
                style={{ backgroundColor: 'white' }}
                rowClassName={(_, idx) => (idx / 11 === 1 ? 'last-row' : '')}
            />
            <TPagination paginationData={pagination} />
        </div>
    );
};

export default AntdTable;

const CustomedTable = styled(Table)`
    .ant-table {
        .ant-table-container {
            // 테이블 전체 테두리 Border
            border-radius: 4px;

            table > thead > tr:first-child {
                *:first-child {
                    border-top-left-radius: 4px;
                }
                *:last-child {
                    border-top-right-radius: 4px;
                }
            }
        }
        .ant-table-content {
            border-radius: 4px;
        }
    }

    .ant-table-thead {
        .ant-table-cell {
            background-color: ${BackgroundColor.color_background_neutral};
            font: ${Body5Regular};
        }
        .supplyPrice {
            border-top-right-radius: 4px;
        }

        :where(.css-dev-only-do-not-override-1pmw8ga).ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
            border-start-end-radius: 4px;
        }
    }

    .ant-table-tbody {
        .ant-table-cell {
            font-size: 16px;
            background-color: #fff;
        }

        tr.ant-table-row:hover > td {
            background-color: ${BackgroundColor.color_background_light};
        }
    }

    .last-row {
        .numbering {
            border-bottom-left-radius: 4px;
        }
        .supplyPrice {
            border-bottom-right-radius: 4px;
        }
    }
`;
