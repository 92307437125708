import { AdminManageHospitalConstants } from './Admin/AdminManageHospitalPageConstants';
import { AdminManageUserConstants } from './Admin/AdminManageUserJoinPageConstants';
import { commonText } from './commonConstants';

export const diagnosisListHeader = [
    { title: '이름', type: 'patientName' },
    { title: '생년월일', type: 'patientIdentityNumber' },
    { title: '학령/학기', type: 'gradeName' },
    { title: '연락처', type: 'patientMobile' },
    { title: '진료일시', type: 'diagnosisDateTime' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
    { title: '', type: 'null' },
];
export const adminDiagnosisListHeader = [
    { title: '소속병원', type: 'hospitalName' },
    { title: '이름', type: 'patientName' },
    { title: '생년월일', type: 'patientIdentityNumber' },
    { title: '학령/학기', type: 'gradeName' },
    { title: '연락처', type: 'patientMobile' },
    { title: '진료일시', type: 'diagnosisDateTime' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
];
export const managingPatientsListHeader = [
    { title: '등록번호', type: 'regNum' },
    { title: '이름', type: 'name' },
    { title: '나이', type: 'age' },
    { title: '생년월일', type: 'identityNumber' },
    { title: '연락처', type: 'mobile' },
    { title: '마지막 진료일시', type: 'lastDiagnosisTimestamp' },
    { title: '총 진료 건수', type: 'allDiagnosisCount' },
    { title: '', type: 'null' },
];

export const adminManageUserListHeader = [
    { title: AdminManageUserConstants.paginationHeaderConstants.numbering, type: 'numbering' },
    { title: AdminManageUserConstants.paginationHeaderConstants.hospitalName, type: 'hospitalName' },
    { title: AdminManageUserConstants.paginationHeaderConstants.email, type: 'email' },
    { title: AdminManageUserConstants.paginationHeaderConstants.userName, type: 'name' },
    { title: AdminManageUserConstants.paginationHeaderConstants.phoneNumber, type: 'mobile' },
    { title: AdminManageUserConstants.paginationHeaderConstants.registrationTimestamp, type: 'registrationTimestamp' },
    { title: AdminManageUserConstants.paginationHeaderConstants.status, type: 'status' },
];

export const adminManageHospitalListHeader = [
    { title: AdminManageHospitalConstants.paginationHeaderConstants.numbering, type: 'numbering' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.hospitalName, type: 'hospitalName' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.phoneNumber, type: 'phoneNumber' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.registrationTimestamp, type: 'registrationTimestamp' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.userCount, type: 'userCount' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.doctorCount, type: 'doctorCount' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.patientCount, type: 'patientCount' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.diagnosisCount, type: 'diagnosisCount' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.completedAssessmentCount, type: 'completedAssessmentCount' },
    { title: AdminManageHospitalConstants.paginationHeaderConstants.totalAmount, type: 'totalAmount' },
];

export const viewDetailPatientListHeader = [
    { title: 'No.', type: 'order' },
    { title: '이름', type: 'name' },
    { title: '생년월일', type: 'identityNumber' },
    { title: '학령/학기', type: 'grade' },
    { title: '진료일시', type: 'diagnosisTimestamp' },
    { title: '담당의', type: 'doctorName' },
    { title: '검사 항목', type: 'status' },
    { title: '', type: 'null' },
];

export const ADMIN_CELL_INFO = {
    NUMBERING: 'numbering',
    HOSPITAL_NAME: 'hospitalName',
    CODE: 'hospitalCode',
    PATIENT_NAME: 'patientName',
    BIRTHDAY: 'patientBirth',
    TESTING_DATE: 'diagnosisDate',
    DOCTOR_NAME: 'doctorName',
    PSY_ITEM_ID: 'testCode',
    RETAIL_PRICE: 'retailPrice',
    SUPPLY_PRICE: 'supplyPrice',
};

// interface RecordType {
//     id:
// }

// export const adminCalculateListHeader: TableProps<RecordType>['columns'] = [
//     { title: 'No.', dataIndex: ADMIN_CELL_INFO.NUMBERING, width: 80, fixed: 'left' },
//     // { title: 'No.', type: ADMIN_CELL_INFO.NUMBERING, width: 80, zIndex: 1 },
//     { title: '병원명', dataIndex: ADMIN_CELL_INFO.HOSPITAL_NAME, width: 186, fixed: 'left' },
//     { title: '병원코드', dataIndex: ADMIN_CELL_INFO.CODE, width: 92, fixed: 'left' },
//     { title: '환자 이름', dataIndex: ADMIN_CELL_INFO.PATIENT_NAME, width: 160 },
//     { title: '생년월일', dataIndex: ADMIN_CELL_INFO.BIRTHDAY, width: 144 },
//     { title: '진료일시', dataIndex: ADMIN_CELL_INFO.TESTING_DATE, width: 216 },
//     { title: '담당의', dataIndex: ADMIN_CELL_INFO.DOCTOR_NAME, width: 120 },
//     { title: '검사항목', dataIndex: ADMIN_CELL_INFO.PSY_ITEM_ID, width: 202 },
//     { title: '매출가', dataIndex: ADMIN_CELL_INFO.RETAIL_PRICE, width: 120 },
//     { title: '공급가', dataIndex: ADMIN_CELL_INFO.SUPPLY_PRICE, width: 120 },
// ];

export const todayDiagnosisListInHome = ['이름', '생년월일', '진료일시', '검사 항목'];

export const diagnosisListWidth = ['13%', '10%', '15%', '13%', '19%', '8%', '17%', '5%'];

export const managingPatientsListWidth = ['10%', '15%', '11%', '11%', '15%', '19%', '13%', '5%'];

export const adminManageUserListWidth = [92, 272, 348, 140, 164, 220, 204];

export const adminManageHospitalWidth = [92, 224, 160, 128, 124, 124, 136, 136, 136, 180];

export const viewDetailPatientListWidth = ['8%', '13%', '11%', '16%', '20%', '10%', '17%', '5%'];

export const todayDiagnosisListInHomeWidth = ['19%', '19%', '31%', '31%'];

export const adminDiagnosisListWidth = [248, 148, 140, 196, 176, 208, 124, 200];
