import IconArrowLineRight from 'assets/Icons/Arrow/Icon_arrowLine_right';
import IconInfoFill from 'assets/Icons/Notice/Icon_Info_fill';
import BasicButton from 'components/Buttons/BasicButton';
import DropdownButton from 'components/Buttons/DropdownButton';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { MobileLabelNumber } from 'components/Buttons/MobileSelectButton';
import { AddDiagnosisPageCalendar } from 'components/Common/Calendar';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { showAssessmentChildrenList, showAssessmentParentsList } from 'constants/Government/commonConstants';
import { governmentInspectionGuidePageConstants } from 'constants/Government/governmentInspectionGuidePageConstants';
import { governmentReadyPageConstants } from 'constants/Government/governmentReadyPageConstants';
import { commonText } from 'constants/commonConstants';
import { governmentPaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import _, { map } from 'lodash';
import { ShowAssessmentListType } from 'models/Government/inspectionGuidPageTypes';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { Accent, BackgroundColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_SemiBold, Body5Medium, Body5Regular, Title2_SemiBold } from 'styles/Global/typography';
import DatePickerAccordion from './Component/DatePickerAccordion';
import BasicConfirmModal from 'components/Modal/BasicConfirmModal';

export type SelectDateType = {
    id: number;
    date: string;
};

const CounselReservationPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [selectIndex, setSelectIndex] = useState<number>(-1);
    const [selectedDateList, setSelectedDateList] = useState<SelectDateType[]>([]);
    const [isShowCounselAlert, setIsShowCounselAlert] = useToggle();

    const reserveTimeList = ['0900', '0930', '1000', '1030', '1100', '1130', '1200', '1230', '1300', '1330', '1400', '1430', '1500', '1530', '1600', '1630', '1700', '1730'];

    // const [showAssessmentList, setShowAssessmentList] = useState<ShowAssessmentListType[]>([]);

    // const { setHeaderText, setInit } = useGovernmentHeaderStore();

    // const [isLeaveModal, setIsLeaveModal] = useToggle();

    // // header text 입력 및 list filtering useEffect
    // useEffect(() => {
    //     const assessmentChildrenList = _.flatMap(state.data, (item) => _.filter(showAssessmentChildrenList, (stateItem) => item.assessmentCode === stateItem.assessmentCode));
    //     const assessmentParentsList = _.flatMap(state.data, (item) => _.filter(showAssessmentParentsList, (stateItem) => item.assessmentCode === stateItem.assessmentCode));

    //     setShowAssessmentList([...assessmentChildrenList, ...assessmentParentsList]);

    //     setHeaderText(governmentInspectionGuidePageConstants.headerText);

    //     return () => {
    //         setInit();
    //     };
    // }, []);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        // if (currentLocation.pathname === governmentPaths.inspectionGuide && nextLocation.pathname === governmentPaths.agree && !isLeaveModal) {
        // if (!true) {
        //     // setIsLeaveModal();
        //     return true;
        // }
        return false;
    });

    // // 검사 시작하기 버튼
    // const clickStartButton = () => {
    //     navigate(governmentPaths.userInspection, { replace: true, state: { data: state.data, info: state.info, diagnosisInfo: state.diagnosisInfo } });
    // };

    // // modal 나가기 버튼
    // const clickLeaveButton = () => {
    //     setInit();
    //     setIsLeaveModal();
    //     navigate(governmentPaths.agree);
    // };

    console.log('@!! length', selectedDateList?.length);

    return (
        <Layout>
            {isShowCounselAlert && (
                <MobileLeaveModal
                    title={'이대로 상담을 요청할까요?'}
                    content={'상담을 요청하면, 희망 일시를 수정할 수 없습니다. 확인하고 요청 부탁드립니다!'}
                    setIsLeaveModal={setIsShowCounselAlert}
                    clickLeaveButtonInLeaveModal={() => {}}
                    firstButton='아니요'
                    secondButton={'상담 요청'}
                    firstButtonType='anotherFill'
                    secondButtonType='confirm'
                    infoContents={_.map(selectedDateList, (e, i) => (
                        <div key={e.date}>
                            <ReserveTimeContainer>
                                <TimeIndexContainer>{`${i + 1}. `}</TimeIndexContainer>
                                {moment(e.date, 'YYYYMMDDhhmm').format('YYYY/MM/DD A HH:mm')}
                            </ReserveTimeContainer>
                            {i < selectedDateList.length - 1 && <Separator />}
                        </div>
                    ))}
                />
            )}
            <WrapContainer>
                <TitleContainer>
                    <Title>검사가 완료되었습니다!</Title>
                    <Title>상담 일시를 선택해주세요.</Title>
                </TitleContainer>
                <InspectionGuideContainer>
                    <InspectionTitleWrapper>
                        <IconWrapper>
                            <IconInfoFill />
                        </IconWrapper>
                    </InspectionTitleWrapper>
                    <DescContainer>
                        검사 안내
                        <DescWrapper>
                            <Body5Regular>3개의 희망 일시를 선택하시면,</Body5Regular>
                            <Body5Regular>상담사와 조율하여 지정된 상담일을 전달드립니다.</Body5Regular>
                        </DescWrapper>
                    </DescContainer>
                </InspectionGuideContainer>
                <SelectDateWrapper>
                    {_.map([0, 1, 2], (e) => {
                        return (
                            <SelectDateContainer>
                                {`상담 희망 일시 ${e + 1}`}
                                <DatePickerAccordion
                                    selectedList={selectedDateList}
                                    selectButtonIndex={selectIndex}
                                    idx={e}
                                    handleShowCalendar={setSelectIndex}
                                    handleSelectedCalendarList={setSelectedDateList}
                                />
                            </SelectDateContainer>
                        );
                    })}
                </SelectDateWrapper>
                <MobileBasicButton
                    onClick={() => {
                        setIsShowCounselAlert();
                    }}
                    $type='fill'
                    disabled={selectedDateList?.length < 3}
                >
                    상담 요청하기
                </MobileBasicButton>
            </WrapContainer>
        </Layout>
    );
};

export default CounselReservationPage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 32px;
    background-color: ${BackgroundColor.color_background_light};
`;

const Title = styled(Title2_SemiBold)``;

const WrapContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 360px;
    padding: 24px 16px 16px 16px;
    background-color: #fff;
    gap: 32px;
`;

const InspectionGuideContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 4px;
    border-radius: ${Radius.radius_8};
    padding: 12px 16px 14px 12px;
    background-color: ${BackgroundColor.color_background_primary_neutral};
`;

const InspectionTitleWrapper = styled(Body4_SemiBold)`
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 4px;
    gap: 4px;
`;

const DescWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const SelectDateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 36px;
`;

const SelectDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 3px;
`;

const ReserveTimeContainer = styled.div`
    display: flex;
    gap: 12px;
    height: 24px;
`;

const TimeIndexContainer = styled.div`
    width: 16px;
    color: ${TextColor.color_text_weak};
`;

const Separator = styled.div`
    height: 12px;
`;
