import { processToolTipText } from 'constants/diagnosisListPageConstants';
import { ProgressCheckBarProps } from 'models/Common/buttonTypes';
import React from 'react';
import styled from 'styled-components';
import { Accent, BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { font, palette, zIndex } from 'styles/Global/globalStyles';
import { Radius } from 'styles/Global/objectStyles';
import { Caption2_Medium, Caption2_Regular } from 'styles/Global/typography';

const ProcessToolTip = ({ diagnosisInfo }: ProgressCheckBarProps) => {
    return (
        <Layout>
            {diagnosisInfo?.status === 1 ? processToolTipText.PENDING : diagnosisInfo?.status === 2 ? processToolTipText.INPROGRESS : processToolTipText.COMPLETED}
            <InnerLayout>
                {diagnosisInfo?.assessmentList?.map((assessment, index) => {
                    if (diagnosisInfo.status === 3) {
                        if (assessment.status === 2) {
                            return (
                                <CellWrapper key={index}>
                                    <Count>{index + 1 + '.'}</Count>
                                    {assessment.group}
                                    <Circle $diagnosisInfoStatus={assessment.status} />
                                </CellWrapper>
                            );
                        }
                    } else {
                        return (
                            <CellWrapper key={index}>
                                <Count>{index + 1 + '.'}</Count>
                                {assessment.group}
                                <Circle $diagnosisInfoStatus={assessment.status} />
                            </CellWrapper>
                        );
                    }
                })}
            </InnerLayout>
        </Layout>
    );
};

export default ProcessToolTip;

const Layout = styled(Caption2_Medium)`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 164px;
    background-color: ${BackgroundColor.color_background_white};
    border: 1px solid ${BorderColor.color_border_over};
    padding: 12px;
    border-radius: ${Radius.radius_8};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    line-height: normal;
    top: 35px;
    left: 5px;
    color: ${TextColor.color_text_light};
    gap: 6px;
    z-index: ${zIndex.floating};
    pointer-events: none;
`;

const InnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 10px;
    gap: 4px;
    background-color: ${BackgroundColor.color_background_neutral};
    border-radius: ${Radius.radius_4};
`;

const CellWrapper = styled.div`
    color: ${TextColor.color_text_normal};
    display: flex;
    align-items: center;
    gap: 2px;
`;

const Count = styled(Caption2_Regular)`
    display: flex;
    justify-content: flex-start;
    width: 16px;
    color: ${TextColor.color_text_weak};
`;

const Circle = styled.div<{ $diagnosisInfoStatus?: number }>`
    width: 6px;
    height: 6px;
    border-radius: 99px;
    margin-left: 3px;
    background-color: ${(props) => (props.$diagnosisInfoStatus === 0 ? Accent.color_accent_lime : props.$diagnosisInfoStatus === 1 ? Accent.color_accent_cyan : PrimaryColor.color_primary_normal)};
`;
