import { AdminSettlementPaginationProps } from 'models/Admin/AdminPaginationTypes';
import { LoginStore, loginData } from 'models/Login/LoginDataStoreTypes';
import { create } from 'zustand';

export const usePageIndexDataStore = create<AdminSettlementPaginationProps>((set) => ({
    currentPage: 1,
    pageSection: 1,
    paginationData: {
        totalCount: 0,
        totalPages: 0,
        page: 0,
        limit: 12,
    },
    handlePageIndex: (index) => set({ currentPage: index }),
    handlePageSection: (index) => set({ pageSection: index }),
}));
