import { header } from 'api/Auth/auth';
import axios from 'axios';
import { apiPaths } from 'constants/path';
import { VerifyVoucherCodeApiResopnse, VerifyVoucherCodeType } from 'models/Government/apiTypes';

class PreciousAuthApi {
    async verifyPurchaseCode(rqData: VerifyVoucherCodeType): Promise<VerifyVoucherCodeApiResopnse> {
        try {
            const response = await axios.post(apiPaths.verifyVoucherCode, rqData, {
                headers: header,
            });
            return response.data as VerifyVoucherCodeApiResopnse;
        } catch (error) {
            throw error;
        }
    }
}
export const preciousAuthApi = new PreciousAuthApi();
