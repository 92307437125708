import { TodayDiagnosisSliderProps, TodaydiagnosisSliderData } from 'models/Hospital/todayDiagnosisPageTypes';
import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { calculateAge } from 'functions/calculateAge';
import moment from 'moment';
import Carousel from 'react-material-ui-carousel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import '../../../styles/Global/customCalender.css';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import { PieChart } from 'react-minimal-pie-chart';
import { Accent, BackgroundColor, BorderColor, PrimaryColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import GraphCheck from 'assets/Icons/Check/GraphCheck';
import { Body5Regular } from 'styles/Global/typography';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';

const TodayDiagnosisSlider = ({ data, type }: TodayDiagnosisSliderProps) => {
    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);

    const groupedData: TodaydiagnosisSliderData[][] = [];

    if (data) {
        const count = type === 'home' ? 3 : 4;
        for (let i = 0; i < data.length; i += count) {
            groupedData.push(data.slice(i, i + count));
        }

        if (groupedData.length > 0) {
            while (groupedData[groupedData.length - 1].length < count) {
                groupedData[groupedData.length - 1].push({
                    _id: '',
                });
            }
        }
    }

    // 자동으로 다음 slide로 넘어가는 조건
    useEffect(() => {
        let nextIndex = currentIndex;

        for (let groupIndex = currentIndex; groupIndex < groupedData.length; groupIndex++) {
            let count = 0;

            if (groupedData[groupIndex].length === (type === 'home' ? 3 : 4)) {
                for (const item of groupedData[groupIndex]) {
                    if (item.status !== 3) {
                        count++;
                        break;
                    }
                }
            }

            if (count === 0) {
                nextIndex = groupIndex + 1;
            } else {
                break;
            }
        }

        if (nextIndex !== currentIndex) {
            setCurrentIndex(nextIndex < groupedData.length ? nextIndex : 0);
        }
    }, [data]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < groupedData.length - 1 ? prevIndex + 1 : 0));
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : groupedData.length - 1));
    };

    // pie chart Data format
    const handlePieData = (item: TodaydiagnosisSliderData) => {
        if (item?.status === 1) {
            return [{ value: 100, color: BorderColor.color_border_neutral }];
        } else if (item?.status === 2) {
            const sortedAssessmentList = [...item.assessmentList!].sort((a, b) => b.status - a.status);
            const arr = sortedAssessmentList.map((assessment) => {
                return {
                    value: 100 / item.assessmentList!.length,
                    color: assessment.status === 1 ? Accent.color_accent_cyan : assessment.status === 2 ? PrimaryColor.color_primary_normal : BorderColor.color_border_neutral,
                };
            });

            return arr;
        } else if (item?.status === 3) {
            return [{ value: 100, color: 'rgba(38, 76, 151, 1)' }];
        } else {
            return [];
        }
    };

    return data ? (
        <CarouselWrapper
            className='carousel'
            strictIndexing={false}
            duration={200}
            autoPlay={false}
            indicators={false}
            navButtonsAlwaysVisible={true}
            interval={5000}
            animation={'fade'}
            index={currentIndex}
            next={handleNext}
            prev={handlePrev}
            NavButton={({ onClick, className, style, next, prev }) => {
                const buttonStyle: CSSProperties = {
                    ...style,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    border: '1px solid #e0e0e0',
                    width: '32px',
                    height: '32px',
                    borderRadius: 99,
                    position: 'absolute',
                    top: type === 'home' ? '158px' : '193px',
                };

                if (next) {
                    buttonStyle.right = type === 'home' ? '12px' : '-15px';
                }

                if (prev) {
                    buttonStyle.left = type === 'home' ? '12px' : '-15px';
                }

                return (
                    groupedData.length > 1 && (
                        <button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onClick(event)} className={className} style={buttonStyle}>
                            {next && <NavigateNextIcon />}
                            {prev && <NavigateBeforeIcon />}
                        </button>
                    )
                );
            }}
        >
            {/* 우리병원 홈에서 data가 없을 때 slide diagnosis */}
            {(!data || data.length === 0) && type === 'home' ? (
                <SlideWrapper>
                    {new Array(3).fill(0).map((_, idx) => {
                        return <NothingDataItemWrapper key={idx}></NothingDataItemWrapper>;
                    })}
                </SlideWrapper>
            ) : (
                groupedData.map((group, idx) => (
                    <SlideWrapper key={idx}>
                        {group.map((item, idx) => {
                            if (item.patientIdentityNumber) {
                                const { birthDate, ageString, gender } = calculateAge(item.patientIdentityNumber);

                                return (
                                    <ItemWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })} $complete={item.status === 3}>
                                        {item.status !== 0 && (
                                            <ChartPieWrapper>
                                                <PieChart data={handlePieData(item) || []} lineWidth={20} startAngle={-90} />
                                                {item.status === 3 && <GraphCheck />}
                                            </ChartPieWrapper>
                                        )}
                                        <ItemInnerInfoWrapper>
                                            <TimeWrapper>
                                                {(() => {
                                                    const date = moment(item.diagnosisDateTime, 'YYYYMMDDHHmm');
                                                    const minutes = date.format('mm');
                                                    return date.format(`A h시 ${minutes === '00' ? '' : 'mm분'}`);
                                                })()}
                                            </TimeWrapper>
                                            <NameAndGenderWrapper>
                                                {item.patientName}
                                                <span style={{ display: 'flex', alignItems: 'center' }}>{gender === 'male' ? <GenderMan /> : <GenderWoman />}</span>
                                            </NameAndGenderWrapper>
                                            <AgeAndBirthWrapper>
                                                <Text>{birthDate}</Text>
                                                <AgeString>{`(${ageString})`}</AgeString>
                                            </AgeAndBirthWrapper>
                                            <Text>{item.gradeName}</Text>
                                        </ItemInnerInfoWrapper>
                                        <ProcessCheckBar diagnosisInfo={item} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })} />
                                    </ItemWrapper>
                                );
                            } else {
                                return <ItemWrapper key={idx}></ItemWrapper>;
                            }
                        })}
                    </SlideWrapper>
                ))
            )}
        </CarouselWrapper>
    ) : (
        <div></div>
    );
};
export default TodayDiagnosisSlider;

const ChartPieWrapper = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    right: 20px;
    top: 20px;
`;

const CarouselWrapper = styled(Carousel)`
    position: static !important;
    overflow: visible !important;
    & .slide {
        transition-duration: 20s !important;
    }
`;

const SlideWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    height: 220px;
`;

const ItemWrapper = styled.div<{ $complete?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 12px;
    width: 288px;
    height: 196px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 8px;
    cursor: pointer;
    background-color: ${(props) => props.$complete && BackgroundColor.color_background_primary_neutral};
`;

const NothingDataItemWrapper = styled.div`
    width: 288px;
    height: 196px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 8px;
    background-color: ${BackgroundColor.color_background_neutral};
`;

const ItemInnerInfoWrapper = styled.div`
    padding-left: 4px;
`;

const TimeWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    height: 26px;
    padding: 0 8px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_4};
    color: ${palette.gray};
    font-size: ${font.size[13]};
    font-weight: ${font.weight.medium};
    margin-bottom: 8px;
`;

const NameAndGenderWrapper = styled.div`
    display: flex;
    height: 24px;
    gap: 4px;
    align-items: center;
    font-size: ${font.size[20]};
    font-weight: ${font.weight.bold};
    margin-bottom: 4px;
`;

const AgeAndBirthWrapper = styled.div`
    display: flex;
    height: 20px;
    align-items: center;
    gap: 2px;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    margin-bottom: 2px;
`;

const Text = styled(Body5Regular)`
    color: ${TextColor.color_text_neutral};
`;
const AgeString = styled(Body5Regular)`
    color: ${TextColor.color_text_light};
`;
