import _ from 'lodash';
import IconInfoFill from 'assets/Icons/Notice/Icon_Info_fill';
import MobileLeaveModal from 'components/Modal/MobileLeaveModal';
import { governmentInspectionGuidePageConstants } from 'constants/Government/governmentInspectionGuidePageConstants';
import { governmentReadyPageConstants } from 'constants/Government/governmentReadyPageConstants';
import { commonText } from 'constants/commonConstants';
import { governmentPaths, preciousChildPaths } from 'constants/path';
import useToggle from 'hooks/CustomHooks/useToggle';
import { useEffect, useState } from 'react';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useGovernmentHeaderStore } from 'store/useGovernmentHeaderStore';
import styled from 'styled-components';
import { TitleContainer } from 'styles/Global/Government/agreeAndCodeConfirmPageStyles';
import { Accent } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_SemiBold, Body5Medium, Body5Regular, Title2_SemiBold } from 'styles/Global/typography';
import { ShowAssessmentListType } from 'models/PreciousChild/inspectionGuidPageTypes';
import { MobileAssessmentButton } from './components/AssessmentButton';
import { preciousAssessmentApi } from 'api/PreciousChild/preciousAssessmentApi';

const InspectionGuidePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const diagnosisInfo = state['diagnosisInfo'];
    const token = state['info']['token'];

    const [showAssessmentList, setShowAssessmentList] = useState<ShowAssessmentListType[]>([]);

    const { setHeaderText, setInit } = useGovernmentHeaderStore();

    const [isLeaveModal, setIsLeaveModal] = useToggle();

    // header text 입력 및 list filtering useEffect
    useEffect(() => {
        const assessmentList = diagnosisInfo['assessmentList'];

        // const assessmentChildrenList = _.flatMap(state.data, (item) => _.filter(assessmentList, (stateItem) => item.assessmentCode === stateItem.assessmentCode));
        // const assessmentParentsList = _.flatMap(state.data, (item) => _.filter(assessmentList, (stateItem) => item.assessmentCode === stateItem.assessmentCode));
        // setShowAssessmentList([...assessmentChildrenList, ...assessmentParentsList]);
        setShowAssessmentList([...assessmentList]);

        setHeaderText(governmentInspectionGuidePageConstants.headerText);
        return () => {
            setInit();
        };
    }, []);

    // 뒤로 가기 기능 차단
    useBlocker(({ currentLocation, nextLocation }) => {
        if (currentLocation.pathname === governmentPaths.inspectionGuide && nextLocation.pathname === governmentPaths.agree && !isLeaveModal) {
            setIsLeaveModal();
            return true;
        }
        return false;
    });

    // 검사 시작하기 버튼
    const clickStartButton = () => {
        // navigate(governmentPaths.userInspection, { replace: true, state: { data: state.data, info: state.info, diagnosisInfo: state.diagnosisInfo } });
    };

    // modal 나가기 버튼
    const clickLeaveButton = () => {
        // setInit();
        // setIsLeaveModal();
        // navigate(governmentPaths.agree);
    };

    return (
        <Layout>
            {isLeaveModal && (
                <MobileLeaveModal
                    title={governmentReadyPageConstants.modalTitle1}
                    subTitle={governmentReadyPageConstants.modalTitle2}
                    content={governmentReadyPageConstants.modalContent}
                    setIsLeaveModal={setIsLeaveModal}
                    clickLeaveButtonInLeaveModal={clickLeaveButton}
                    firstButton={commonText.no}
                    secondButton={commonText.out}
                />
            )}
            {!_.isEmpty(showAssessmentList) && (
                <>
                    <TitleAndDescContainer>
                        <TitleContainer>
                            <>아래의 검사를 진행해주세요.</>
                        </TitleContainer>
                        <InspectionGuideContainer>
                            <InspectionTitleWrapper>
                                <IconWrapper>
                                    <IconInfoFill />
                                </IconWrapper>
                                검사 안내
                            </InspectionTitleWrapper>
                            <DescContainer>
                                <DescWrapper>
                                    <Body5Medium>-</Body5Medium>
                                    <Body5Medium>본검사는 시험이 아니므로, 정답과 오답이 따로 없으며 좋고 나쁜 답도 없습니다.</Body5Medium>
                                </DescWrapper>
                                <DescWrapper>
                                    <Body5Regular>-</Body5Regular>
                                    <Body5Regular>자신과 같거나 가깝다고 생각되는 대로 응답해 주시면 됩니다.</Body5Regular>
                                </DescWrapper>
                                <DescWrapper>
                                    <Body5Regular>-</Body5Regular>
                                    <Body5Regular>정확한 검사 결과를 위해 한 문장도 빠짐없이 기록해 주시기 바랍니다.</Body5Regular>
                                </DescWrapper>
                            </DescContainer>
                        </InspectionGuideContainer>
                    </TitleAndDescContainer>
                    <AssessmentListContainer>
                        {showAssessmentList.map((assessment, index) => {
                            const getTesterTagName = () => {
                                if (assessment.subName === '부모용') {
                                    return '부모검사';
                                } else if (assessment.subName === '부모보고형') {
                                    return '보호자용';
                                }

                                return '자녀용';
                            };

                            return (
                                <MobileAssessmentButton
                                    $title={assessment.assessmentCode}
                                    $subTitle={''}
                                    $desc={assessment.assessmentName}
                                    $label={getTesterTagName()}
                                    // $type={assessment.label === '자녀용' ? 'cyan' : 'parents'}
                                    $type={'parents'}
                                    $bedgeType='strong'
                                    $number={index + 1}
                                    $onClick={async () => {
                                        const response = await preciousAssessmentApi.getAssessmentScriptByCode(assessment.assessmentCode, token);
                                        navigate(preciousChildPaths.assessmentScript, { replace: false, state: { data: response.data, info: state.info, diagnosisInfo: state.diagnosisInfo } });
                                    }}
                                />
                            );
                        })}
                    </AssessmentListContainer>
                    {/* <MobileBasicButton onClick={clickStartButton} $type='fill' $gap='4px'>
                        상담 예약하러 가기
                    </MobileBasicButton> */}
                </>
            )}
        </Layout>
    );
};

export default InspectionGuidePage;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TitleAndDescContainer = styled(Title2_SemiBold)`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const InspectionGuideContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    border-radius: ${Radius.radius_8};
    padding: 12px 16px 14px 12px;
    background-color: ${Accent['color-accent-yellow-opacity-8']};
`;

const InspectionTitleWrapper = styled(Body4_SemiBold)`
    display: flex;
    height: 24px;
    align-items: center;
    gap: 4px;
`;

const DescContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    gap: 4px;
`;

const DescWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: start;
`;

const AssessmentListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    gap: 8px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 24px;
    height: 24px;
    padding: 3px;
`;
