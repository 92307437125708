import React from 'react';

const IconInfoFill = () => {
    return (
        <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z' fill='#F4C10B' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4ZM7.50005 7.29999C7.11345 7.29999 6.80005 7.61339 6.80005 7.99999C6.80005 8.38659 7.11345 8.69999 7.50005 8.69999H8.29995L8.29995 12.3H7.50005C7.11345 12.3 6.80005 12.6134 6.80005 13C6.80005 13.3866 7.11345 13.7 7.50005 13.7H10.5C10.8866 13.7 11.2 13.3866 11.2 13C11.2 12.6134 10.8866 12.3 10.5 12.3H9.69995L9.69995 8.01181L9.70005 7.99999C9.70005 7.61339 9.38665 7.29999 9.00005 7.29999H7.50005Z'
                fill='white'
            />
        </svg>
    );
};

export default IconInfoFill;
