import React from 'react';
import { AdminListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import moment from 'moment';
import { changeMobileForm } from 'functions/changeMobileForm';
import { adminManageUserListWidth } from 'constants/pagination';
import { AdminManageUserJoinPaginationTableProps } from 'models/Admin/AdminPaginationTypes';
import { getWidthRation } from 'functions/calculateWdith';
import { NegativeButton, PositiveButton } from 'components/Buttons/VariantsButtons';
import { AccountStatus } from 'utils/statusCode';
import { Body3_Medium } from 'styles/Global/typography';
import styled from 'styled-components';
import { PrimaryColor, WarningColor } from 'styles/Global/colorSemantic';
import ToolTipEnableIcon from 'assets/Icons/ToolTip/ToolTipEnableIcon';
import ToolTipDisabledIcon from 'assets/Icons/ToolTip/ToolTipDisabledIcon';
import { AdminManageUserConstants } from 'constants/Admin/AdminManageUserJoinPageConstants';

const AdminManageUserJoinPaginationTable = ({ currentData, totalCount, currentPage, clickUserManageButton }: AdminManageUserJoinPaginationTableProps) => {
    const itemsPerPage = 11;

    return (
        <>
            {currentData?.map((item, idx) => {
                const registrationDate = moment(item.registrationTimestamp).format('YYYY/MM/DD A h:mm');
                const itemNumber = totalCount - (currentPage - 1) * itemsPerPage - idx;

                return (
                    <AdminListWrapper key={idx}>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[0])} $type='no'>
                            {itemNumber}
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[1])} $type='name'>
                            {item.hospitalName}
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[2])}>{item.email}</TableCell>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[3])}>{item.name}</TableCell>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[4])}>{changeMobileForm(item.mobile)}</TableCell>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[5])}>{registrationDate}</TableCell>
                        <TableCell $width={getWidthRation(adminManageUserListWidth[6])} $padding='8px 14px' $justifyContent='space-between' $gap='6px'>
                            {item.status === AccountStatus.pending && (
                                <>
                                    <NegativeButton $height='40px' onClick={() => clickUserManageButton(item, 'reject')}>
                                        {AdminManageUserConstants.pageCommonConstants.reject}
                                    </NegativeButton>
                                    <PositiveButton $height='40px' onClick={() => clickUserManageButton(item, 'accept')}>
                                        {AdminManageUserConstants.pageCommonConstants.accept}
                                    </PositiveButton>
                                </>
                            )}
                            {item.status === AccountStatus.active && (
                                <ActiveWrapper>
                                    <ToolTipEnableIcon />
                                    {AdminManageUserConstants.pageCommonConstants.completeAccept}
                                </ActiveWrapper>
                            )}
                            {item.status === AccountStatus.reject && (
                                <RejectWrapper>
                                    <ToolTipDisabledIcon />
                                    {AdminManageUserConstants.pageCommonConstants.completeReject}
                                </RejectWrapper>
                            )}
                        </TableCell>
                    </AdminListWrapper>
                );
            })}
        </>
    );
};

export default AdminManageUserJoinPaginationTable;

const StatusWrapper = styled(Body3_Medium)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 4px;
    line-height: normal;
`;

const ActiveWrapper = styled(StatusWrapper)`
    color: ${PrimaryColor.color_primary_normal};
`;

const RejectWrapper = styled(StatusWrapper)`
    color: ${WarningColor.color_warning_normal};
`;
