import { adminManageFilterList } from 'constants/Admin/AdminManageUserJoinPageConstants';
import { DropdownCell, DropdownLayout } from './DropdownForms';
import { adminSearchFilterList, manageFilterList, searchFilterList } from 'constants/diagnosisListPageConstants';
import { SearchFilterDropdownNotPeriodProps } from 'models/Hospital/diagnosisListPageTypes';
import { adminManageHospitalFilterList } from 'constants/Admin/AdminManageHospitalPageConstants';

const SearchFilterDropdownNotPeriod = ({ selectedSearchFilter, handleDropdown, type }: SearchFilterDropdownNotPeriodProps) => {
    const divideDropdownList = (type?: string) => {
        switch (type) {
            case 'adminManageUserJoinPage':
                return adminManageFilterList;
            case 'managingPatients':
                return manageFilterList;
            case 'admin':
                return adminSearchFilterList;
            default:
                return searchFilterList;
        }
    };

    const divideDropdownHeight = (type?: string) => {
        switch (type) {
            case 'adminManageUserJoinPage':
                return '118px';
            case 'managingPatients':
                return '154px';
            default:
                return '188px';
        }
    };

    return (
        <DropdownLayout $position='absolute' $height={divideDropdownHeight(type)} $padding='4px 0' $top='44px' $left='0'>
            {divideDropdownList(type).map((item, idx) => {
                return (
                    <DropdownCell key={idx} $height='36px' $padding='8px 12px' onClick={(e: React.MouseEvent) => handleDropdown(e, idx, 'search')} $selected={selectedSearchFilter === idx}>
                        {item}
                    </DropdownCell>
                );
            })}
        </DropdownLayout>
    );
};

export default SearchFilterDropdownNotPeriod;
