import { Divider, Input, DatePicker } from 'antd';
import SearchIcon from 'assets/Icons/Common/SearchIcon';
import CustomDropdown from 'components/Admin/Dropdown/CustomDropdown';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { BorderColor } from 'styles/Global/colorSemantic';
import { SearchIconWrapper } from 'styles/Input/inputStyle';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

type Props = {
    isShowPeriodFilter?: boolean;
    isShowWordSearch?: boolean;
    selectedSearchPeriod?: number;
    startSearchDate?: number;
    endSearchDate?: number;
    onChangePeriod?: (v: string) => void;
    onChangeMonth?: (v: string) => void;
    onChangeSearchDateList?: (v: string[]) => void;
    onChangeSearchWord?: (v: string) => void;
    onClickedSearch?: (v: boolean) => void;
};

const periodOptions: string[] = ['1년', '1개월', '1주', '오늘', '직접 입력'];

const monthOptions: string[] = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

const keywordOptions: string[] = ['병원명', '환자명', '담당의'];

const FilterButtons = ({ isShowPeriodFilter, isShowWordSearch, onChangePeriod, onChangeMonth, onChangeSearchDateList, onChangeSearchWord, onClickedSearch }: Props) => {
    const [periodTitle, setPeriodTitle] = useState('1개월');
    const [searchCategory, setSearchCategory] = useState('병원명');

    return (
        <FilterButtonContainer>
            <SearchContainer isShow={isShowPeriodFilter}>
                <CustomDropdown defaultSelectValue={periodTitle} menuList={periodOptions} onSelect={onChangePeriod} />
                {<CustomDropdown menuList={monthOptions} defaultSelectValue={periodTitle} disabled={true} />}
                <RangePicker
                    format={'YYYY/MM/DD'}
                    onChange={(_, dateString) => {
                        if (!onChangeSearchDateList) {
                            return;
                        }

                        onChangeSearchDateList(dateString);
                    }}
                />
            </SearchContainer>
            <Divider type='vertical' style={{ height: 20, color: BorderColor.color_border_neutral, visibility: isShowPeriodFilter !== false ? 'visible' : 'hidden' }} />
            <SearchContainer isShow={isShowWordSearch}>
                <CustomDropdown defaultSelectValue={searchCategory} menuList={keywordOptions} onSelect={setSearchCategory} />
                <CustomedSearchContainer
                    placeholder='검색어를 입력해주세요.'
                    suffix={
                        <SearchIconWrapper onClick={() => {}}>
                            <SearchIcon />
                        </SearchIconWrapper>
                    }
                    onChange={(e) => {
                        if (!onChangeSearchWord) {
                            return;
                        }
                        onChangeSearchWord(e.target.value);
                    }}
                    onPressEnter={(e) => {
                        if (!onClickedSearch) {
                            return;
                        }

                        onClickedSearch(true);
                    }}
                />
            </SearchContainer>
        </FilterButtonContainer>
    );
};

export default FilterButtons;

const FilterButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    gap: 6px;
`;

const SearchContainer = styled.div<{ isShow?: boolean }>`
    display: flex;
    visibility: ${(props) => (props.isShow !== false ? 'visible' : 'hidden')};
    display: flex;
    height: 40px;
    gap: 6px;
`;

const CustomedSearchContainer = styled(Input)`
    width: 220px;
    height: 40px;
    padding: 12px 10px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: 4px;
`;
