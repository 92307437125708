import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetUserListApiType } from 'models/Admin/AdminPaginationTypes';

class AdminManageUserJoinPageApi {
    async getUserList(page: number | null, selectedSearchFilter: number | null, searchValue: string | null, token: string): Promise<GetUserListApiType> {
        const url = `${apiPaths.getUserList}?page=${page}&limit=${11}&searchType=${selectedSearchFilter}&searchKeyword=${searchValue}`;
        try {
            const resopnse = await axios.get(url, { headers: tokenHeader(token) });
            return resopnse.data;
        } catch (error) {
            throw error;
        }
    }
}

export const adminManageUserJoinPageApi = new AdminManageUserJoinPageApi();
