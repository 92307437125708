import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const LnbDiagnosisListIcon = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M10.5 8.39999C10.5 7.90294 10.9029 7.49999 11.4 7.49999H15.8C16.2971 7.49999 16.7 7.90294 16.7 8.39999C16.7 8.89705 16.2971 9.29999 15.8 9.29999H11.4C10.9029 9.29999 10.5 8.89705 10.5 8.39999Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
            <path
                d='M8.3999 9.39999C8.95219 9.39999 9.3999 8.95228 9.3999 8.39999C9.3999 7.84771 8.95219 7.39999 8.3999 7.39999C7.84762 7.39999 7.3999 7.84771 7.3999 8.39999C7.3999 8.95228 7.84762 9.39999 8.3999 9.39999Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
            <path
                d='M10.5 12C10.5 11.5029 10.9029 11.1 11.4 11.1H15.8C16.2971 11.1 16.7 11.5029 16.7 12C16.7 12.4971 16.2971 12.9 15.8 12.9H11.4C10.9029 12.9 10.5 12.4971 10.5 12Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
            <path
                d='M8.3999 13C8.95219 13 9.3999 12.5523 9.3999 12C9.3999 11.4477 8.95219 11 8.3999 11C7.84762 11 7.3999 11.4477 7.3999 12C7.3999 12.5523 7.84762 13 8.3999 13Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
            <path
                d='M10.5 15.6C10.5 15.1029 10.9029 14.7 11.4 14.7H15.8C16.2971 14.7 16.7 15.1029 16.7 15.6C16.7 16.0971 16.2971 16.5 15.8 16.5H11.4C10.9029 16.5 10.5 16.0971 10.5 15.6Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
            <path
                d='M8.3999 16.6C8.95219 16.6 9.3999 16.1523 9.3999 15.6C9.3999 15.0477 8.95219 14.6 8.3999 14.6C7.84762 14.6 7.3999 15.0477 7.3999 15.6C7.3999 16.1523 7.84762 16.6 8.3999 16.6Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM19 5H5V19H19V5Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default LnbDiagnosisListIcon;
