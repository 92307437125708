import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { GetHospitalDataApiResponse, AddDiagnosisRqData, DiagnosisApiResponse, VoucherAddDiagnosisRqData, AssignCounselorRqData, CounselDateTimeRqData } from 'models/Hospital/hospitalApiTypes';

class AddDiagnosisApi {
    async getHospitalData(url: string, token: string): Promise<GetHospitalDataApiResponse> {
        try {
            const response = await axios.get(apiPaths.getHospitalData + url, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async addDiagnosis(rqData: AddDiagnosisRqData, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.post(apiPaths.addDiagnosis, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async governmentAddDiagnosis(rqData: VoucherAddDiagnosisRqData, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.post(apiPaths.addDiagnosis, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateCounselDateTime(rqData: CounselDateTimeRqData, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.put(apiPaths.updateCounselDateTime, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateAssignCounselor(rqData: AssignCounselorRqData, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.put(apiPaths.updateAssignCounselor, rqData, {
                headers: tokenHeader(token),
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export const addDiagnosisApi = new AddDiagnosisApi();
