import { Select } from 'antd';
import _ from 'lodash';

const { Option } = Select;

type Props = {
    menuList: string[];
    defaultSelectValue: string;
    disabled?: boolean;
    onSelect?: (e: string) => void;
};

const CustomDropdown = ({ disabled, menuList, defaultSelectValue, onSelect }: Props) => {
    return (
        <Select
            onChange={(e) => {
                if (!onSelect) {
                    return;
                }

                onSelect(e);
            }}
            defaultValue={defaultSelectValue}
            size='large'
            style={{ borderRadius: 4, width: 100 }}
            disabled={disabled}
        >
            {_.map(menuList, (item, _) => (
                <Option key={item} style={{}}>
                    {item}
                </Option>
            ))}
        </Select>
    );
};

export default CustomDropdown;
