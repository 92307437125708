import React from 'react';
import { AdminListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import moment from 'moment';
import { adminManageHospitalWidth } from 'constants/pagination';
import { AdminManageHospitalPaginationTableProps } from 'models/Admin/AdminPaginationTypes';
import { getWidthRation } from 'functions/calculateWdith';
import { Body3_Medium } from 'styles/Global/typography';
import styled from 'styled-components';
import { PrimaryColor, WarningColor } from 'styles/Global/colorSemantic';

const AdminManageHospitalPaginationTable = ({ currentData, totalCount, currentPage }: AdminManageHospitalPaginationTableProps) => {
    const itemsPerPage = 11;

    return (
        <>
            {currentData?.map((item, idx) => {
                const registrationDate = moment(item.timestamp).format('YYYY/MM/DD');
                const itemNumber = totalCount - (currentPage - 1) * itemsPerPage - idx;

                return (
                    <AdminListWrapper key={idx}>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[0])} $type='no'>
                            {itemNumber}
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[1])} $type='name'>
                            {item.name}
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[2])}>{item.tel}</TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[3])}>{registrationDate}</TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[4])}>{item.userCount}개 계정</TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[5])}>{item.doctorCount}명 담당의</TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[6])} $padding='8px 14px' $justifyContent='space-between' $gap='6px'>
                            {item.patientCount}명 환자
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[7])} $padding='8px 14px' $justifyContent='space-between' $gap='6px'>
                            {item.diagnosisCount}개 검사
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[8])} $padding='8px 14px' $justifyContent='space-between' $gap='6px'>
                            {item.completedAssessmentCount}개 검사
                        </TableCell>
                        <TableCell $width={getWidthRation(adminManageHospitalWidth[9])} $padding='8px 14px' $justifyContent='space-between' $gap='6px'>
                            {item.totalAmount.toLocaleString()}원
                        </TableCell>
                    </AdminListWrapper>
                );
            })}
        </>
    );
};

export default AdminManageHospitalPaginationTable;

const StatusWrapper = styled(Body3_Medium)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 4px;
    line-height: normal;
`;

const ActiveWrapper = styled(StatusWrapper)`
    color: ${PrimaryColor.color_primary_normal};
`;

const RejectWrapper = styled(StatusWrapper)`
    color: ${WarningColor.color_warning_normal};
`;
