import { BackgroundColor, TextColor } from 'styles/Global/colorSemantic';
import styled, { css } from 'styled-components';
import { font } from './globalStyles';

export type Props = {
    $color?: string;
};

export const Display1_Bold = styled.span<Props>`
    font-size: ${font.size[40]};
    font-weight: ${font.weight.bold};
    line-height: 54px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Display1_SemiBold = styled.span<Props>`
    font-size: ${font.size[40]};
    font-weight: ${font.weight.semiBold};
    line-height: 54px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Display2_Bold = styled.span<Props>`
    font-size: ${font.size[32]};
    font-weight: ${font.weight.bold};
    line-height: 44px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Display2_SemiBold = styled.span<Props>`
    font-size: ${font.size[32]};
    font-weight: ${font.weight.semiBold};
    line-height: 44px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Title1_Bold = styled.span<Props>`
    font-size: ${font.size[28]};
    font-weight: ${font.weight.bold};
    line-height: 38px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Title1_SemiBold = styled.span<Props>`
    font-size: ${font.size[28]};
    font-weight: ${font.weight.semiBold};
    line-height: 38px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Title1_Medium = styled.span<Props>`
    font-size: ${font.size[28]};
    font-weight: ${font.weight.medium};
    line-height: 38px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Title1_Regular = styled.span<Props>`
    font-size: ${font.size[28]};
    font-weight: ${font.weight.regular};
    line-height: 38px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Title2_Bold = styled.span<Props>`
    font-size: ${font.size[24]};
    font-weight: ${font.weight.bold};
    line-height: 32px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Title2_SemiBold = styled.span<Props>`
    font-size: ${font.size[24]};
    font-weight: ${font.weight.semiBold};
    line-height: 32px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Title2_Medium = styled.span<Props>`
    font-size: ${font.size[24]};
    font-weight: ${font.weight.medium};
    line-height: 32px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Title2_Regular = styled.span<Props>`
    font-size: ${font.size[24]};
    font-weight: ${font.weight.regular};
    line-height: 32px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body1_Bold = styled.span<Props>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.bold};
    line-height: 28px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body1_SemiBold = styled.span<Props>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.semiBold};
    line-height: 28px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body1_Medium = styled.span<Props>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    line-height: 28px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body1_Medium_button = styled.button`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    line-height: 28px;
`;

export const Body1_Medium_input = styled.input<Props>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    line-height: 28px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body1_Regular_Button = styled.button<Props>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.medium};
    line-height: 28px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body1_Regular = styled.span<Props>`
    font-size: ${font.size[20]};
    font-weight: ${font.weight.regular};
    line-height: 28px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body2_Bold = styled.span<Props>`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.bold};
    line-height: 26px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Body2_Bold_Button = styled.button<Props>`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.bold};
    line-height: 26px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body2_SemiBold = styled.span<Props>`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.semiBold};
    line-height: 26px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body2_Medium = styled.span<Props>`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.medium};
    line-height: 26px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body2_Regular = styled.span<Props>`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.regular};
    line-height: 26px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body3_Bold = styled.span<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.bold};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body3_SemiBold = styled.span<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.semiBold};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body3_Medium = styled.span<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body3_Medium_Section = styled.section<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_neutral};
`;

export const Body3_Medium_Button = styled.button<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body3_Medium_TextArea = styled.textarea<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Body3_Medium_Input = styled.input<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body3_Regular = styled.span<Props>`
    font-size: ${font.size[16]};
    font-weight: ${font.weight.regular};
    line-height: 24px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body4_Bold = styled.span<Props>`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.bold};
    line-height: 22px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body4_Bold_Button = styled.button<Props>`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.bold};
    line-height: 22px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body4_SemiBold = styled.span<Props>`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.semiBold};
    line-height: 22px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body4_Medium = styled.span<Props>`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
    line-height: 22px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body4_Medium_Button = styled.button<Props>`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.medium};
    line-height: 22px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body4_Regular = styled.span<Props>`
    font-size: ${font.size[15]};
    font-weight: ${font.weight.regular};
    line-height: 22px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body5_Bold = styled.span<Props>`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.bold};
    line-height: 20px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body5SemiBold = styled.span<Props>`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.semiBold};
    line-height: 20px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body5Medium = styled.span<Props>`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.medium};
    line-height: 20px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Body5_Medium_button = styled.button<Props>`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.medium};
    line-height: 20px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body5Regular = styled.span<Props>`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    line-height: 20px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Caption1SemiBold = styled.span<Props>`
    font-size: ${font.size[13]};
    font-weight: ${font.weight.semiBold};
    line-height: 18px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;
export const Caption1Medium = styled.span<Props>`
    font-size: ${font.size[13]};
    font-weight: ${font.weight.medium};
    line-height: 18px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Caption1Regular = styled.span<Props>`
    font-size: ${font.size[13]};
    font-weight: ${font.weight.regular};
    line-height: 18px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Caption2_SemiBold = styled.span<Props>`
    font-size: ${font.size[12]};
    font-weight: ${font.weight.semiBold};
    line-height: 16px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Caption2_Medium = styled.span<Props>`
    font-size: ${font.size[12]};
    font-weight: ${font.weight.medium};
    line-height: 16px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Caption2_Regular = styled.span<Props>`
    font-size: ${font.size[12]};
    font-weight: ${font.weight.regular};
    line-height: 16px;
    color: ${(props) => props.$color || TextColor.color_text_normal};
`;

export const Body5_Regular_Css = css`
    font-size: ${font.size[14]};
    font-weight: ${font.weight.regular};
    line-height: 20px;
    color: ${TextColor.color_text_normal};
`;
