import usePaginationStateHooks from 'hooks/CustomHooks/usePagenationStateHooks';
import { useEffect, useState } from 'react';
import { useLoginStore } from 'store/useLoginDataStore';
import { BorderColor } from 'styles/Global/colorSemantic';
import { PageTitle, RowDivider } from 'styles/Global/commonStyles';
import { DiagnosisLayout, FilterWrapper, HospitalPageLabel } from 'styles/Global/hospitalStyles';
import { useLoadingStore } from 'store/useLoadingStore';
import { AdminManageHospitalConstants } from 'constants/Admin/AdminManageHospitalPageConstants';
import { adminManageHospitalPageApi } from 'api/Admin/adminManageHospitalPageApi';
import AdminManageHospitalPageFilter from './AdminManageHospitalPageFilter';
import AdminManageHospitalPagination from './AdminManageHospitalPagination';
import { GetHospitalListApiType } from 'models/Admin/AdminManageHospitalPageTypes';
import { AdminInnerLayout } from 'styles/Global/Admin/adminCommonStyles';

const AdminManageHospitalPage = () => {
    const { loginData } = useLoginStore();
    const { setLoading } = useLoadingStore();

    const [hospitalData, setHospitalData] = useState<GetHospitalListApiType[]>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);

    const { setPage, currentPage, pageGroup, handlePageGroup } = usePaginationStateHooks(hospitalData);

    useEffect(() => {
        adminManageHospitalApiCall();
    }, [currentPage]);

    // user list api call
    const adminManageHospitalApiCall = async () => {
        setLoading(true);

        try {
            const response = await adminManageHospitalPageApi.getHospitalList(currentPage, searchValue, loginData.token);
            if (response.data) {
                setHospitalData(response.data.list);
                setTotalPages(response.data.pagination.totalPages);
                setTotalCount(response.data.pagination.totalCount);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    // reset Button function
    const handleResetButton = async () => {
        const response = await adminManageHospitalPageApi.getHospitalList(currentPage, null, loginData.token);

        setSearchValue('');
        setHospitalData(response.data.list);
        setPage(1);
        handlePageGroup(0);
    };

    return (
        <DiagnosisLayout>
            <AdminInnerLayout>
                <HospitalPageLabel onClick={() => window.location.reload()}>{AdminManageHospitalConstants.pageCommonConstants.title}</HospitalPageLabel>
                <PageTitle $marginbottom='24px'>{AdminManageHospitalConstants.pageCommonConstants.title}</PageTitle>
                <RowDivider $backgroundcolor={BorderColor.color_border_neutral} $marginbottom='16px' />
                <FilterWrapper>
                    <AdminManageHospitalPageFilter diagnosisNumber={totalCount} onChange={setSearchValue} searchValue={searchValue} handleSearchFilter={adminManageHospitalApiCall} />
                </FilterWrapper>
                <AdminManageHospitalPagination
                    initNumber={hospitalData?.length}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageGroup={pageGroup}
                    handlePageGroup={handlePageGroup}
                    handlePageChange={setPage}
                    currentData={hospitalData}
                    totalCount={totalCount}
                    handleResetButton={handleResetButton}
                />
            </AdminInnerLayout>
        </DiagnosisLayout>
    );
};

export default AdminManageHospitalPage;
