import { HeaderWrapper, Layout, TableCell, TableLayout } from 'components/Hospital/PaginationForms';
import { adminManageUserListHeader, adminManageUserListWidth } from 'constants/pagination';
import { getWidthRation } from 'functions/calculateWdith';
import { AdminManageUserJoinPaginationProps } from 'models/Admin/AdminPaginationTypes';
import { NothingDataInManagingPatientsPagination } from 'pages/Hospital/AddDiagnosisPage/NothingDataInPagination';
import CustomedPagination from 'pages/Hospital/DiagnosisListPage/CustomedPagination';
import AdminManageUserJoinPaginationTable from './AdminManageUserJoinPaginationTable';

const AdminManageUserJoinPagination = ({
    currentPage,
    totalPages,
    pageGroup,
    handlePageGroup,
    handlePageChange,
    currentData,
    initNumber,
    totalCount,
    clickUserManageButton,
    handleResetButton,
}: AdminManageUserJoinPaginationProps) => {
    return (
        <Layout>
            <TableLayout>
                <HeaderWrapper>
                    {adminManageUserListHeader.map((name, idx) => {
                        return (
                            <TableCell key={idx} $width={getWidthRation(adminManageUserListWidth[idx])} $type='header' $justifyContent='space-between' cellType={name.type}>
                                {name.title}
                            </TableCell>
                        );
                    })}
                </HeaderWrapper>
                {currentData?.length !== 0 ? (
                    <AdminManageUserJoinPaginationTable currentPage={currentPage} totalCount={totalCount} currentData={currentData} clickUserManageButton={clickUserManageButton} />
                ) : (
                    <NothingDataInManagingPatientsPagination initNumber={initNumber} handleResetButton={handleResetButton} />
                )}
            </TableLayout>
            {currentData && totalPages !== 0 && (
                <CustomedPagination currentPage={currentPage} onChangeCurrentPage={handlePageChange} onChangeSectionIdx={handlePageGroup} sectionIndex={pageGroup} totalPages={totalPages} />
            )}
        </Layout>
    );
};

export default AdminManageUserJoinPagination;
