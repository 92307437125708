import { common } from '@mui/material/colors';
import { commonText } from 'constants/commonConstants';

export const adminManageHospitalFilterList = [commonText.hospitalName];

export namespace AdminManageHospitalConstants {
    export const pageCommonConstants = {
        title: '등록 병원 관리',
    };

    export const paginationHeaderConstants = {
        numbering: commonText.registrationNumber,
        hospitalName: commonText.hospitalName,
        phoneNumber: commonText.tel,
        registrationTimestamp: commonText.registrationDay,
        userCount: '등록 계정 수',
        doctorCount: commonText.doctor,
        patientCount: '등록 환자 수',
        diagnosisCount: '전체 진료 수',
        completedAssessmentCount: '완료 검사 수',
        totalAmount: '정산 누적 금액',
    };
}
