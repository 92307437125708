import ArrowRightSlim from 'assets/Icons/Arrow/ArrowRightSlim';
import { HomeCellButton, HomeCellTitle, HomeCellTitleContainer } from 'components/Hospital/HomeForms';
import { commonText } from 'constants/commonConstants';
import { pagePaths } from 'constants/path';
import { TodayDiagnosisListProps } from 'models/Hospital/homeTypes';
import moment from 'moment';
import TodayDiagnosisSlider from 'pages/Hospital/TodayDiagnosisPage/TodayDiagnosisSlider';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TextColor } from 'styles/Global/colorSemantic';
import { Body1_SemiBold } from 'styles/Global/typography';

const TodayDiagnosisListSlide = ({ slideData }: TodayDiagnosisListProps) => {
    const navigate = useNavigate();

    return (
        <>
            <HomeCellTitleContainer>
                <HomeCellTitle>
                    {moment().format('YYYY년 M월 D일 dddd')} <Text> {commonText.diagnosis}</Text>
                </HomeCellTitle>
                <HomeCellButton onClick={() => navigate(pagePaths.todayDiagnosis)}>
                    {commonText.showAll} <ArrowRightSlim />
                </HomeCellButton>
            </HomeCellTitleContainer>
            <TodayDiagnosisSlider data={slideData || []} type='home' />
        </>
    );
};

export default TodayDiagnosisListSlide;

const Text = styled(Body1_SemiBold)`
    color: ${TextColor.color_text_light};
`;
