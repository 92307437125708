import { PaginationIconWrapper, PaginationPageButton, PaginationPageButtonWrapper } from 'styles/Global/buttonStyles';
import NextPageIcon from 'assets/Icons/Pagination/NextPageIcon';
import NextSectionJumpIcon from 'assets/Icons/Pagination/NextSectionJumpIcon';
import PrevPageIcon from 'assets/Icons/Pagination/PrevPageIcon';
import PrevSectionJumpIcon from 'assets/Icons/Pagination/PrevSectionJumpIcon';
import { DiagnosisListpaginationFooterProps } from 'models/Hospital/paginationTypes';
import styled from 'styled-components';

const CustomedPagination = ({ currentPage, onChangeCurrentPage: handlePageChange, onChangeSectionIdx: handlePageGroup, sectionIndex, totalPages }: DiagnosisListpaginationFooterProps) => {
    return (
        <PaginationLayout>
            <ArrowButtonContainer>
                {totalPages && totalPages > 10 && (
                    <PaginationIconWrapper
                        disabled={currentPage <= 10}
                        onClick={() => {
                            const newPage = Math.floor((currentPage - 1) / 10 - 1) * 10 + 1;
                            handlePageChange(newPage);
                            handlePageGroup(Math.floor((newPage - 1) / 10));
                        }}
                    >
                        <PrevSectionJumpIcon />
                    </PaginationIconWrapper>
                )}
                <PaginationIconWrapper
                    disabled={currentPage === 1}
                    onClick={() => {
                        handlePageChange(currentPage - 1);
                        if (currentPage % 10 === 1) {
                            handlePageGroup(sectionIndex - 1);
                        }
                    }}
                >
                    <PrevPageIcon />
                </PaginationIconWrapper>
            </ArrowButtonContainer>
            <PaginationPageButtonWrapper>
                {Array.from({ length: totalPages || 0 }, (_, index) => index + 1)
                    .slice(sectionIndex * 10, sectionIndex * 10 + 10)
                    .map((page) => (
                        <PaginationPageButton key={page} onClick={() => handlePageChange(page)} selected={page === currentPage}>
                            {page}
                        </PaginationPageButton>
                    ))}
            </PaginationPageButtonWrapper>
            <ArrowButtonContainer>
                <PaginationIconWrapper
                    disabled={currentPage === totalPages}
                    onClick={() => {
                        handlePageChange(currentPage + 1);
                        if (currentPage % 10 === 0) {
                            handlePageGroup(sectionIndex + 1);
                        }
                    }}
                >
                    <NextPageIcon />
                </PaginationIconWrapper>
                {totalPages && totalPages > 10 && (
                    <PaginationIconWrapper
                        disabled={totalPages && (sectionIndex + 1) * 10 >= totalPages ? true : false}
                        onClick={() => {
                            const newPage = Math.floor((currentPage - 1) / 10 + 1) * 10 + 1;
                            handlePageChange(newPage);
                            handlePageGroup(Math.floor((newPage - 1) / 10));
                        }}
                    >
                        <NextSectionJumpIcon />
                    </PaginationIconWrapper>
                )}
            </ArrowButtonContainer>
        </PaginationLayout>
    );
};

export default CustomedPagination;

const ArrowButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PaginationLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 36px;
`;
