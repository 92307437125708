import React from 'react';
import { ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalTitle } from './ModalForms';
import BasicButton from 'components/Buttons/BasicButton';
import styled from 'styled-components';
import { changeMobileForm } from 'functions/changeMobileForm';
import { calculationTimeStamp } from 'functions/calculateTime';
import moment from 'moment';
import { AgeAndBirthDateWrapper, CloseButtonWrapper, TextInDetailPatientPageModal } from 'styles/Modal/modalStyle';
import { DeletePatientModalProps } from 'models/Common/modalTypes';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { RoundBadgeS } from 'components/Common/Badge';
import { Radius } from 'styles/Global/objectStyles';
import { Body4_Medium, Body5Regular } from 'styles/Global/typography';
import ModalDeleteIcon from 'assets/Icons/DeleteIcon/CloseDialog';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';

const DeletePatientModal = ({ toggle, userInfo, birthDate, age, handleDeletePatient }: DeletePatientModalProps) => {
    return (
        <ModalBackground>
            <ModalLayout $width='520px'>
                <ModalInnerLayout>
                    <ModalTitleAndContentContainer>
                        <ModalTitle>해당 환자를 삭제할까요?</ModalTitle>
                        <ModalContent>삭제하시면, 해당환자의 정보부터 진료 내역까지 모든 데이터를 더 이상 볼 수 없습니다.</ModalContent>
                    </ModalTitleAndContentContainer>
                    <PatientInfoLayout>
                        <PatientInfoHeaderContainer>
                            <RoundBadgeS>{userInfo.regNum}</RoundBadgeS>
                            <InfoWrapper>
                                <TextInDetailPatientPageModal>{userInfo.name}</TextInDetailPatientPageModal>
                                <TextInDetailPatientPageModal>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>{userInfo.gender === 'male' ? <GenderMan /> : <GenderWoman />}</span>
                                </TextInDetailPatientPageModal>
                                <AgeAndBirthDateWrapper>
                                    <span>{age}</span>
                                    <span>({birthDate})</span>
                                </AgeAndBirthDateWrapper>
                            </InfoWrapper>
                        </PatientInfoHeaderContainer>
                        <PatientInfoBodyContainer>
                            <PatientInfoCellsWrapper>
                                <Label>연락처</Label>
                                <Value>{changeMobileForm(userInfo.mobile)}</Value>
                            </PatientInfoCellsWrapper>
                            <PatientInfoCellsWrapper>
                                <Label>마지막 진료일시</Label>
                                <Value>{calculationTimeStamp(userInfo.lastDiagnosisTimestamp)}</Value>
                            </PatientInfoCellsWrapper>
                            <PatientInfoCellsWrapper>
                                <Label>총 진료 건수</Label>
                                <Value>{`${userInfo.diagnosisIdList.length}건의 진료`}</Value>
                            </PatientInfoCellsWrapper>
                            <PatientInfoCellsWrapper>
                                <Label>환자 등록일</Label>
                                <Value>{moment(userInfo.timestamp).format('YYYY/MM/DD')}</Value>
                            </PatientInfoCellsWrapper>
                        </PatientInfoBodyContainer>
                    </PatientInfoLayout>
                </ModalInnerLayout>
                <ModalFooter>
                    <BasicButton $width='160px' $height='48px' onClick={toggle} $type='anotherFill'>
                        취소
                    </BasicButton>
                    <BasicButton $width='160px' $height='48px' $type='fill' onClick={() => handleDeletePatient(userInfo._id)}>
                        삭제하기
                    </BasicButton>
                </ModalFooter>
                <CloseButtonWrapper onClick={toggle}>
                    <ModalDeleteIcon />
                </CloseButtonWrapper>
            </ModalLayout>
        </ModalBackground>
    );
};

export default DeletePatientModal;

const ModalInnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 16px;
`;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
`;

const PatientInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    border: 1px solid ${BorderColor.color_border_neutral};
    border-radius: 4px;
    gap: 14px;
`;

const PatientInfoHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;

const PatientInfoBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 18px 20px;
    background-color: ${BackgroundColor.color_background_neutral};
    border-radius: ${Radius.radius_4};
    gap: 12px;
`;

const PatientInfoCellsWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 22px;
    gap: 12px;
`;

const Label = styled(Body5Regular)`
    width: 100px;
    color: ${TextColor.color_text_light};
`;

const Value = styled(Body4_Medium)`
    width: calc(100% - 84px);
    color: ${TextColor.color_text_normal};
`;

const InfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
`;
