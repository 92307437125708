import axios from 'axios';
import { apiPaths } from 'constants/path';
import { tokenHeader } from 'functions/apiFunctions';
import { DiagnosisApiResponse, VoucherAddDiagnosisRqData } from 'models/Hospital/hospitalApiTypes';

class PreciousDiagnoisisApi {
    async addDiagnosis(rqData: VoucherAddDiagnosisRqData, token: string): Promise<DiagnosisApiResponse> {
        try {
            const response = await axios.post(apiPaths.addDiagnosis, rqData, {
                headers: tokenHeader(token),
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export const preciousDiagnosisApi = new PreciousDiagnoisisApi();
