import { viewDetailDiagnosisPageApi } from 'api/Hospital/viewDetailDiagnosisPageApi';
import { assessmentApi } from 'api/Tablet/assessmentApi';
import { diagnosisApi } from 'api/Tablet/diagnosisApi';
import CancelBtn from 'assets/Icons/ETC/Button/CancelBtn';
import React, { MouseEvent, MouseEventHandler } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAssessmentStore } from 'store/useAssessmentDataStore';
import { useInpsytParamDataStore } from 'store/useInpsytParamDataStore';
import { useLoginStore } from 'store/useLoginDataStore';
import { usePatientStore } from 'store/usePatientLoginDataStore';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import _ from 'lodash';
import { BackgroundColor, BorderColor, PrimaryColor, Static, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body1_Regular_Button, Body2_Regular, Body2_SemiBold, Body5Medium, Title1_Bold } from 'styles/Global/typography';
import { tabletPaths } from 'constants/path';
import { useLeaveModalStateStore } from 'store/useLeaveModalStateStore';
import { useTabletModalStatus } from 'store/useTabletModalStatus';

interface Props {
    setlogoutShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowSettingsModal: React.Dispatch<React.SetStateAction<boolean>>;
    view: string;
}

const LogoutModal = ({ setlogoutShowModal, setShowSettingsModal, view }: Props) => {
    const { setModalStatus } = useTabletModalStatus();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { patientData, resetPatient } = usePatientStore();
    const { assessmentData, resetAssessment } = useAssessmentStore();
    const { inpsytParamData, resetInpsytParamData } = useInpsytParamDataStore();
    const { loginData } = useLoginStore();

    const handleCancelClick = () => {
        setModalStatus(false);
    };

    const handleLogoutClick = (e: MouseEvent<HTMLButtonElement>) => {
        resetDataFn();
        navigate(tabletPaths.managerLogin);
    };

    const resetDataFn = () => {
        resetPatient();
        resetAssessment();
        resetInpsytParamData();
        setModalStatus(false);
        setlogoutShowModal(false);
        setShowSettingsModal(false);
    };

    const movePatientLoginpage = () => {
        resetDataFn();
        navigate(tabletPaths.login);
    };

    const handleChangeStatus = () => {
        if (pathname !== tabletPaths.information) {
            changeAssessmentStatus(0);
        } else {
            movePatientLoginpage();
        }
    };

    const changeAssessmentStatus = async (status: number) => {
        try {
            const rqData = {
                diagnosisId: patientData.diagnosisId!,
                assessmentCode: assessmentData.assessmentCodeList[assessmentData.assessmentCnt],
                status,
            };
            const res = await assessmentApi.changeAssessmentStatus(rqData, loginData.token);
            if (res.status === 'ok') {
                if (assessmentData.assessmentCnt === 0) {
                    changeDiagnosisStatus(1);
                } else {
                    changeDiagnosisStatus(3);
                }
            }
        } catch (error) {}
    };

    const changeDiagnosisStatus = async (status: number) => {
        try {
            const rqData = {
                _id: patientData.diagnosisId!,
                status,
            };
            const res = await viewDetailDiagnosisPageApi.changeDiagnosisStatus(rqData, loginData.token);
            if (res.status === 'ok') {
                resetDataFn();
                if (status === 1) {
                    if (view === 'login') {
                        navigate(tabletPaths.login);
                    }
                    if (view === 'manager-login') {
                        navigate(tabletPaths.managerLogin, { state: tabletPaths.managerLogin });
                    }
                } else {
                    navigate(tabletPaths.userSurvey, { state: { path: view === 'login' ? tabletPaths.login : tabletPaths.managerLogin, step: view } });
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Wrapper>
            <ModalLayout>
                <CancelBtnLayout onClick={handleCancelClick}>
                    <CancelBtn />
                </CancelBtnLayout>
                <ModelInfoLayout>
                    {tabletPaths.login === pathname && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>지금 로그아웃 할까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 로그아웃 하시면, 로그인 페이지로 이동합니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                    {tabletPaths.information === pathname && view === 'login' && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>환자 변경을 위해 나갈까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 나가시면, 검사 준비 페이지로 이동합니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                    {tabletPaths.information === pathname && view === 'manager-login' && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>지금 로그아웃 할까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 로그아웃 하시면, 로그인 페이지로 이동합니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                    {assessmentData.assessmentCnt === 0 && tabletPaths.startExamination === pathname && view === 'login' && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>검사를 중단하고 나갈까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 나가시면, 진행 중인 검사 문항은 저장되지 않습니다.</p>
                                    <p>검사 전 상태로 되돌아갑니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                    {assessmentData.assessmentCnt === 0 && tabletPaths.startExamination === pathname && view === 'manager-login' && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>검사를 중단하고 로그아웃 할까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 로그아웃 하시면, 진행 중인 검사 문항은 저장되지 않습니다.</p>
                                    <p>검사 전 상태로 되돌아갑니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                    {assessmentData.assessmentCnt !== 0 && tabletPaths.startExamination === pathname && view === 'login' && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>검사를 끝내고 나갈까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 나가시면, 진행 중인 검사 문항은 저장되지 않습니다.</p>
                                    <p>이미 완료된 검사만 결과 확인이 가능합니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                    {assessmentData.assessmentCnt !== 0 && tabletPaths.startExamination === pathname && view === 'manager-login' && (
                        <>
                            <ModelTextLayout>
                                <ModalTitle>검사를 끝내고 로그아웃 할까요?</ModalTitle>
                                <ModalDescription>
                                    <p>지금 로그아웃 하시면, 진행 중인 검사 문항은 저장되지 않습니다.</p>
                                    <p>이미 완료된 검사만 결과 확인이 가능합니다.</p>
                                </ModalDescription>
                            </ModelTextLayout>
                        </>
                    )}
                </ModelInfoLayout>
                {tabletPaths.login === pathname && (
                    <ButtonLayout>
                        <CancelButton onClick={handleCancelClick}>취소</CancelButton>
                        <SubmitButton onClick={handleLogoutClick}>로그아웃</SubmitButton>
                    </ButtonLayout>
                )}
                {tabletPaths.information === pathname && view === 'login' && (
                    <>
                        <ButtonLayout>
                            <CancelButton onClick={handleCancelClick}>머무르기</CancelButton>
                            <SubmitButton onClick={handleChangeStatus}>환자 변경하러 나가기</SubmitButton>
                        </ButtonLayout>
                    </>
                )}
                {tabletPaths.information === pathname && view === 'manager-login' && (
                    <ButtonLayout>
                        <CancelButton onClick={handleCancelClick}>취소</CancelButton>
                        <SubmitButton onClick={handleLogoutClick}>로그아웃</SubmitButton>
                    </ButtonLayout>
                )}
                {assessmentData.assessmentCnt === 0 && tabletPaths.startExamination === pathname && view === 'login' && (
                    <>
                        <ButtonLayout>
                            <CancelButton onClick={handleCancelClick}>머무르기</CancelButton>
                            <SubmitButton onClick={handleChangeStatus}>환자 변경하러 나가기</SubmitButton>
                        </ButtonLayout>
                    </>
                )}
                {assessmentData.assessmentCnt === 0 && tabletPaths.startExamination === pathname && view === 'manager-login' && (
                    <ButtonLayout>
                        <CancelButton onClick={handleCancelClick}>취소</CancelButton>
                        <SubmitButton onClick={handleChangeStatus}>로그아웃</SubmitButton>
                    </ButtonLayout>
                )}
                {assessmentData.assessmentCnt !== 0 && tabletPaths.startExamination === pathname && view === 'login' && (
                    <>
                        <ButtonLayout>
                            <CancelButton onClick={handleCancelClick}>머무르기</CancelButton>
                            <SubmitButton onClick={handleChangeStatus}>환자 변경하러 나가기</SubmitButton>
                        </ButtonLayout>
                    </>
                )}
                {assessmentData.assessmentCnt !== 0 && tabletPaths.startExamination === pathname && view === 'manager-login' && (
                    <ButtonLayout>
                        <CancelButton onClick={handleCancelClick}>취소</CancelButton>
                        <SubmitButton onClick={handleChangeStatus}>로그아웃</SubmitButton>
                    </ButtonLayout>
                )}
            </ModalLayout>
        </Wrapper>
    );
};

export default LogoutModal;

const Wrapper = styled.div`
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const ModalLayout = styled.div`
    position: relative;
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    padding: 48px 36px 32px 36px;
    background-color: ${Static.color_static_white};
    border-radius: ${Radius.radius_16};
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
`;

const CancelBtnLayout = styled.button`
    position: absolute;
    right: -16px;
    top: -16px;
`;

const ModelInfoLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`;

const ModelTextLayout = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 12px;
`;

const ModalTitle = styled(Title1_Bold)`
    text-align: center;
`;

const ModalDescription = styled(Body2_Regular)`
    text-align: center;
`;

const ButtonLayout = styled.div`
    display: flex;
    column-gap: 8px;
`;

const SubmitButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${Static.color_static_white};
    background-color: ${BackgroundColor.color_background_primary};

    &:active {
        background-color: ${BackgroundColor.color_background_primary_heavy};
    }
`;

const CancelButton = styled(Body1_Regular_Button)`
    width: 260px;
    height: 68px;
    border-radius: ${Radius.radius_4};
    color: ${TextColor.color_text_normal};
    background-color: ${BackgroundColor.color_background_strong};
`;

const AssessmentLayout = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_neutral};
    padding: 12px 16px;
`;

const AssessmentItem = styled.div`
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;

    &:nth-child(1) {
        border-bottom: ${Border.border_1} solid ${BorderColor.color_border_weak};
        padding-bottom: 8px;
    }

    &:not(:first-child) {
        padding-top: 8px;
    }
`;

const AssessmentCnt = styled(Body5Medium)`
    width: 28px;
    height: 28px;
    border-radius: ${Radius.radius_8};
    border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${TextColor.color_text_normal};
`;

const SpanLayout = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    align-items: center;
`;

const AssessmentGroupTxt = styled(Body2_SemiBold)`
    color: ${TextColor.color_text_normal};
`;

const AssessmentStatusTag = styled(Body5Medium)`
    border-radius: ${Radius.radius_circle};
    background-color: ${BackgroundColor.color_background_primary_strong};
    padding: 4px 10px;
    color: ${PrimaryColor.color_primary_normal};
`;
