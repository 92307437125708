import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import moment from 'moment';
import 'moment/locale/ko';
import { TimeButtonProps, TimeInCalendarProps } from 'models/Common/calendarTypes';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const TimeInCalendar = ({ setSelectedTime, selectedTime, selectedDate }: TimeInCalendarProps) => {
    const [timeList, setTimeList] = useState<string[]>([]);

    useEffect(() => {
        moment.locale('ko');
        const now = moment();
        const selectedMoment = moment(selectedDate);
        const times = [];

        if (selectedMoment.isSame(now, 'day')) {
            const roundedNow = now.minute() < 30 ? now.startOf('hour').add(30, 'minutes') : now.startOf('hour').add(1, 'hours');

            while (roundedNow.isBefore(moment().endOf('day'))) {
                times.push(roundedNow.format('A h:mm'));
                roundedNow.add(30, 'minutes');
            }

            setSelectedTime && setSelectedTime(times[0]);
            // setSelectedTime && setSelectedTime(selectedDate || '');
        } else {
            let startOfDay = moment(selectedDate).startOf('day').add(30, 'minutes');
            let endOfDay = moment(selectedDate).endOf('day');

            while (startOfDay.isBefore(endOfDay)) {
                times.push(startOfDay.format('A h:mm'));
                startOfDay = startOfDay.add(30, 'minutes');
            }
            setSelectedTime && setSelectedTime(times[0]);
        }

        setTimeList(times);
    }, [selectedDate]);

    return (
        <>
            {timeList.map((time, idx) => (
                <TimeButton key={idx} onClick={() => setSelectedTime && setSelectedTime(time)} $selected={time === selectedTime}>
                    {time}
                </TimeButton>
            ))}
        </>
    );
};

export default TimeInCalendar;

const TimeButton = styled.button<TimeButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    min-height: 40px;
    font-size: ${font.size[14]};
    border: 1px solid ${palette.testGray};
    border-radius: 4px;
    margin-left: 5px;
    ${(props) => props.$selected && `background-color: ${PrimaryColor.color_primary_normal}; color: ${palette.white}`};
    &:hover {
        background-color: ${(props) => !props.$selected && palette.testGray};
    }
`;
