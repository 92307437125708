import BasicButton from 'components/Buttons/BasicButton';
import DropdownButton from 'components/Buttons/DropdownButton';
import { AddDiagnosisPageCalendar } from 'components/Common/Calendar';
import _, { isEmpty, update } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BackgroundColor } from 'styles/Global/colorSemantic';
import { SelectDateType } from '../CounselReservationPage';

type PickerType = {
    selectedList: SelectDateType[];
    selectButtonIndex: number;
    idx: number;
    handleShowCalendar: (index: number) => void;
    handleSelectedCalendarList: (date: SelectDateType[]) => void;
};

const DatePickerAccordion = ({ selectButtonIndex, selectedList, idx, handleShowCalendar, handleSelectedCalendarList }: PickerType) => {
    const reserveTimeList = ['0900', '0930', '1000', '1030', '1100', '1130', '1200', '1230', '1300', '1330', '1400', '1430', '1500', '1530', '1600', '1630', '1700', '1730'];
    const [selectedDateText, setSelectedDateText] = useState('일시 선택');

    useEffect(() => {
        setSelectedDateText(getSelectedReserveTime(idx));
    }, [selectedList, idx]);

    function getNextDayDate() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        return moment(tomorrow).format('YYYYMMDD');
    }

    const getSelectedReserveTime = (idx: number) => {
        const selectedDate = selectedList[idx]?.date ?? '';
        console.log('@!!-->> selectedDate 1111:', selectedDate);
        const isSelectedTime = !isEmpty(selectedDate) && selectedDate.substring(8, 12) !== '0000';

        return isSelectedTime ? moment(selectedDate, 'YYYYMMDDHHmm').format('YYYY/MM/DD A HH:mm') : '일시 선택';
    };

    const isTimeSlotOccupied = (date: string, time: string) => {
        return selectedList?.some((item) => item?.date === date + time) ?? false;
    };

    const handleReserveTimeSelect = (time: string) => {
        const newDate = selectedList[idx]?.date.substring(0, 8);
        const updatedDates = [...selectedList];
        updatedDates[selectButtonIndex] = { id: selectButtonIndex, date: newDate + time };
        handleSelectedCalendarList(updatedDates);
    };

    const setCalendarDate = (idx: number) => {
        const selectedDateText = getSelectedReserveTime(idx);
        return selectedDateText === '일시 선택' ? getNextDayDate() : selectedDateText;
    };

    return (
        <>
            <DropdownButton
                onClick={() => {
                    if (selectButtonIndex === idx) {
                        handleShowCalendar(-1);
                        return;
                    }
                    handleShowCalendar(idx);
                }}
                disabled={false}
                $width='132px'
            >
                {selectedDateText}
            </DropdownButton>
            {selectButtonIndex === idx && (
                <CalenderWrapper>
                    <AddDiagnosisPageCalendar
                        setSelectedDate={(date) => {
                            const updatedDates = [...selectedList];
                            const formattedDate = moment(date).format('YYYYMMDD');
                            updatedDates[selectButtonIndex] = { id: selectButtonIndex, date: formattedDate + '0000' };
                            handleSelectedCalendarList(updatedDates);
                        }}
                        selectedDate={moment(setCalendarDate(idx), 'YYYYMMDD').format('YYYY/MM/DD')}
                    />
                    <ReserveTimeWrapper>
                        {_.chunk(reserveTimeList, 3).map((e, i) => {
                            return _.map(e, (value) => (
                                <BasicButton
                                    key={value}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleReserveTimeSelect(value);
                                    }}
                                    $width='93px'
                                    $height='48px'
                                    $type='outLine'
                                    disabled={isTimeSlotOccupied(selectedList[idx]?.date.substring(0, 8), value)}
                                >
                                    {moment(value, 'HHmm').format('A h:mm')}
                                </BasicButton>
                            ));
                        })}
                    </ReserveTimeWrapper>
                </CalenderWrapper>
            )}
        </>
    );
};

export default DatePickerAccordion;

const CalenderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px 16px;
    margin-top: 14px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const ReserveTimeWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
`;
