import { BadgeProps } from 'models/Common/badgeTypes';
import { ChildrenType } from 'models/Common/commonTypes';
import styled from 'styled-components';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Border, Radius } from 'styles/Global/objectStyles';
import { Body5Medium } from 'styles/Global/typography';

export const LabelSelectButton = ({ children, onClick }: BadgeProps) => {
    return (
        <LabelSelectButtonLayout onClick={onClick}>
            <LabelSelectButtonText>{children}</LabelSelectButtonText>
        </LabelSelectButtonLayout>
    );
};

const LabelSelectButtonLayout = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 0 8px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_strong};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        background-color: ${BackgroundColor.color_background_heavy};
    }
    &:focus {
        background-color: ${BackgroundColor.color_background_over};
    }
`;

const LabelSelectButtonText = styled(Body5Medium)`
    color: ${TextColor.color_text_light};
`;
