import styled from 'styled-components';
import { font, palette } from './globalStyles';
import { Body2_Bold_Button, Body3_Medium, Caption1Regular } from './typography';
import { BorderColor, PrimaryColor, TextColor } from './colorSemantic';
import { Border, Radius } from './objectStyles';
import { DiagnosisItemButtonProps, DiagnosisTapsContainerProps } from 'models/Common/buttonTypes';

export const CounselingPageLayout = styled.div`
    display: flex;
    justify-content: center;
    // align-items: center;
    width: 100%;
    min-height: calc(100vh - 60px);
    margin-bottom: 220px;
`;

export const CounslingInnerLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 440px;
    gap: 40px;
`;

export const DiagnosisLayout = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0 40px 80px 40px;
`;

export const DiagnosisInnerLayout = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    min-width: 880px;
    z-index: 0;
`;

export const FilterWrapper = styled(Body3_Medium)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    height: 40px;
    color: ${TextColor.color_text_light};
`;

export const HospitalPageLabel = styled(Caption1Regular)`
    display: inline-flex;
    flex-grow: 0;
    align-self: flex-start;
    margin-bottom: 8px;
    cursor: pointer;
    color: ${TextColor.color_text_neutral};
    &:hover {
        text-decoration-line: underline;
    }
`;

export const HomeNothingDataContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    width: 100%;
    height: 100%;
    padding: 20px 0 40px 0;
    color: ${TextColor.color_text_weak};
    font-weight: ${font.weight.medium};
    gap: 8px;
`;

export const TestCircle24 = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 99px;
    background-color: ${palette.gray};
`;

export const TwoBreadCrumbContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: ${font.size[13]};
    gap: 4px;
    & > :nth-child(2):hover {
        text-decoration: none;
    }
`;

export const InfoHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-top: 4px;
    font-size: ${font.size[18]};
    font-weight: ${font.weight.semiBold};
    color: ${palette.gray};
    margin-bottom: 8px;
`;

export const InfoHeaderButtonContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    gap: 8px;
    margin-bottom: 8px;
`;

export const DetailPageInfoLayout = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 28px 36px;
    border-radius: ${Radius.radius_4};
    border: 1px solid ${BorderColor.color_border_normal};
`;

export const DiagnosisTapsContainer = styled.div<DiagnosisTapsContainerProps>`
    display: flex;

    height: 52px;
    width: 100%;
    margin-top: ${(props) => props.$topMargin};
    margin-bottom: ${(props) => props.$bottomMargin};
    border-bottom: 1px solid ${BorderColor.color_border_neutral};
`;

export const TapsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
`;

export const DiagnosisItemButton = styled(Body2_Bold_Button)<DiagnosisItemButtonProps>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-top: auto;
    gap: 4px;
    border-bottom: ${(props) => (props.$idx === props.$selected ? `2px solid ${PrimaryColor.color_primary_strong}` : `2px solid #FFFFFF00`)};
    font-weight: ${(props) => (props.$idx === props.$selected ? font.weight.bold : font.weight.semiBold)};
    color: ${(props) => (props.$idx === props.$selected ? PrimaryColor.color_primary_strong : TextColor.color_text_weak)};
    padding: 12px 20px;
    &:disabled {
        color: ${palette.testGray};
        border: none;
        background-color: transparent;
    }
`;
