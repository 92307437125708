import { useState } from 'react';
import { FooterLayout, HeaderWrapper, Layout, TableCell, TableLayout } from 'components/Hospital/PaginationForms';
import { managingPatientsListHeader, managingPatientsListWidth } from 'constants/pagination';
import { NothingDataInManagingPatientsPagination } from '../AddDiagnosisPage/NothingDataInPagination';
import { ManagingPatientsPaginationProps } from 'models/Hospital/paginationTypes';
import ArrowLineUp from 'assets/Icons/Arrow/ArrowLineUp';
import CustomedPagination from '../DiagnosisListPage/CustomedPagination';
import ManagingPatientsPaginationTable from './ManagingPatientsPaginationTable';
import { OtherSortingButton } from 'styles/Global/buttonStyles';
import ArrowLineDown from 'assets/Icons/Arrow/ArrowLineDown';

const ManagingPatientsPagination = ({
    currentPage,
    totalPages,
    pageGroup,
    handlePageGroup,
    handlePageChange,
    currentData,
    initNumber,
    handleSorting,
    sortField,
    sortDirections,
    handleResetButton,
}: ManagingPatientsPaginationProps) => {
    const [selectHeader, setSelectHeader] = useState<string>('');

    return (
        <Layout>
            <TableLayout>
                <HeaderWrapper>
                    {managingPatientsListHeader.map((name, idx) => {
                        return (
                            <TableCell key={idx} $idx={idx} $width={managingPatientsListWidth[idx]} $type='header' $justifyContent='space-between' hover={setSelectHeader} cellType={name.type}>
                                {name.title}
                                {(sortField === name.type || selectHeader === name.type) &&
                                    name.type !== 'null' &&
                                    name.type !== 'mobile' &&
                                    name.type !== 'age' &&
                                    name.type !== 'allDiagnosisCount' && (
                                        <OtherSortingButton onClick={() => handleSorting(name.type)}>{sortDirections[name.type] ? <ArrowLineUp /> : <ArrowLineDown />}</OtherSortingButton>
                                    )}
                            </TableCell>
                        );
                    })}
                </HeaderWrapper>
                {!currentData || currentData?.length === 0 ? (
                    <NothingDataInManagingPatientsPagination initNumber={initNumber} handleResetButton={handleResetButton} />
                ) : (
                    <ManagingPatientsPaginationTable data={currentData} />
                )}
            </TableLayout>
            {currentData && totalPages !== 0 && (
                <CustomedPagination currentPage={currentPage} onChangeCurrentPage={handlePageChange} onChangeSectionIdx={handlePageGroup} sectionIndex={pageGroup} totalPages={totalPages} />
            )}
        </Layout>
    );
};

export default ManagingPatientsPagination;
