import { ButtonContainerType, PaginationPageButtonType } from 'models/Common/buttonTypes';
import styled from 'styled-components';
import { font, palette } from './globalStyles';
import { BackgroundColor, BorderColor, InverseColor, OpacityColor, PrimaryColor, TextColor } from './colorSemantic';
import { HospitalSideBarListWrapperProps, SideListWrapperProps } from 'models/Hospital/sideBarTypes';
import { Border, Radius } from './objectStyles';

export const ButtonContainer = styled.div<ButtonContainerType>`
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    margin-top: ${(props) => props.$margintop || '0px'};
`;

export const PaginationIconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    width: 37px;
    &:hover {
        border-radius: ${Radius.radius_circle};
        border: ${Border.border_1} solid ${BorderColor.color_border_normal};
    }
    &:active {
        border-radius: ${Radius.radius_circle};
        border: ${Border.border_1} solid ${BorderColor.color_border_strong};
    }
    &:disabled {
        opacity: 0.24;
        border: none;
        background: none;
    }
`;
export const PaginationPageButtonWrapper = styled.div`
    display: flex;
    height: 100%;
    gap: 2px;
`;

// ! 여기부터는 System Design -------------------------------------------------------------

export const GnbButton = styled.button<SideListWrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 76px;
    gap: 2px;
    color: ${InverseColor.color_inverse_white};
    font-size: ${font.size[12]};
    font-weight: ${font.weight.medium};
    line-height: 16px;
    ${(props) =>
        props.$idx !== props.selected &&
        `
        &:hover {
            opacity: 0.72;
            background-color: ${OpacityColor.color_opacity_normal_20};
        };
        &:active {
            opacity: 1;
            background-color: ${OpacityColor.color_opacity_normal_20};
        };
        &:focus {
            opacity: 1;
            background-color: ${OpacityColor.color_opacity_normal_20};
        };
        opacity: 0.5;
    `};
    ${(props) =>
        props.$idx === props.selected &&
        `
        &:hover {
            background-color: ${OpacityColor.color_opacity_normal_52};
        };
        &:active {
            background-color: ${OpacityColor.color_opacity_normal_52};
        };
        &:focus {
            background-color: ${OpacityColor.color_opacity_normal_52};
        };
        background-color: ${OpacityColor.color_opacity_normal_40};
    `};
`;

export const ProminentLNBButton = styled.button<HospitalSideBarListWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 48px;
    color: ${InverseColor.color_inverse_white};
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    padding: 0 16px 0 8px;
    border-radius: ${Radius.radius_circle};
    background-color: ${PrimaryColor.color_primary_normal};
    &:hover {
        background-color: ${PrimaryColor.color_primary_strong};
    }
    &:active {
        background-color: ${PrimaryColor.color_primary_strong};
    }
    &:focus {
        background-color: ${PrimaryColor.color_primary_strong};
    }
    margin-bottom: 12px;
`;

export const NormalLNBButton = styled.button<HospitalSideBarListWrapperProps>`
    display: flex;
    width: 200px;
    height: 48px;
    padding: 0 16px;
    align-items: center;
    gap: 8px;
    font-size: ${font.size[16]};
    border-radius: ${Radius.radius_circle};
    line-height: 24px;
    ${(props) =>
        props.$path === props.$listPath &&
        `
        background-color: ${OpacityColor.color_opacity_primary_light_12};
        color: ${PrimaryColor.color_primary_heavy};
        font-weight: ${font.weight.semiBold};
        &:hover {
            background-color: ${OpacityColor.color_opacity_primary_light_20};
        };
        &:active {
            background-color: ${OpacityColor.color_opacity_primary_light_20};
        };
        &:focus {
            background-color: ${OpacityColor.color_opacity_primary_light_20};
        };
    `};
    ${(props) =>
        props.$path !== props.$listPath &&
        `
        color: ${TextColor.color_text_neutral};
        font-weight: ${font.weight.medium};
        &:hover {
            background-color: ${OpacityColor.color_opacity_neutral_8};
        };
        &:active {
            background-color: ${OpacityColor.color_opacity_neutral_8};
        };
        &:focus {
            background-color: ${OpacityColor.color_opacity_neutral_8};
        };
    `}
`;

export const ListButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    align-items: center;
    padding: 0 6px 0 20px;
    cursor: pointer;
    &:hover {
        background-color: ${BackgroundColor.color_background_neutral};
    }
`;

export const PaginationPageButton = styled.button<PaginationPageButtonType>`
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.selected ? PrimaryColor.color_primary_normal : TextColor.color_text_light)};
    font-size: ${font.size[15]};
    font-weight: ${(props) => (props.selected ? font.weight.bold : font.weight.medium)};
    line-height: 22px;
    background-color: ${(props) => (props.selected ? BackgroundColor.color_background_primary_normal : 'transparent')};
    border: ${(props) => props.selected && `1px solid ${OpacityColor.color_opacity_primary_light_20}`};
    border-radius: ${Radius.radius_circle};
    &:hover {
        ${(props) => !props.selected && `border: ${Border.border_1} solid ${BorderColor.color_border_normal};`}
    }
    &:focus {
        ${(props) => !props.selected && `border: ${Border.border_1} solid ${BorderColor.color_border_strong};`}
    }
    &:disabled {
        color: ${TextColor.color_text_light};
    }
`;

export const OtherSortingButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: ${Radius.radius_4};
    border: ${Border.border_1} solid ${BorderColor.color_border_strong};
    background-color: ${BackgroundColor.color_background_white};
    &:hover {
        background-color: ${BackgroundColor.color_background_heavy};
    }
    &:focus {
        background-color: ${BackgroundColor.color_background_over};
    }
`;
