import Add from 'assets/Icons/AddIcons/Add';
import { pagePaths } from './path';
import LnbHospitalHomeIcon from 'assets/Icons/SideBarList/LnbHospitalHomeIcon';
import LnbDiagnosisTodayIcon from 'assets/Icons/SideBarList/LnbDiagnosisTodayIcon';
import LnbDiagnosisListIcon from 'assets/Icons/SideBarList/LnbDiagnosisListIcon';
import LnbPatientListIcon from 'assets/Icons/SideBarList/LnbPatientListIcon';
import LnbSettingHospitalIcon from 'assets/Icons/SideBarList/LnbSettingHospitalIcon';
import LnbAdminCalculateIcon from 'assets/Icons/SideBarList/LnbAdminCalculateIcon';
import LnbAdminApprovalIcon from 'assets/Icons/SideBarList/LnbAdminApprovalIcon';
import LnbAdminHospitalIcon from 'assets/Icons/SideBarList/LnbAdminHospitalIcon';

export const HospitalSideBarConstants = (currentPath: string) => {
    return [
        { icon: <Add />, name: '진료 추가', path: pagePaths.addDiagnosis },
        { icon: <LnbHospitalHomeIcon $active={currentPath === pagePaths.home} />, name: '우리병원 홈', path: pagePaths.home },
        { icon: <LnbDiagnosisTodayIcon $active={currentPath === pagePaths.todayDiagnosis} />, name: '오늘의 진료', path: pagePaths.todayDiagnosis },
        { icon: <LnbDiagnosisListIcon $active={currentPath === pagePaths.diagnosisList} />, name: '진료 목록', path: pagePaths.diagnosisList },
        { icon: <LnbPatientListIcon $active={currentPath === pagePaths.managingPatients} />, name: '등록 환자 관리', path: pagePaths.managingPatients },
        { icon: <LnbSettingHospitalIcon $active={currentPath === pagePaths.hospitalSetting} />, name: '병원 설정', path: pagePaths.hospitalSetting },
    ];
};

export const HospitalAdminSideBarConstants = (currentPath: string) => {
    return [
        { icon: <LnbDiagnosisListIcon $active={currentPath === pagePaths.adminDiagnosisList} />, name: '진료 목록', path: pagePaths.adminDiagnosisList },
        { icon: <LnbAdminHospitalIcon $active={currentPath === pagePaths.adminManageHospital} />, name: '등록 병원 관리', path: pagePaths.adminManageHospital },
        { icon: <LnbAdminApprovalIcon $active={currentPath === pagePaths.adminManageUserJoin} />, name: '가입 요청 관리', path: pagePaths.adminManageUserJoin },
        { icon: <LnbAdminCalculateIcon $active={currentPath === pagePaths.calculate} />, name: '병원 검사 정산', path: pagePaths.calculate },
        // { icon: <SettingHospital $active={currentPath === pagePaths.hospitalSetting} />, name: '병원 설정', path: pagePaths.hospitalSetting },
    ];
};

// export const sideBarConstants = ['상담검사', '칼로리 밸런스'];
export const sideBarConstants = ['상담검사'];
