import React from 'react';

const IconArrowLineRight = ({ color }: { color?: string }) => {
    return (
        <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.49995 11.2C6.05812 11.2 5.69995 11.5582 5.69995 12C5.69995 12.4418 6.05812 12.8 6.49995 12.8L16.5987 12.8L13.5278 15.9407C13.2189 16.2566 13.2246 16.7631 13.5405 17.072C13.8564 17.3809 14.3629 17.3752 14.6718 17.0593L19.0718 12.5593C19.3758 12.2484 19.3758 11.7516 19.0718 11.4407L14.6718 6.94072C14.3629 6.62481 13.8564 6.61912 13.5405 6.92801C13.2246 7.2369 13.2189 7.7434 13.5278 8.05931L16.5987 11.2L6.49995 11.2Z'
                fill={color ?? 'white'}
            />
        </svg>
    );
};

export default IconArrowLineRight;
