import { Dropdown, Button, Space, Divider, Input, Carousel, List, Card, TableColumnsType } from 'antd';
import _ from 'lodash';
import SettlementCard from 'components/Card/SettlementCard';
import { GetPaymentListApiResponse, GetQuarterSettlementResponse, PaymentModel } from 'models/Admin/calculatePageApiTypes';
import AntdTable from 'pages/Admin/AdminSettlement/Components/AntdTable';
import styled from 'styled-components';
import { BackgroundColor, BorderColor } from 'styles/Global/colorSemantic';
import { Body3_Medium } from 'styles/Global/typography';
import FilterButtons from './Components/FilterButtons';
import React from 'react';

type Props = {
    isShowCarosel?: boolean;
    calData?: GetPaymentListApiResponse['data']['list'];
    settlementData?: GetQuarterSettlementResponse;
    selectedKeyword?: string;
    searchWord?: string;
    onChangeSearchWord?: (v: string) => void;
    onClickedSearch?: (v: boolean) => void;
};

export interface QuarterTableRecordType {
    numbering: number;
    hospitalName: string;
    hospitalCode: string;
    settlements: Object;
}

const tableColumns: TableColumnsType<QuarterTableRecordType> = [
    { title: 'No.', dataIndex: 'numbering', width: 52, fixed: 'left', align: 'left' },
    { title: '병원명', dataIndex: 'hospitalName', width: 160, fixed: 'left', align: 'left' },
    { title: '병원코드', dataIndex: 'hospitalCode', width: 92, fixed: 'left', align: 'left' },
    { title: '1분기', dataIndex: ['settlements', 'Q1', 'count'], width: 68 },
    { title: '1분기 매출가', dataIndex: ['settlements', 'Q1', 'totalRetailPrice'], width: 108 },
    { title: '1분기 공급가', dataIndex: ['settlements', 'Q1', 'totalSupplyPrice'], width: 108 },
    { title: '2분기', dataIndex: ['settlements', 'Q2', 'count'], width: 68 },
    { title: '2분기 매출가', dataIndex: ['settlements', 'Q2', 'totalRetailPrice'], width: 108 },
    { title: '2분기 공급가', dataIndex: ['settlements', 'Q2', 'totalSupplyPrice'], width: 108 },
    { title: '3분기', dataIndex: ['settlements', 'Q3', 'count'], width: 68 },
    { title: '3분기 매출가', dataIndex: ['settlements', 'Q3', 'totalRetailPrice'], width: 108 },
    { title: '3분기 공급가', dataIndex: ['settlements', 'Q3', 'totalSupplyPrice'], width: 108 },
    { title: '4분기', dataIndex: ['settlements', 'Q4', 'count'], width: 68 },
    { title: '4분기 매출가', dataIndex: ['settlements', 'Q4', 'totalRetailPrice'], width: 108 },
    { title: '4분기 공급가', dataIndex: ['settlements', 'Q4', 'totalSupplyPrice'], width: 108 },
];

export const QuarterTabPage = ({ isShowCarosel, settlementData, onChangeSearchWord, onClickedSearch }: Props) => {
    let quarterData = settlementData?.data.quarterSummary;
    const hospitalSummary = settlementData?.data.hospitalSummary;
    const paginationInfo = settlementData?.data.pagination;

    const switchQuarterData = () => {
        const yearDataIndex = _.findIndex(quarterData, (item) => {
            return item.period === 'year';
        });

        if (yearDataIndex > 0) {
            const ridList = quarterData?.splice(yearDataIndex, 1) ?? [];

            if (!_.isEmpty(ridList)) {
                quarterData?.unshift(...ridList);
            }
        }

        return quarterData;
    };

    const getData = () => {
        if (!hospitalSummary) {
            return [];
        }

        const parsedDataList = _.map(hospitalSummary, (item, index) => {
            const baseHospitalData = {
                numbering: index + 1,
                hospitalName: item.hospitalName,
                hospitalCode: item.hospitalCode,
            };

            const settlements: Record<string, { type: string; period: string; totalRetailPrice: number; totalSupplyPrice: number; count: number }> = _.reduce(
                item.payment,
                (acc, paymentItem) => {
                    acc[paymentItem.period] = {
                        period: paymentItem.period,
                        totalRetailPrice: paymentItem.totalRetailPrice,
                        totalSupplyPrice: paymentItem.totalSupplyPrice,
                        count: paymentItem.count,
                    };
                    return acc;
                },
                {} as Record<string, any>
            );

            return { ...baseHospitalData, settlements };
        });

        return parsedDataList;
    };

    const mergedColumns = React.useMemo<typeof tableColumns>(() => {
        return tableColumns.map((col) => ({ ...col, align: col.align ?? 'center' }));
    }, []);

    const getCardBackgroundColor = (period: string) => {
        return period === 'year' ? BackgroundColor.color_background_primary_neutral : BackgroundColor.color_background_white;
    };

    return (
        <TabChildrenContainer>
            {isShowCarosel && quarterData && (
                <MetricCardContainer isShow={isShowCarosel}>
                    <List
                        grid={{
                            column: 5,
                        }}
                        dataSource={switchQuarterData()}
                        style={{ justifyContent: 'space-between', width: 1440 }}
                        renderItem={(item, index) => <SettlementCard key={index} data={item} backgroundColor={getCardBackgroundColor(item.period)} />}
                    />
                </MetricCardContainer>
            )}
            <FilteredContainer>
                <Body3_Medium>{`${hospitalSummary?.length ?? 0}곳의 병원`}</Body3_Medium>
                <FilterButtons isShowPeriodFilter={false} onChangeSearchWord={onChangeSearchWord} onClickedSearch={onClickedSearch} />
            </FilteredContainer>
            <AntdTable initNumber={hospitalSummary?.length ?? 0} currentData={getData()} mergedColumnData={mergedColumns} />
        </TabChildrenContainer>
    );
};

export default QuarterTabPage;

const TabChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 16px;
`;

const FilteredContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const MetricCardContainer = styled.div<{ isShow?: boolean }>`
    display: ${(props) => (props.isShow ? 'flex' : 'none')};
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0px;
    width: 100%;
`;
