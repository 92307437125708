import React from 'react';

const ToolTipEnableIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='2' y='2' width='20' height='20' rx='10' fill='#E0ECFF' />
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M11.9999 15.5C13.9329 15.5 15.4999 13.933 15.4999 12C15.4999 10.067 13.9329 8.50002 11.9999 8.50002C10.0669 8.50002 8.4999 10.067 8.4999 12C8.4999 13.933 10.0669 15.5 11.9999 15.5ZM11.9999 17.1C14.8166 17.1 17.0999 14.8167 17.0999 12C17.0999 9.18337 14.8166 6.90002 11.9999 6.90002C9.18325 6.90002 6.8999 9.18337 6.8999 12C6.8999 14.8167 9.18325 17.1 11.9999 17.1Z'
                fill='#264C97'
            />
        </svg>
    );
};

export default ToolTipEnableIcon;
