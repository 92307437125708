import React, { useEffect } from 'react';
import { InfoBodySubTitle } from './InfoBodySubTitles';
import { InfoBodyDiagnosisMemoText } from 'constants/viewDetailDiagnosisPageConstants';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import BasicButton from 'components/Buttons/BasicButton';
import { TextareaAutosize } from '@mui/material';
import { InfoBodyDiagnosisMemoProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import { BackgroundColor, BorderColor, TextColor } from 'styles/Global/colorSemantic';
import { Radius } from 'styles/Global/objectStyles';

const InfoBodyDiagnosisMemo = ({
    setDiagnosisMemo,
    diagnosisMemo,
    updateDiagnosisInfoApiCall,
    diagnosisInfoData,
    memoChangeBtn,
    setMemoChangeBtn,
    clickCancelButtonInMemo,
    initDiagnosisMemo,
}: InfoBodyDiagnosisMemoProps) => {
    useEffect(() => {
        if (diagnosisInfoData.prescriptionMemo) {
            return setMemoChangeBtn(true);
        }
        return setMemoChangeBtn(false);
    }, [diagnosisInfoData]);

    return (
        <>
            <InfoBodySubTitle title={InfoBodyDiagnosisMemoText.title} firstSubText={InfoBodyDiagnosisMemoText.firstSubText} secondSubText={InfoBodyDiagnosisMemoText.secondSubText} />
            <TextArea minRows={6} placeholder={InfoBodyDiagnosisMemoText.textAreaPlaceholder} onChange={(e) => setDiagnosisMemo(e.target.value)} value={diagnosisMemo} disabled={memoChangeBtn} />
            <ButtonWrapper>
                {!memoChangeBtn && !diagnosisMemo && !initDiagnosisMemo ? (
                    ''
                ) : !memoChangeBtn ? (
                    <>
                        <BasicButton $width='215px' $type='anotherFill' onClick={clickCancelButtonInMemo}>
                            취소
                        </BasicButton>
                        <BasicButton $width='215px' $type='fill' disabled={diagnosisInfoData.prescriptionMemo === diagnosisMemo} onClick={() => updateDiagnosisInfoApiCall('memo')}>
                            저장하기
                        </BasicButton>
                    </>
                ) : (
                    <BasicButton $width='310px' disabled={!diagnosisMemo} onClick={() => setMemoChangeBtn(false)} $type='outLine'>
                        수정하기
                    </BasicButton>
                )}
            </ButtonWrapper>
        </>
    );
};

export default InfoBodyDiagnosisMemo;

const TextArea = styled(TextareaAutosize)`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 180px;
    padding: 18px 20px;
    margin-top: 36px;
    border: 1px solid ${BorderColor.color_border_normal};
    border-radius: ${Radius.radius_4};
    font-size: ${font.size[16]};
    font-weight: ${font.weight.medium};
    line-height: 24px;
    resize: none;
    outline: none;

    &:focus :hover {
        background-color: ${palette.white};
    }

    &:hover {
        border: 1px solid ${BorderColor.color_border_normal_hover};
    }

    &:disabled {
        border: 1px solid ${BorderColor.color_border_normal};
        background-color: ${BackgroundColor.color_background_disabled};
        color: ${TextColor.color_text_normal};

        &:hover {
            background-color: inherit;
        }
    }

    &:focus {
        border: 1px solid ${BorderColor.color_border_normal_press};
    }
    &::placeholder {
        color: ${TextColor.color_text_placeholder};
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 10px;
`;
