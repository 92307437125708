import { LeaveModalProps } from 'models/Hospital/viewDetailDiagnosisPageTypes';
import React from 'react';
import styled from 'styled-components';
import { MobileModalContent, MobileModalLayout, MobileModalLeftTitle, ModalBackground, ModalContent, ModalFooter, ModalLayout, ModalLeftTitle, ModalTitle } from './ModalForms';
import BasicButton from 'components/Buttons/BasicButton';
import MobileBasicButton from 'components/Buttons/MobileBasicButton';
import { BorderColor } from 'styles/Global/colorSemantic';

const MobileLeaveModal = ({
    setIsLeaveModal,
    clickLeaveButtonInLeaveModal,
    title,
    subTitle,
    content,
    infoContents,
    firstButton,
    secondButton,
    firstButtonType = 'anotherFill',
    secondButtonType = 'warning',
}: LeaveModalProps) => {
    return (
        <ModalBackground $type='mobile'>
            <MobileModalLayout $width='300px'>
                <ModalTitleAndContentContainer>
                    <TitleWrapper>
                        <MobileModalLeftTitle>{title}</MobileModalLeftTitle>
                        <MobileModalLeftTitle>{subTitle}</MobileModalLeftTitle>
                    </TitleWrapper>
                    <MobileModalContent>{content}</MobileModalContent>
                    {infoContents && <ContentsInfoContainer>{infoContents}</ContentsInfoContainer>}
                </ModalTitleAndContentContainer>
                <ModalFooter>
                    <MobileBasicButton $width='100%' $height='52px' onClick={setIsLeaveModal} $type={firstButtonType}>
                        {firstButton}
                    </MobileBasicButton>
                    {secondButton && (
                        <MobileBasicButton $width='100%' $height='52px' onClick={clickLeaveButtonInLeaveModal} $type={secondButtonType}>
                            {secondButton}
                        </MobileBasicButton>
                    )}
                </ModalFooter>
                {/* <CloseButtonWrapper onClick={setIsLeaveModal}>
                    <CloseDialog />
                </CloseButtonWrapper> */}
            </MobileModalLayout>
        </ModalBackground>
    );
};

export default MobileLeaveModal;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

const TitleWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const ContentsInfoContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid ${BorderColor.color_border_neutral};
    padding: 16px 14px;
`;
