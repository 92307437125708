import React from 'react';
import { PrimaryColor } from 'styles/Global/colorSemantic';

const LnbAdminApprovalIcon = ({ $active }: { $active?: boolean }) => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5 5H19V19H5L5 5ZM3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5ZM16.2311 10.1823C16.6079 9.77857 16.5861 9.14578 16.1823 8.76894C15.7786 8.39211 15.1458 8.41393 14.7689 8.81768L10.8333 13.0344L9.23106 11.3177C8.85423 10.9139 8.22144 10.8921 7.81769 11.2689C7.41393 11.6458 7.39211 12.2786 7.76895 12.6823L10.1023 15.1823C10.2914 15.385 10.5562 15.5 10.8333 15.5C11.1105 15.5 11.3753 15.385 11.5644 15.1823L16.2311 10.1823Z'
                fill={$active ? PrimaryColor.color_primary_normal : '#333438'}
            />
        </svg>
    );
};

export default LnbAdminApprovalIcon;
