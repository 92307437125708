import styled from 'styled-components';

export const AdminInnerLayout = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    min-width: 880px;
    z-index: 0;
`;
