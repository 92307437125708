import React from 'react';
import None from 'assets/Icons/Common/None';
import BasicButton from 'components/Buttons/BasicButton';
import { diagnosisNothingLists } from 'constants/diagnosisListPageConstants';
import { managingPatientsDiagnosisNothingLists, managingPatientsDiagnosisNothingSearchLists } from 'constants/managingPatientsPageConstants';
import { pagePaths } from 'constants/path';
import { todayDiagnosisNothingLists } from 'constants/todayDiagnosisConstants';
import { viewDetailPatientNothingLists } from 'constants/viewDetailPatientPageConstants';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { font, palette } from 'styles/Global/globalStyles';
import { useLoginStore } from 'store/useLoginDataStore';
import { AccountLevel } from 'utils/statusCode';

const NothingDataInPagination = ({ initNumber, handleResetButton }: { initNumber?: number; handleResetButton?: () => void }) => {
    const { loginData } = useLoginStore();
    const navigate = useNavigate();

    return (
        <NothingDataWrapper>
            <None />
            <Text>{initNumber === 0 || !initNumber ? todayDiagnosisNothingLists.text : diagnosisNothingLists.text}</Text>
            <SubText>{initNumber === 0 || !initNumber ? todayDiagnosisNothingLists.subText : diagnosisNothingLists.subText}</SubText>

            {loginData.level !== AccountLevel.externalCounselor && (
                <BasicButton $width='200px' $height='44px' $type='fill' onClick={!initNumber || initNumber === 0 ? () => navigate(pagePaths.addDiagnosis) : handleResetButton}>
                    {initNumber === 0 || !initNumber ? todayDiagnosisNothingLists.buttonText : diagnosisNothingLists.buttonText}
                </BasicButton>
            )}
        </NothingDataWrapper>
    );
};

export default NothingDataInPagination;

export const NothingDataPatientDiagnosis = () => {
    return (
        <NothingDataWrapper>
            <None />
            <Text>{viewDetailPatientNothingLists.text}</Text>
            <SubText>{viewDetailPatientNothingLists.subText}</SubText>
        </NothingDataWrapper>
    );
};

export const NothingDataInManagingPatientsPagination = ({ initNumber, handleResetButton }: { initNumber?: number; handleResetButton: () => void }) => {
    const navigate = useNavigate();

    return (
        <NothingDataWrapper>
            <None />
            <Text>{initNumber === 0 || !initNumber ? managingPatientsDiagnosisNothingLists.text : managingPatientsDiagnosisNothingSearchLists.text}</Text>
            <SubText>{initNumber === 0 || !initNumber ? managingPatientsDiagnosisNothingLists.subText : managingPatientsDiagnosisNothingSearchLists.subText}</SubText>
            <BasicButton $width='200px' $height='44px' $type='fill' onClick={initNumber === 0 ? () => navigate(pagePaths.addDiagnosis) : handleResetButton}>
                {initNumber === 0 || !initNumber ? managingPatientsDiagnosisNothingLists.buttonText : managingPatientsDiagnosisNothingSearchLists.buttonText}
            </BasicButton>
        </NothingDataWrapper>
    );
};

const NothingDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 360px;
`;

const Text = styled.span`
    font-size: ${font.size[18]};
    font-weight: ${font.weight.medium};
    margin-bottom: 8px;
`;

const SubText = styled.span`
    font-size: ${font.size[14]};
    color: ${palette.testGray};
    font-weight: ${font.weight.regular};
    margin-bottom: 20px;
`;
