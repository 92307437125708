import { PaginationIconWrapper, PaginationPageButton, PaginationPageButtonWrapper } from 'styles/Global/buttonStyles';
import NextPageIcon from 'assets/Icons/Pagination/NextPageIcon';
import NextSectionJumpIcon from 'assets/Icons/Pagination/NextSectionJumpIcon';
import PrevPageIcon from 'assets/Icons/Pagination/PrevPageIcon';
import PrevSectionJumpIcon from 'assets/Icons/Pagination/PrevSectionJumpIcon';
import styled from 'styled-components';
import _ from 'lodash';
import { usePageIndexDataStore } from 'store/usePaginationStore';
import { Pagination } from 'models/Admin/calculatePageApiTypes';

type Props = {
    paginationData: Pagination | undefined;
};

const TPagination = ({ paginationData }: Props) => {
    const totalPages = paginationData?.totalPages ?? 0;

    const { pageSection, currentPage } = usePageIndexDataStore();
    const setPageIndex = usePageIndexDataStore((state) => state.handlePageIndex);
    const setPageSection = usePageIndexDataStore((state) => state.handlePageSection);

    return (
        <PaginationLayout>
            <ArrowButtonContainer>
                {totalPages > 10 && (
                    <PaginationIconWrapper
                        disabled={pageSection === 1}
                        onClick={() => {
                            setPageSection(pageSection - 1);
                            setPageIndex((pageSection - 2) * 10 + 1);
                        }}
                    >
                        <PrevSectionJumpIcon />
                    </PaginationIconWrapper>
                )}
                <PaginationIconWrapper
                    disabled={currentPage === 1}
                    onClick={() => {
                        if (currentPage % 10 === 1) {
                            setPageSection(pageSection - 1);
                        }
                        setPageIndex(currentPage - 1);
                    }}
                >
                    <PrevPageIcon />
                </PaginationIconWrapper>
            </ArrowButtonContainer>
            <PaginationPageButtonWrapper>
                {_.map(_.range((pageSection - 1) * 10, totalPages < pageSection * 10 ? totalPages : pageSection * 10), (index) => (
                    <PaginationPageButton key={index + 1} onClick={() => setPageIndex(index + 1)} selected={currentPage === index + 1}>
                        {index + 1}
                    </PaginationPageButton>
                ))}
            </PaginationPageButtonWrapper>
            <ArrowButtonContainer>
                <PaginationIconWrapper
                    disabled={currentPage === totalPages}
                    onClick={() => {
                        if (currentPage % 10 === 0) {
                            setPageSection(pageSection + 1);
                        }
                        setPageIndex(currentPage + 1);
                    }}
                >
                    <NextPageIcon />
                </PaginationIconWrapper>
                {totalPages > 10 && (
                    <PaginationIconWrapper
                        disabled={totalPages / 10 <= pageSection}
                        onClick={() => {
                            setPageSection(pageSection + 1);
                            setPageIndex(pageSection * 10 + 1);
                        }}
                    >
                        <NextSectionJumpIcon />
                    </PaginationIconWrapper>
                )}
            </ArrowButtonContainer>
        </PaginationLayout>
    );
};

export default TPagination;

const ArrowButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PaginationLayout = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 36px;
`;
